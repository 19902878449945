import { createSlice } from "@reduxjs/toolkit";

export const stagesSlice = createSlice({
  name: "stageSlice",
  initialState: {
    items: []
  },
  reducers: {
    setStages: (state, action) => {
        state.items = action.payload
    },
  },
});

export const { setStages } = stagesSlice.actions;

export const getStages = (state) => {
  return state.stageSlice?.items;
};

export default stagesSlice.reducer;