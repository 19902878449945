import React from "react";

const StandardTable = ({quality_doc}) => {
  return (
    <table className="c36">
      <tbody>
        <tr className="c18">
          <td className="c45" colSpan={2} rowSpan={1}>
            <p className="c28">
              <span className="c22">STANDARD #: {quality_doc.standard}</span>
            </p>
          </td>
        </tr>
        <tr className="c18">
          <td className="c80" colSpan={1} rowSpan={1}>
            <p className="c28">
              <span className="c22">PLANNED QUANTITY: {quality_doc.planned_qty}</span>
            </p>
          </td>
          <td className="c79" colSpan={1} rowSpan={1}>
            <p className="c28">
              <span className="c22">DATE</span>
              <span className="c10">:</span>
            </p>
          </td>
        </tr>
        <tr className="c18">
          <td className="c80" colSpan={1} rowSpan={1}>
            <p className="c28">
              <span className="c22">SAMPLING PLAN: {quality_doc.sampling_plan}</span>
            </p>
          </td>
          <td className="c79" colSpan={1} rowSpan={1}>
            <p className="c28">
              <span className="c22">AREA: {quality_doc.area }</span>
            </p>
          </td>
        </tr>
        <tr className="c14">
          <td className="c80" colSpan={1} rowSpan={1}>
            <p className="c28">
              <span className="c22">SEVERITY: {quality_doc.severity}</span>
              <span className="c10">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span className="c22">LEVEL: {quality_doc.level}</span>
            </p>
          </td>
          <td className="c79" colSpan={1} rowSpan={1}>
            <p className="c28">
              <span className="c22">SAMPLING SIZE: {quality_doc.sample_size}</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default StandardTable;
