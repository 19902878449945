import { createSlice } from "@reduxjs/toolkit";


export const slice = createSlice({
  name: "lineVariationSlice",
  initialState: {
    items: [],
    totalItems: 0,
    rowsPerPage: 10,
    page: 1,
  },
  reducers: {
    setlineVariation: (state, action) => {
      state.items = action.payload.variations || [];
      state.totalItems = action.payload.count
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload
    }
  },
});

export const { setlineVariation , setPage, setRowsPerPage} = slice.actions;

export const getLineVariation = (state) => {
  return state.lineVariationSlice.items;
};

export default slice.reducer;
