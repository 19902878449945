import muiStyles from "../../styles/MuiComponentStyles";
import {
  Stack,
  Button,
  FormGroup,
  FormControl,
  TextField,
  Select,
  MenuItem,
  ThemeProvider,
  FormLabel,
} from "@mui/material";
import React from "react";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import { East } from "@mui/icons-material";
import { ModalFormTheme } from "../../styles/MuiThemes";

const ZoneForm = ({ handleClose, handleSubmit, data ,isHeadercss}) => {
  const [zone, setZone] = React.useState(data);

  const labelStyle = "original";
  const timezones = {
    ...allTimezones,
    "Europe/Berlin": "Frankfurt",
  };

  const { options } = useTimezoneSelect({ labelStyle, timezones });
  const handleChange = (e) => {
    setZone((prevZone) => {
      return { ...prevZone, [e.target.name]: e.target.value };
    });
  };

  return (
    <>
      <ThemeProvider theme={ModalFormTheme}>
        <form onSubmit={(e) => handleSubmit(e, zone)}>
          <FormGroup>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <TextField
                name="name"
                variant="outlined"
                onChange={handleChange}
                value={zone.name}
                required
              />
            </FormControl>
            <FormLabel>Time Zone</FormLabel>
            <FormControl>
              {/* <InputLabel>Time Zone*</InputLabel> */}
              <Select
                name="time_zone"
                onChange={handleChange}
                value={zone.time_zone}
                required
                placeholder="TimeZone"
              >
                {options.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            mt={4}
          >
            <Button
              type="reset"
              variant="text"
              size="large"
              onClick={handleClose}
              sx={muiStyles.secondaryButton}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              // sx={muiStyles.primaryButton}
              sx={ isHeadercss || muiStyles.primaryButton}
              endIcon={<East />}
            >
              Submit
            </Button>
          </Stack>
        </form>
      </ThemeProvider>
    </>
  );
};

export default ZoneForm;
