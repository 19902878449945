import muiStyles from "../../../../styles/MuiComponentStyles"
import { Stack, Button } from '@mui/material'
import React from "react";
import ResourceDetailsForm from './ResourceDetailsForm'

const ProductionResourceForm = ({handleSubmit, initialResource}) => {
  const [resource, setResource] = React.useState(
    initialResource || { name: "", desc: "" }
  );
  
  const handleResourceInputChange = (e) => {
    setResource((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

    return <form onSubmit={(e) => handleSubmit(e, resource)}>
    <ResourceDetailsForm resource={resource} handleInputChange={handleResourceInputChange} />
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      mt={3}
    >
      <Button type="submit" sx={muiStyles.primaryButton}>
        Submit
      </Button>
    </Stack>
  </form>
}

export default ProductionResourceForm