import {
  setSteps,
  addStep,
  setEnableStepSubmitted,
  updateImageUrl,
  updateVideoUrl,
  setCurrentStepIndex,
  cancelStep,
  updateQuestion,
  deleteStep,
  setStepEditable,
  updateQuestionImageUrl,
  updateOptionImageUrl,
  updateImageUrlgood,
  updateImageUrlbad,
  updateImageUrlreference
} from "../../../../features/ProductionResourceSteps/stepsSlice";
import useApi from "../../../../hooks/useAPI";
import muiStyles from "../../../../styles/MuiComponentStyles";
import { MediaComponent } from "../Media";
import Questions from "../Questions/Questions";
import ResourceDetailsForm from "./ResourceDetailsForm";
import StepDetailsForm from "./StepDetailsForm";
import { East } from "@mui/icons-material";
import { Stack, Button, Divider, Typography, Box, Tabs, Tab } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CosmeticaModal } from "../../../UIComponents";
import Sortable from "../Sortable/Sortable";
import StepDetailsFromQuality from "./StepDetailsFromQuality";
import { addStepQuality, setCurrentStepIndexQuality, setEnableStepSubmittedQuality, setStepEditableQuality, setStepsQuality, updateImageUrlquality } from "../../../../features/ProductionResourceSteps/stepsSliceQuality";
import MediaComponentQuality from "../Media/MediaComponentQuality";

const ProductionResourceStepForm = ({ resource, handleSubmit,handleSubmitQuality }) => {
  const { resourceId } = useParams();
  const { getData, postData, deleteData, patchData } = useApi();
  const dispatch = useDispatch();
  const steps = useSelector((state) => state.steps.items);
  const stepsQuality = useSelector((state) => state.stepsquality.items);
  const isEdit = useSelector((state) => state.steps.isEdit);
  const currentStep = useSelector((state) => state.steps.currentStep);
  const currentStepquality = useSelector((state) => state.stepsquality.currentStep);
  const currentStepIndex = useSelector((state) => state.steps.currentStepIndex);
  const isStepEditable = useSelector((state) => state.steps.isStepEditable);
  const isEditQuality = useSelector((state) => state.stepsquality.isEdit);
  const isStepEditableQuality = useSelector((state) => state.stepsquality.isStepEditable);
  const [showModal, setShowModal] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const productionUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/production`;
  const mediaUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/media/view`;
  const productionUrln = `${process.env.REACT_APP_BASE_URL}/web/v2/production`;
  // const [tabValue1, setTabValue1] = React.useState(false);

  if ((isEdit && steps.length < 1) || steps.length < 1) {
    dispatch(setEnableStepSubmitted(true));
  }
  if ((isEditQuality && stepsQuality.length < 1) || stepsQuality.length < 1) {
    dispatch(setEnableStepSubmittedQuality(true));
  }

  React.useEffect(() => {
    if (resourceId && isEdit) {
      fetchSteps(resourceId);
      // fetchStepsQuality(resourceId);
    }
    // eslint-disable-next-line
  }, [resourceId]);
  React.useEffect(() => {
    if ( isEditQuality && currentStep) {
      fetchStepsQuality(currentStep.id);
    }
    // eslint-disable-next-line
  }, [resourceId, currentStepIndex, isEditQuality, tabValue,currentStep]);

  React.useEffect(() => {
    setTabValue(0)
  }, [currentStepIndex])
  // React.useEffect(() => {
  //   if(resourceId && isEditQuality && tabValue === 2){
  //     setTabValue1(true)
  //     // fetchStepsQuality(resourceId);
  //   }else{
  //     setTabValue1(false)
  //   } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tabValue])
  const fetchSteps = async (resourceId) => {
    try {
      let images, video,good, bad , reference;
      await getData(`${productionUrl}/step/all/${resourceId}`).then((data) => {

      //   var data =     [
      //     {
      //         "id": 215,
      //         "production_resource_id": 65,
      //         "name": "test step 1 good",
      //         "desc": "test step 1 good",
      //         "min_crew": 2,
      //         "max_crew": 4,
      //         "position": 1,
      //         "deleted": false,
      //         "created_at": "2024-04-23T10:49:28.255914Z",
      //         "updated_at": "2024-04-23T10:49:28.255914Z",
      //         "media": [
      //             {
      //                 "id": 624,
      //                 "parent": "production:resource",
      //                 "parent_id": 65,
      //                 "child": "production:step",
      //                 "child_id": 215,
      //                 "name": "test step 1 good",
      //                 "tags": "",
      //                 "file_name": "1/1/production:resource_65/production:step_215/v_1_1713869368705",
      //                 "type": "video",
      //                 "deleted": false,
      //                 "created_at": "2024-04-23T10:49:31.470056Z",
      //                 "updated_at": "2024-04-23T10:49:31.470056Z"
      //             },
      //             {
      //               "id": 627,
      //               "parent": "production:resource",
      //               "parent_id": 65,
      //               "child": "production:step:reference",
      //               "child_id": 215,
      //               "name": "reference",
      //               "tags": "",
      //               "file_name": "1/1/production:resource_65/production:step_215/i_1_1713869374610",
      //               "type": "image",
      //               "deleted": false,
      //               "created_at": "2024-04-23T10:49:35.514615Z",
      //               "updated_at": "2024-04-23T10:49:35.514615Z"
      //           },
      //             {
      //                 "id": 625,
      //                 "parent": "production:resource",
      //                 "parent_id": 65,
      //                 "child": "production:step:good",
      //                 "child_id": 215,
      //                 "name": "good",
      //                 "tags": "",
      //                 "file_name": "1/1/production:resource_65/production:step_215/i_1_1713869371914",
      //                 "type": "image",
      //                 "deleted": false,
      //                 "created_at": "2024-04-23T10:49:32.819072Z",
      //                 "updated_at": "2024-04-23T10:49:32.819072Z"
      //             },
      //             {
      //                 "id": 626,
      //                 "parent": "production:resource",
      //                 "parent_id": 65,
      //                 "child": "production:step:bad",
      //                 "child_id": 215,
      //                 "name": "bad",
      //                 "tags": "",
      //                 "file_name": "1/1/production:resource_65/production:step_215/i_1_1713869373268",
      //                 "type": "image",
      //                 "deleted": false,
      //                 "created_at": "2024-04-23T10:49:34.162458Z",
      //                 "updated_at": "2024-04-23T10:49:34.162458Z"
      //             },
                  
      //         ],
      //         "question": ""
      //     },
      //     {
      //         "id": 216,
      //         "production_resource_id": 65,
      //         "name": "test step 2 good",
      //         "desc": "test step 2 good",
      //         "min_crew": 3,
      //         "max_crew": 4,
      //         "position": 0,
      //         "deleted": false,
      //         "created_at": "2024-04-23T10:50:46.550914Z",
      //         "updated_at": "2024-04-23T10:50:46.550914Z",
      //         "media": [
      //             {
      //                 "id": 628,
      //                 "parent": "production:resource",
      //                 "parent_id": 65,
      //                 "child": "production:step",
      //                 "child_id": 216,
      //                 "name": "test step 2 good",
      //                 "tags": "test step 2 good",
      //                 "file_name": "1/1/production:resource_65/production:step_216/v_1_1713869447006",
      //                 "type": "video",
      //                 "deleted": false,
      //                 "created_at": "2024-04-23T10:50:49.840481Z",
      //                 "updated_at": "2024-04-23T10:50:49.840481Z"
      //             },
      //             {
      //               "id": 631,
      //               "parent": "production:resource",
      //               "parent_id": 65,
      //               "child": "production:step:reference",
      //               "child_id": 216,
      //               "name": "reference",
      //               "tags": "reference",
      //               "file_name": "1/1/production:resource_65/production:step_216/i_1_1713869453076",
      //               "type": "image",
      //               "deleted": false,
      //               "created_at": "2024-04-23T10:50:54.029301Z",
      //               "updated_at": "2024-04-23T10:50:54.029301Z"
      //           },
      //             {
      //                 "id": 629,
      //                 "parent": "production:resource",
      //                 "parent_id": 65,
      //                 "child": "production:step:good",
      //                 "child_id": 216,
      //                 "name": "good",
      //                 "tags": "good",
      //                 "file_name": "1/1/production:resource_65/production:step_216/i_1_1713869450298",
      //                 "type": "image",
      //                 "deleted": false,
      //                 "created_at": "2024-04-23T10:50:51.243326Z",
      //                 "updated_at": "2024-04-23T10:50:51.243326Z"
      //             },
      //             {
      //                 "id": 630,
      //                 "parent": "production:resource",
      //                 "parent_id": 65,
      //                 "child": "production:step:bad",
      //                 "child_id": 216,
      //                 "name": "bad",
      //                 "tags": "bad",
      //                 "file_name": "1/1/production:resource_65/production:step_216/i_1_1713869451701",
      //                 "type": "image",
      //                 "deleted": false,
      //                 "created_at": "2024-04-23T10:50:52.623058Z",
      //                 "updated_at": "2024-04-23T10:50:52.623058Z"
      //             },
                
      //         ],
      //         "question": ""
      //     }
      // ]
        data.sort((a, b) => a.position - b.position);
        data = data.map((d) => {
          const empltyFile = {
            name: "",
            tags: "",
            url: "",
            type: "",
            file: null,
          };
          video = d?.media?.find((m) => m.type === "video") || empltyFile;
          images = d?.media?.filter((m) => m.type === "image");
          good = d?.media?.filter((m) => m.type === "image" && m.child === "production:step:good");
          bad = d?.media?.filter((m) => m.type === "image" && m.child === "production:step:bad");
          reference = d?.media?.filter((m) => m.type === "image" && m.child === "production:step:reference");
          d.media = {
            video,
            images: images.length > 0 ? images : [empltyFile],
            good: good.length > 0 ? good : [empltyFile],
            bad: bad.length > 0 ? bad : [empltyFile],
            reference: reference.length > 0 ? reference : [empltyFile],
          };
          d.isStepSubmitted = true
          return d;
        });

        dispatch(setSteps(data || []));
        dispatch(setCurrentStepIndex(isEdit ? (currentStepIndex && currentStepIndex < data.length) ? currentStepIndex : 0 : null));

        for (let m = 0; m < data.length; m++) {
          const images = data[m]?.media?.images;
          const images1 = data[m]?.media?.good;
          const images2 = data[m]?.media?.bad;
          const images3 = data[m]?.media?.reference;
          for (let i = 0; i < images.length; i++) {
            const image = images[i];
            if (!image.url && image.file_name && image.file_name !== "") {
              postData(mediaUrl, { file_name: image.file_name }).then(
                (resp) => {
                  dispatch(
                    updateImageUrl({ stepIndex: m, index: i, url: resp.url })
                  );
                }
              );
            }
          }

          for (let i = 0; i < images1.length; i++) {
            const image1 = images1[i];
            if (!image1.url && image1.file_name && image1.file_name !== "") {
              postData(mediaUrl, { file_name: image1.file_name }).then(
                (resp) => {
                  dispatch(
                    updateImageUrlgood({ stepIndex: m, index: i, url: resp.url })
                  );
                }
              );
            }
          }
          for (let i = 0; i < images2.length; i++) {
            const image2 = images2[i];
            if (!image2.url && image2.file_name && image2.file_name !== "") {
              postData(mediaUrl, { file_name: image2.file_name }).then(
                (resp) => {
                  dispatch(
                    updateImageUrlbad({ stepIndex: m, index: i, url: resp.url })
                  );
                }
              );
            }
          }
          for (let i = 0; i < images3.length; i++) {
            const image3 = images3[i];
            if (!image3.url && image3.file_name && image3.file_name !== "") {
              postData(mediaUrl, { file_name: image3.file_name }).then(
                (resp) => {
                  dispatch(
                    updateImageUrlreference({ stepIndex: m, index: i, url: resp.url })
                  );
                }
              );
            }
          }

          const video = data[m].media.video;
          if (!video.url && video.file_name && video.file_name !== "") {
            postData(mediaUrl, { file_name: video.file_name }).then((resp) => {
              dispatch(updateVideoUrl({ stepIndex: m, url: resp.url }));
            });
          }
          const url = `${process.env.REACT_APP_BASE_URL}/web/v2/production/step/question/all/${data[m].id}`
          getData(url).then((data) => {
            for (let i = 0; i < data.length; i++) {
              const question = data[i];
              if (question?.has_image && question.question_image && question.question_image !== "") {
                postData(mediaUrl, { file_name: question.question_image }).then(
                  (resp) => {
                    dispatch(
                      updateQuestionImageUrl({ stepIndex: m, qIndex: i, url: resp.url })
                    );
                  }
                );
              } else {
                updateQuestionImageUrl({ stepIndex: m, qIndex: i, url: '' })
              }
              for (let j=0; j <= question.options.length; j++) {
                const option = question.options[j]
                if (option?.has_image && option.option_image && option.option_image !== "") {
                  postData(mediaUrl, { file_name: option.option_image }).then(
                    async (resp1) => {
                      dispatch(
                        updateOptionImageUrl({ stepIndex: m, qIndex: i, oIndex: j, url: resp1.url })
                      );
                    }
                  );
                } else {
                  updateOptionImageUrl({ stepIndex: m, qIndex: i, oIndex: j, url: ''})
                }
              }
            }
            dispatch(updateQuestion({stepIndex: m, data}))
          })
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
 
  
  const fetchStepsQuality = async (resourceId) => {
    try {
      let images;
      await getData(`${productionUrln}/step/quality/alerts/all/${resourceId}`).then((data) => {
      //   var data = [
      //     {
      //         "id": 78,
      //         "production_resource_step_id": 62,
      //         "name": "step 2 quality",
      //         "desc": "step 2 quality",
      //         "image": "1/1/production:resource_62/production:step:qa_78/i_1_1713860390747",
      //         "deleted": false,
      //         "created_at": "2024-04-23T08:19:50.295916Z",
      //         "updated_at": "2024-04-23T08:19:50.295916Z",
      //         "media": [
      //             {
      //                 "id": 606,
      //                 "parent": "production:resource",
      //                 "parent_id": 62,
      //                 "child": "production:step:qa",
      //                 "child_id": 78,
      //                 "name": "step 2 quality",
      //                 "tags": "",
      //                 "file_name": "1/1/production:resource_62/production:step:qa_78/i_1_1713860390747",
      //                 "type": "image",
      //                 "deleted": false,
      //                 "created_at": "2024-04-23T08:19:52.262028Z",
      //                 "updated_at": "2024-04-23T08:19:52.262028Z"
      //             }
      //         ],
      //         "position": 0
      //     },
      //     {
      //         "id": 77,
      //         "production_resource_step_id": 62,
      //         "name": "test step 1 title",
      //         "desc": "test step 1 desc22222",
      //         "image": "1/1/production:resource_62/production:step:qa_77/i_1_1713858634522",
      //         "deleted": false,
      //         "created_at": "2024-04-23T07:50:34.068677Z",
      //         "updated_at": "2024-04-23T07:50:34.068677Z",
      //         "media": [
      //             {
      //                 "id": 603,
      //                 "parent": "production:resource",
      //                 "parent_id": 62,
      //                 "child": "production:step:qa",
      //                 "child_id": 77,
      //                 "name": "test step 122222 nm",
      //                 "tags": "",
      //                 "file_name": "1/1/production:resource_62/production:step:qa_77/i_1_1713858634522",
      //                 "type": "image",
      //                 "deleted": false,
      //                 "created_at": "2024-04-23T07:50:36.004227Z",
      //                 "updated_at": "2024-04-23T07:50:36.004227Z"
      //             }
      //         ],
      //         "position": 2
      //     },
      //     {
      //       "id": 77,
      //       "production_resource_step_id": 62,
      //       "name": "test step 1 title",
      //       "desc": "test step 1 desc",
      //       "image": "1/1/production:resource_62/production:step:qa_77/i_1_1713858634522",
      //       "deleted": false,
      //       "created_at": "2024-04-23T07:50:34.068677Z",
      //       "updated_at": "2024-04-23T07:50:34.068677Z",
      //       "media": [
      //           {
      //               "id": 603,
      //               "parent": "production:resource",
      //               "parent_id": 62,
      //               "child": "production:step:qa",
      //               "child_id": 77,
      //               "name": "test step 1 nm",
      //               "tags": "",
      //               "file_name": "1/1/production:resource_62/production:step:qa_77/i_1_1713858634522",
      //               "type": "image",
      //               "deleted": false,
      //               "created_at": "2024-04-23T07:50:36.004227Z",
      //               "updated_at": "2024-04-23T07:50:36.004227Z"
      //           }
      //       ],
      //       "position": 1
      //   },
          
      // ]
        data.sort((a, b) => a.position - b.position);
        data = data.map((d) => {
          const empltyFile = {
            name: "",
            tags: "",
            url: "",
            type: "",
            file: null,
          };
          // video = d?.media?.find((m) => m.type === "video") || empltyFile;
          images = d?.media?.filter((m) => m.type === "image")|| empltyFile;
          d.media = {
            // video,
            images: images.length > 0 ? images : [empltyFile],
          };
          d.isStepSubmitted = true
          return d;
        }); 
        dispatch(setStepsQuality(data || []));
        
        if (true || data.length === 0) {
          dispatch(addStepQuality(resource.id));
        }
        dispatch(setCurrentStepIndexQuality(isEditQuality ? (currentStepIndex && currentStepIndex < data.length) ? currentStepIndex : 0 : null));

        for (let m = 0; m < data.length; m++) {
          const images = data[m]?.media?.images;
          for (let i = 0; i < images.length; i++) {
            const image = images[i];
            if (!image.url && image.file_name && image.file_name !== "") {
              postData(mediaUrl, { file_name: image.file_name }).then(
                (resp) => {
                  dispatch(
                    updateImageUrlquality({ stepIndex: m, index: i, url: resp.url })
                  );
                }
              );
            }
          }         
        }
      });
    } catch (error) {
      console.log(error);
    }  
  }
  const handleAddStep = () => {
    dispatch(addStep(resource.id));
    dispatch(addStepQuality(resource.id));
  };

  const handleDeleteStep = async () => {
    const id = currentStep?.id
    if (id) {
      await deleteData(`${productionUrl}/step/delete/${id}`)
    }
    dispatch(deleteStep())
    dispatch(setEnableStepSubmitted(true))
    dispatch(setEnableStepSubmittedQuality(true))
    dispatch(setStepEditable(false))
    dispatch(setStepEditableQuality(false))
    await fetchSteps(resourceId)
  }

  const handleStepPositions = async (_steps) => {
    const stepPositions = []
    _steps = _steps.map((step, index) => {
      step.position = index
      stepPositions.push({id: step.id, position: step.position})
      return step
    })
    if (isEdit ) {
     
      await patchData(`${productionUrl}/step/postion/update`, {step_positions: stepPositions}).then(async (resp) => {
        // await fetchSteps(resourceId)
        dispatch(setSteps(_steps))
      }).catch((err) => alert('Something went wrong, please try again after sometime'))
    }
    if (isEditQuality) {
     
      await patchData(`${productionUrl}/step/postion/update`, {step_positions: stepPositions}).then(async (resp) => {
        // await fetchSteps(resourceId)
        // dispatch(setSteps(_steps))
        dispatch(setStepsQuality(_steps))
      }).catch((err) => alert('Something went wrong, please try again after sometime'))
    }
  }

  const handleFormSubmit = async (e) => {
    await handleSubmit(e, currentStep)
    dispatch(setStepEditable(false))
    await fetchSteps(resourceId);
  }
  const handleFormSubmitQuality = async (e) => {
    await handleSubmitQuality(e,currentStep,currentStepquality)
    dispatch(setStepEditableQuality(false))
    await fetchStepsQuality(currentStep.id);
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      {!isEdit && <ResourceDetailsForm resource={resource} />}
      <div style={{
        marginTop: '32px',
        borderTop: '1px solid #d7d2d2',
        paddingTop: '24px'
      }}>
         <Typography sx={muiStyles.pageTitle}>
            Production Resource Steps
          </Typography>
        <Sortable handleAddStep={handleAddStep} handleDeleteStep={handleDeleteStep} handleStepPositions={handleStepPositions} />

        {currentStep && (
          <Box sx={{ width: '100%', mt: 3 }}>
            <Box>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="secondary tabs example"
              >
                <Tab value={0} index={0} label="Step Details" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
                <Tab value={1} index={1} label="Questions" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
                <Tab value={2} index={2} label="Quality Alerts" id="simple-tab-2" aria-controls="simple-tabpanel-2" />
              </Tabs>
            </Box>

            <Box>
              <div
                  role="tabpanel"
                  hidden={tabValue !== 0}
                  id={`simple-tabpanel-0`}
                  aria-labelledby={`simple-tab-0`}
                >
                  {tabValue === 0 && (
                    <Box sx={{ p: 3 }}>
                      <form onSubmit={handleFormSubmit} style={{marginBottom: '16px'}}>
                        <Stack spacing={3} divider={<Divider fullWidth />}>
                          <StepDetailsForm />

                          <MediaComponent fetchSteps={() => fetchSteps(resourceId)} />

                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            my={3}
                          >
                            <Button
                              type="button"
                              sx={muiStyles.secondaryButton}
                              onClick={() => setShowModal(true)}
                              disabled={currentStep?.isStepSubmitted || !isStepEditable}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              sx={muiStyles.primaryButton}
                              endIcon={<East />}
                              disabled={steps.length < 1 || !isStepEditable}
                            >
                              Submit
                            </Button>
                          </Stack>
                        </Stack>
                      </form>
                    </Box>
                  )}
              </div>
              <div
                  role="tabpanel"
                  hidden={tabValue !== 1}
                  id={`simple-tabpanel-1`}
                  aria-labelledby={`simple-tab-1`}
                >
                  {tabValue === 1 && (
                    <Box sx={{ p: 3 }}>
                      <form onSubmit={handleFormSubmit} style={{marginBottom: '16px'}}>
                        <Stack spacing={3} divider={<Divider fullWidth />}>

                          <Questions fetchSteps={() => fetchSteps(resourceId)}/>

                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            my={3}
                          >
                            <Button
                              type="button"
                              sx={muiStyles.secondaryButton}
                              onClick={() => setShowModal(true)}
                              disabled={currentStep?.isStepSubmitted || !isStepEditable}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              sx={muiStyles.primaryButton}
                              endIcon={<East />}
                              disabled={steps.length < 1 || !isStepEditable}
                            >
                              Submit
                            </Button>
                          </Stack>
                        </Stack>
                      </form>
                    </Box>
                  )}
              </div>
              <div
                  role="tabpanel"
                  hidden={tabValue !== 2}
                  id={`simple-tabpanel-2`}
                  aria-labelledby={`simple-tab-2`}
                >
                  {tabValue === 2 && (
                    <Box sx={{ p: 3 }}>
                      <form onSubmit={handleFormSubmitQuality} style={{marginBottom: '16px'}}>
                        <Stack spacing={3} divider={<Divider fullWidth />}>
                          <StepDetailsFromQuality />
                          {/* {tabValue1 && ( */}
                          {/* <MediaComponentQuality fetchSteps={() => fetchStepsQuality(resourceId)} qualityalerts={tabValue1}  /> */}
                          <MediaComponentQuality fetchSteps={() => fetchStepsQuality(resourceId)} />
                        {/* )  } */}
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            my={3}
                          >
                            <Button
                              type="button"
                              sx={muiStyles.secondaryButton}
                              onClick={() => setShowModal(true)}
                              disabled={currentStep?.isStepSubmitted || !isStepEditableQuality}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              sx={muiStyles.primaryButton}
                              endIcon={<East />}
                              disabled={steps.length < 1 || !isStepEditableQuality}
                            >
                              Submit
                            </Button>
                          </Stack>
                        </Stack>
                      </form>
                    </Box>
                  )}
              </div>
            </Box>
          </Box>
        )}
      </div>
      <CosmeticaModal showModal={showModal}
        closeModal={() => setShowModal(false)}
        title=''>
          <Box p={2}>
            <Typography textAlign='center'>Are you sure you want to cancel?</Typography>
            <Stack direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              my={3}>
              <Button type="button" sx={muiStyles.primaryButton} onClick={() => setShowModal(false)}>No</Button>
              <Button type="button" sx={muiStyles.primaryButton} onClick={() => {dispatch(cancelStep());setShowModal(false)}}>Yes</Button>
            </Stack>
          </Box>
      </CosmeticaModal>
    </>
  );
};

export default ProductionResourceStepForm;
