import { Grid, Box, Typography, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { CosmeticaModal } from "../UIComponents";
import AddEditScheduleJob from "./AddEditSchedule";
import ScheduleJobDetails from "./ScheduleJobDetails";
import JobCard from "./JobCard";
import AddJobCard from "./AddJobCard";
import { formatDate, getStartAndEndDateOfWeek, getWeekNumber } from "../../utils/helperFunctions";
import useApi from "../../hooks/useAPI";
import { colors } from "../../styles/colors";

const Weeks = ({week, fetchWorkOrders}) => {
  const user = useSelector((state) => state.user.user);
  const [showForm, setShowForm] = React.useState(false);
  const [showJobDetails, setShowJobDetails] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("Add");
  const [job, setJob] = React.useState(null)
  const {postData, deleteData, patchData} = useApi()
  const workOrderUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/work/order`;
  const { start, end } = getStartAndEndDateOfWeek(+week.week)

  const currentWeekNumber = getWeekNumber()
  let weekColor
  if (+week?.week === currentWeekNumber) {
    weekColor = 'green'
  } else if (week?.week > currentWeekNumber) {
    weekColor = colors.primary
  } else {
    weekColor = 'gray'
  }

  const handleAddJob = () => {
    const newJob = {
      customer_reference: '',
      end_date: '',
      line_id: 0,
      line_variation_id: 0,
      note: '',
      priority_id: 0,
      product_id: 0,
      production_resource_id: 0,
      quantity: 0,
      start_date: '',
      tenant_id: user.tenant_id,
      work_order: '',
      work_order_status_id: 1,
      zone_id: user.zone_id
    }
    setJob(newJob)
    setModalTitle('Add')
    setShowForm(true)
  }

  const handleEditJob = () => {
    setModalTitle('Edit')
    setShowJobDetails(false)
    setShowForm(true)
  }

  const handleDeleteJob = () => {
    deleteData(`${workOrderUrl}/delete/${job.id}`).then(()=>{
      setShowJobDetails(false)
      fetchWorkOrders()
    })
}

  const handleJobDetails = (_job) => {
    setJob(_job)
    setShowJobDetails(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (job.id) {
      patchData(`${workOrderUrl}/update`, job).then(() => fetchWorkOrders())
    } else {
      postData(`${workOrderUrl}/create`, job).then(() => fetchWorkOrders())
    }
    setShowForm(false)
  }

  return (
    <Grid item xs={3}>
      <Box
        sx={{
          backgroundColor: weekColor,
          color: "white",
          lineHeight: "13px",
          textAlign: "center",
          padding: "4px 0",
          marginBottom: "4px",
        }}
      >
        <Typography sx={{ fontSize: "13px", fontWeight: 700 }}>
          W - {week?.week}
        </Typography>
        <Typography sx={{ fontSize: "11px", fontWeight: 400 }}>
          {formatDate(start.split('T')[0], 'ddMMM')} - {formatDate(end.split('T')[0], 'ddMMM')}
        </Typography>
      </Box>
      <Stack direction="column" spacing={0.5} justifyContent="space-evenly">
        {week["work_order_by_week"]?.map((workOrder, jobIndex) => {
          return (
            <>
              <JobCard workOrder={workOrder} handleJobDetails={handleJobDetails} />
            </>
          );
        })}
      </Stack>
      <AddJobCard handleAddJob={handleAddJob} weekNumber={+week?.week} />
      {/* Modals */}
      <Box>
          <CosmeticaModal
            title={`${modalTitle} Schedule Job`}
            showModal={showForm}
            closeModal={() => setShowForm(false)}
            width={800}
          >
            <AddEditScheduleJob job={job} setJob={setJob} weekNumber={+week.week} handleSubmit={handleSubmit} closeModal={() => setShowForm(false)} start_date={start} end_date={end} />
          </CosmeticaModal>
          <CosmeticaModal
            title="Job Details"
            showModal={showJobDetails}
            closeModal={() => setShowJobDetails(false)}
          >
            <ScheduleJobDetails job={job} handleEditJob={handleEditJob} weekNumber={+week.week} handleDelete={handleDeleteJob} />
          </CosmeticaModal>
      </Box>
    </Grid>
  );
};

export default Weeks;
