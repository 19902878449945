import { QRCodeCanvas } from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../hooks/useAPI";
import { setwdevice } from "../../features/mdevices/wdevicesSlice";
import { Box, } from "@mui/system";
import { Card, IconButton, Modal, ThemeProvider, Typography, createTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Loading } from "../UIComponents";
import { useCssStateHook } from "../UIComponents/ComonCss";


const ModalTheme = createTheme({
    typography: {
        body1: {
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'center',
            fontSize: '16px'
        },
        subtitle1: {
            color: '#4C4C4C',
            fontWeight: 600,
            margin: '24px 0',
            lineHeight: '24px',
            textAlign: 'center',
            fontSize: '24px',
        }
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    padding: '20px',
                    borderRadius: "10px",
                }
            }
        }
    }
})
const WUnassignedFrom = () => {
    const [showModal, setShowModal] = useState(false);
    const [qrData, setQrData] = useState();
    const userInfo = useSelector((state) => state.user.user);
    const licenseData = useSelector((state) => state.wdevices.items);
    const {loading, getData, } = useApi()
    const LicenseURL = `${process.env.REACT_APP_BASE_URL}/web/v2/license`
    const dispatch = useDispatch();
    const {themeCssHeader} = useCssStateHook();
    const callAPI = async () => {
        if (userInfo?.tenant_id !== undefined && userInfo?.zone_id !== undefined) {
            try {
                getData(`${LicenseURL}/worker/all/${userInfo?.tenant_id}/${userInfo?.zone_id}`).then(data => {
                    const userInfo = data || [];
                    dispatch(setwdevice(userInfo))

                })
            } catch (error) {
                console.log(error);
            }
        }
    }
    useEffect(() => {
        callAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo?.tenant_id, userInfo?.zone_id])
    const handleClose = () => {
        setShowModal(false);
    };
    const QrhandleClick = (items) => {
        setShowModal(true);
        setQrData(items.license)

    }

    return loading ? (
        <Loading/>
    ) : (
        <>
            <Box display={"flex"} flexDirection={"column"} alignItems={"left"} >
                {licenseData.map((items) => (
                    <Box >
                        <Typography sx={{
                            fontWeight: "bold",
                            backgroundColor: themeCssHeader.backgroundColor,
                            color: "#ECECEC",
                            padding: "10px",
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px'
                        }} >{items.zone}</Typography>
                        {items.licenses?.map((itemslicense) => (
                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} >
                                <Box margin={"30px"} padding={"25px"} sx={{ border: "2px solid black", cursor: "pointer" }}>
                                    <QRCodeCanvas
                                        value={itemslicense.license}
                                        size={350}
                                        bgColor={"#ffffff"}
                                        fgColor={"#000000"}
                                        level={"L"}
                                        includeMargin={false}
                                        onClick={() => QrhandleClick(itemslicense)}
                                    />
                                </Box>
                            </Box>
                        ))
                        }
                    </Box>
                ))
                }

                {licenseData.length < 1 && <Typography variant="h5" my={3} sx={{ width: '100%', textAlign: 'center' }} >No data to display.</Typography>}
            </Box>
            {showModal &&
                <QrModel
                    showModal={showModal}
                    handleClose={handleClose}
                    data={qrData}
                />
            }
        </>
    )

}
const QrModel = ({ showModal, handleClose, data }) => {

    return <>
        <ThemeProvider theme={ModalTheme}>
            <Modal open={showModal} onClose={handleClose}>
                <Card>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Box margin={"30px"} padding={"20px"} sx={{ border: "2px solid black", cursor: "pointer" }}>

                            <QRCodeCanvas

                                value={data}
                                size={350}
                                bgColor={"#ffffff"}
                                fgColor={"#000000"}
                                level={"L"}
                                includeMargin={false}

                            />
                        </Box>

                    </Box>

                </Card>
            </Modal>
        </ThemeProvider>

    </>
}
export default WUnassignedFrom