import React from "react";
import { formatDateTime } from "../../../utils/helperFunctions";

const FillTableTwo = ({readings}) => {
  return (
    <table className="c99">
      <tbody>
        <tr className="c18">
          <td className="c7" colSpan={1} rowSpan={8}>
            <p className="c97">
              <span className="c22">MAJOR LABEL <br />APPLICATION</span>
            </p>
          </td>
          <td className="c58" colSpan={1} rowSpan={1}>
            <p className="c28">
              <span className="c68">Time:</span>
            </p>
          </td>
          {/* <td className="c25" colSpan={1} rowSpan={8}>
            <p className="c1">
              <span className="c22" />
            </p>
            <p className="c53">
              <span className="c22" />
            </p>
            <p className="c74">
              <span className="c10">
                Registration off-center, missing or incorrect text, product on
                label, etc.
              </span>
            </p>
          </td> */}
          <td className="c13" colSpan={1} rowSpan={8}>
            <p className="c49">
              <span className="c10">
              Registration off-center, missing or incorrect text, product on
                label, etc.
              </span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0 c29" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading?.time ? formatDateTime(reading.time, 'HH:mm') : 'N/A'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20 c29" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9"></span>
            </p>
          </td>
          <td className="c3 c29" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20 c29" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3 c29" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17 c29" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23 c29" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20 c29" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
        
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">8. Bottom</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.label8 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">9. Shade</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.label9 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">10. UPC</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.label10 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">11. Packer (Shipper)</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.label11 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">12. Shipper (Master)</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.label12 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">13. Over Label</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.label13 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
          {/* <td className="c16" colSpan={7} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">14. Other</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.label14 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
        {/* <tr className="c14">
          
          <td className="c38" colSpan={2} rowSpan={1}>
            <p className="c28">
              <span className="c68">MINOR</span>
            </p>
          </td>
          <td className="c16" colSpan={7} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
        </tr> */}
        <tr className="c18">
            <td className="c7" colSpan={1} rowSpan={7}>
            <p className="c97">
              <span className="c22">MINOR ASSEMBLY <br />INTO SHIPPER <br />(MASTER)</span>
            </p>
          </td>
          <td className="c58" colSpan={2} rowSpan={1}>
            <p className="c78 c86">
              <span className="c10">15. Unit Carton</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.shipper15 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c58" colSpan={2} rowSpan={1}>
            <p className="c78 c86">
              <span className="c10">16. Packer (Shipper)</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.shipper16 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c58" colSpan={2} rowSpan={1}>
            <p className="c78 c86">
              <span className="c10">17. Shipper (Master Box)</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.shipper17 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c58" colSpan={2} rowSpan={1}>
            <p className="c78 c86">
              <span className="c10">18. Poly / Bubble bag</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.shipper18 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c58" colSpan={2} rowSpan={1}>
            <p className="c78 c86">
              <span className="c10">19. Number of units weighted at the end of the line opaque components (3 / 6)</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.shipper19 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c58" colSpan={2} rowSpan={1}>
            <p className="c78 c86">
              <span className="c10">20. Number of units set on coding machine Primary (1/3/4)</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.shipper20 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c58" colSpan={2} rowSpan={1}>
            <p className="c78 c86">
              <span className="c10">21. Number of units set on coding machine
Secondary (1/3/4)</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.shipper21 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c3" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c17" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c23" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td>
          <td className="c20" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c9" />
            </p>
          </td> */}
        </tr>
      </tbody>
    </table>
  );
};

export default FillTableTwo;
