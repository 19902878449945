import { Box, Typography, IconButton } from "@mui/material";
import React from "react";
import uploadIcon from '../../../../images/upload-icon.svg'
import { Delete } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { DeleteModal } from "../../../UIComponents";

const styles = {
  root: {
    cursor: "pointer",
    textAlign: "center",
    background: 'none',
    display: "flex",
    "&:hover p,&:hover svg,& img, & video": {
      opacity: 1,
    },
    "& p, svg": {
      opacity: 0.4,
    },
    "&:hover img, &:hover video": {
      opacity: 0.3,
    },
  },
  noMouseEvent: {
    pointerEvents: "none",
  },
  iconText: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    background: 'none',
    color: '#3F4549'
  },
  hidden: {
    display: "none",
  },
  onDragOver: {
    "& img": {
      opacity: 0.3,
    },
    "& video": {
      opacity: 0.3,
    },
    "& p, svg": {
      opacity: 1,
    },
  },
};

const UploadFile = ({
    accept,
    label,
    height,
    file,
    handleFileChange,
    handleDeleteImage,
    handleDeleteVideo,
    optional = false,
    isDeletable = true,
    isEditable = true,
  }) => {
  const [isDragOver, setIsDragOver] = React.useState(false);
  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const isEdit = useSelector(state => state.steps.isEdit)
  const isStepEditable = useSelector(state => state.steps.isStepEditable)
  const fileId = React.useId()

  const handleClose = () => {
    setShowModal(false);
  };

  const stopDefaults = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true);
    },
    onMouseLeave: () => {
      setIsMouseOver(false);
    },
    onDragEnter: (e) => {
      stopDefaults(e);
      setIsDragOver(true);
    },
    onDragLeave: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
    },
    onDragOver: stopDefaults,
    onDrop: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
      if (e.dataTransfer.files[0]) {
        const url = URL.createObjectURL(e.dataTransfer.files[0])
        const type = e.dataTransfer.files[0].type
        const file_content = e.dataTransfer.files[0]
        handleFileChange(url, type, file_content);
      }
      onDrop(e);
    }
  };

  const onChange = (event) => {
    if (event.target.files !== null && event.target?.files?.length > 0) {
        const url = URL.createObjectURL(event.target.files[0])
        const type = event.target.files[0].type
        const file_content = event.target.files[0]
        handleFileChange(url, type, file_content);
    }
  }

  const onDrop =  (event) => {
    const url = URL.createObjectURL(event.dataTransfer.files[0])
    const type = event.dataTransfer.files[0].type
    const file_content = event.dataTransfer.files[0]
    handleFileChange(url, type, file_content);
  }

  const handleChange = (event) => {
    if (event.target.files[0]) {
        const url = URL.createObjectURL(event.target.files[0])
        const type = event.target.files[0].type
        const file_content = event.target.files[0]
        handleFileChange(url, type, file_content);
    }
    onChange(event);
  };
  
  return <Box width='100%' height={height}>
  <Box width='100%' height={height} position='relative'>
    {(file?.url !== '') && (
      <Box height={'100%'}>
          {file?.type?.includes('image') ? (
          <img
              src={file?.url || ''}
              width={'100%'}
              height={'100%'}
              required={!optional}
              alt={file?.name || 'Image'} style={{objectFit: 'contain', borderRadius: '8px'}}
          />
          ) : (
          <video
              src={file?.url || null}
              alt={file?.name || 'Video'}
              width={'100%'}
              height={'100%'}
              controls
              style={{borderRadius: '8px'}}
          />
          )}
      </Box>
    )}
    
    <Box width={'100%'} height={'100px'} position='absolute' sx={{left:'50%', top:'50%', transform: 'translate(-50%, -50%)', background: 'none' }}>
      {isEditable && (!file?.id && (file?.url === '' || isDragOver || isMouseOver)) && (
        <input
        onChange={handleChange}
        accept={accept}
        style={{...styles.hidden}}
        id={fileId}
        type="file"
        required={!optional && !file?.url}
        onInvalid={() => alert(`Add the ${accept.includes('image') ? 'image' : 'video'}`)}
        disabled={!isStepEditable}
          />
        )
      }
      {
        isEditable && <label
            htmlFor={fileId}
            {...dragEvents}
            style={isDragOver ? {...styles.onDragOver, ...styles.root, height: '100px'} : {...styles.root, height: '100px', position:'relative'}}
        >
            <Box
            height={'100%'}
            sx={styles.noMouseEvent}
            >
                  {(!file?.id  && (file?.url === '' || isDragOver || isMouseOver )) && (
                <Box height={'100%'} width={'100%'} sx={{...styles.iconText}}>
                    <Typography sx={{fontSize: '14px', fontWeight: 400}}>Drag your {label} here to upload</Typography>
                    <img src={uploadIcon} alt='upload-icon' style={{backgroundColor: 'rgba(255, 255, 255, 0.50)', margin: '10px 0', borderRadius: '20px', padding: '10px'}} />
                    <Typography sx={{fontSize: '14px', fontWeight: 400}}>Select a {label} from your computer</Typography>
                </Box>
            )}
            </Box>
        </label>
      }
    </Box>
    {
      (isDeletable && isEdit && !file?.file && file?.url !== '') && 
      <Box position='absolute' sx={{right:'1%', top:'1%'}}>
        <IconButton onClick={() => setShowModal(true)} disableFocusRipple disableTouchRipple sx={{background: '#fff', borderRadius: '50%', padding: '2px', '&:hover': {background: '#fff'}}} disabled={!isStepEditable} >
          <Delete color="error" />
        </IconButton>
      </Box>
    }
    
  </Box>
  <DeleteModal
    showModal={showModal}
    handleClose={handleClose}
    handleDelete={() => {
      accept.includes('image') ? handleDeleteImage() : handleDeleteVideo()
      handleClose();
    }}
    title={accept.includes('image') ? 'image' : 'video'}
  />

</Box>
};

export default UploadFile;
