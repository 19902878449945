import React from "react";
import Role from "../../components/Roles/Roles";
const UserRole = () => <Role/>


export default UserRole;





