import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "shiftSlice",
  initialState: {
    items: [],
  },
  reducers: {
    setShift: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const { setShift } = slice.actions;

export const getShift = (state) => {
  return state.shiftSlice.items;
};

export default slice.reducer;
