import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "tenanturlSlice",
  initialState: {
    items: [],
    itemsname: [],
  },
  reducers: {
    settenanturl: (state, action) => {
      state.items = action.payload;
    },
    settenantname: (state, action) => {
        state.itemsname = action.payload;
      },
  },
});

export const { settenanturl ,settenantname } = slice.actions;

export const gettenanturl = (state) => {
  return state.tenanturlSlice.items;
};

export default slice.reducer;
