import { createSlice } from "@reduxjs/toolkit";


export const slice = createSlice({
  name: "allUsersSlice",
  initialState: {
    items: [],
    totalItems: 0,
    rowsPerPage: 10,
    page: 1,
  },
  reducers: {
    setUsers: (state, action) => {
      state.items = action.payload.users || [];
      state.totalItems = action.payload.count
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload
    }
  },
});

export const { setUsers, setPage, setRowsPerPage } = slice.actions;

export const getUsers = (state) => {
  return state.allUsersSlice.items;
};

export default slice.reducer;



