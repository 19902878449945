import { fetchSkills, setSkill } from "../../features/skill/skillSlice";
import { setWorkers } from "../../features/workers/workersSlice";
import useApi from "../../hooks/useAPI";
import Allergy from "../../images/workerimages/Allergy.png";
import Chair from "../../images/workerimages/Chair.png";
import NoCapping from "../../images/workerimages/NoCapping.png";
import { Loading } from "../UIComponents";
import { useCssStateHook } from "../UIComponents/ComonCss";
import { East } from "@mui/icons-material";
import {
  Box,
  Stack,
  Button,
  Typography,
  TextField,
  FormControl,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";

const initialState = {
  first_name: "",
  last_name: "",
  color_code: "",
  code: "",
  skill: "",
  zone_id: "",
  accommodations: [],
};

const AddEditWorkers = () => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const workersUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/worker`;
  const zoneUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/zone`;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const userInfo = useSelector((state) => state.user.user);
  const workers = useSelector((state) => state.worker.workers[0]?.workers);
  const skills = useSelector((state) => state.skill.items);
  const { loading, getData, patchData, postData } = useApi();
  const [zones, setZones] = useState([]);
  const [limitations, setLimitations] = useState([]);
  const [selectedLimitations, setSelectedLimitations] = useState([]);
  const [workercodeValid, setWorkerCodeValid] = useState(false);
  const [workerCodeError, setWorkerCodeError] = useState("");
  const [editWorkersData, setEditWorkersData] = useState(initialState);
  const [formData, setFormData] = useState(editWorkersData);
  const { themeCssButton } = useCssStateHook();
  const skillURL = `${process.env.REACT_APP_BASE_URL}/web/v2/worker/skill`;
  const mediaView = `${process.env.REACT_APP_BASE_URL_ADMIN}/admin/media`;

  React.useEffect(() => {
    apiCall();
    // eslint-disable-next-line
  }, [userInfo.tenant_id]);

  const apiCall = () => {
    try {
      const response = getData(`${skillURL}/all/${userInfo.tenant_id}`);
      response.then((data) => {
        dispatch(setSkill(data || []));
      });
    } catch (error) {
      console.log(error);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getFileData = (name) => {
    return new Promise((resolve) => {
      postData(`${mediaView}/view`, {
        file_name: name,
      })
        .then((data) => {
          resolve({ status: true, data });
        })
        .catch((error) => {
          resolve({ status: false, error });
        });
    });
  };

  const getLimitationImage = (name) => {
    return {
      Chair,
      Allergy,
      "No Capping": NoCapping,
    }[name];
  };

  const fetchLimitations = async () => {
    try {
      const response = await getData(
        `${workersUrl}/limitations/all/${userInfo.tenant_id}`
      );
      setLimitations(response);
      //   const t = await getFileData('1/1/worker:limitation_1/_0/i_1_1714646706083')
      console.log(" setLimitations ", response);
      //   console.log(' setLimitations Images', t)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userInfo.tenant_id) {
      dispatch(fetchSkills(userInfo.tenant_id));
      fetchLimitations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.tenant_id, dispatch]);

  const fetchWorkers = async () => {
    try {
      const zonesData = await getData(
        `${zoneUrl}/all/${userInfo.tenant_id}/page/1/count/9999`
      );
      setZones(zonesData.zones);
      const zoneWiseWorkers = [];
      if (zonesData?.count > 0) {
        for (const zone of zonesData.zones || []) {
          const response = await getData(
            `${workersUrl}/all/${userInfo.tenant_id}/${zone.id}`
          );
          zoneWiseWorkers.push({ ...zone, workers: response });
        }
      }
      dispatch(setWorkers(zoneWiseWorkers || []));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userInfo.tenant_id) {
      fetchWorkers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.tenant_id, workersUrl, zoneUrl]);

  useEffect(() => {
    if (id !== undefined) {
      const editworkers = workers.find((d) => d.id === Number(id));
      if (editworkers) {
        setEditWorkersData({
          id: parseInt(id),
          first_name: editworkers.first_name,
          last_name: editworkers.last_name,
          color_code: editworkers.color_code,
          code: editworkers.code,
          skills: editworkers.skills,
          zone_id: editworkers.zone_id,
        });
        if (editworkers.limitations) {
          const l = editworkers.limitations.split(",")?.map((i) => parseInt(i));
          setSelectedLimitations(l);
        }
        setFormData({
          id: parseInt(id),
          first_name: editworkers.first_name,
          last_name: editworkers.last_name,
          color_code: editworkers.color_code,
          code: editworkers.code,
          skills: editworkers.skills,
          zone_id: editworkers.zone_id,
        });
      }
    }
  }, [id, workers]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangenumber = (e) => {
    const { name, value } = e.target;
    const numericPattern = /^\d{0,6}$/; // Regular expression to allow up to 6 numeric characters
    if (value === "" || numericPattern.test(value)) {
      setWorkerCodeValid(true);
      if (value.length > 6) {
        setWorkerCodeError("Worker code must be exactly 6 numeric digits");
      } else {
        setWorkerCodeError("");
        setWorkerCodeValid(false);
      }
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setWorkerCodeValid(true);
      setWorkerCodeError("Worker code must be exactly 6 numeric digits");
    }
  };

  const handleBlur = (e) => {
    const { value } = e.target;
    if (value.length < 6) {
      setWorkerCodeValid(true);
      setWorkerCodeError("Worker code must be exactly 6 numeric digits");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.id) {
      const selectedSkill = skills.find(
        (skill) => skill.skills === formData.skills
      );
      const editData = {
        ...formData,
        color_code: selectedSkill.color_code,
        limitations: selectedLimitations.join(","),
      };
      try {
        await patchData(`${workersUrl}/update`, editData);
        fetchWorkers();
        navigate("/admin/workers");
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const selectedSkill = skills.find(
          (skill) => skill.skills === formData.skills
        );
        const createData = {
          ...formData,
          color_code: selectedSkill.color_code,
          tenant_id: userInfo.tenant_id,
          zone_id: userInfo.zone_id,
          limitations: selectedLimitations.join(","),
        };
        await postData(
          `${workersUrl}/add/${userInfo.tenant_id}/${formData.zone_id}`,
          createData
        );
        fetchWorkers();
        navigate("/admin/workers");
      } catch (error) {
        console.log(error);
      }
    }
    setEditWorkersData(initialState);
  };

  return loading ? (
    <Loading />
  ) : (
    <Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <form
          style={{ marginTop: "20px", width: "100%" }}
          onSubmit={handleSubmit}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 700,
              color: "#2E3646",
              margin: 2,
            }}
            align="left"
          >
            {formData.id ? "Edit Worker" : "Add Worker"}
          </Typography>
          <FormGroup>
            <FormControl sx={{ mt: 2 }}>
              <TextField
                label="First Name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl sx={{ mt: 2 }}>
              <TextField
                label="Last Name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl sx={{ mt: 2 }}>
              <TextField
                label="Worker Code"
                helperText={workerCodeError}
                error={workercodeValid}
                name="code"
                value={formData.code}
                onChange={handleChangenumber}
                onBlur={handleBlur}
              />
            </FormControl>
            <FormControl sx={{ mt: 2 }}>
              <InputLabel id="skill-select-label">Skill</InputLabel>
              <Select
                labelId="skill-select-label"
                id="skill-select"
                value={formData.skills}
                name="skills"
                onChange={handleChange}
                label="Skills"
                renderValue={(selected) => {
                  const selectedSkill = skills.find(
                    (skill) => skill.skills === selected
                  );
                  return (
                    <Box sx={{ display: "flex", alignItems: "left" }}>
                      <Box
                        sx={{
                          width: 15,
                          height: 20,
                          backgroundColor: selectedSkill?.color_code,
                          marginRight: 1,
                        }}
                      />
                      {selected}
                    </Box>
                  );
                }}
              >
                {skills.map((skill) => (
                  <MenuItem key={skill.id} value={skill.skills}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          width: 15,
                          height: 20,
                          backgroundColor: skill.color_code,
                          marginRight: 2,
                        }}
                      />
                      {skill.skills}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ mt: 2 }}>
              <InputLabel id="zone-select-label">Zone</InputLabel>
              <Select
                labelId="zone-select-label"
                id="zone-select"
                value={formData.zone_id}
                name="zone_id"
                onChange={handleChange}
              >
                {zones.map((zone) => (
                  <MenuItem key={zone.id} value={zone.id}>
                    {zone.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 2 }}>
              <Box component="fieldset" sx={{ border: "1px solid #c4c4c4" }}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 500,
                    // color: "#2E3646",
                    margin: 2,
                    position: "absolute",
                    top: "-30px",
                    backgroundColor: "white",
                    color: "#878181",
                  }}
                  align="left"
                >
                  Accommodations
                </Typography>
                {/* <legend> Accommodations</legend> */}
                <div
                  style={{
                    border: "3px solid #c4c4c4",
                    borderRadius: "10px",
                    height: "150px",
                    width: "400px",
                    margin: "15px",
                    marginTop: "30px",
                    boxShadow: "1px 1px 7px 4px rgba(0,0,0,0.10)",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    {limitations?.map((l) => {
                      return (
                        <>
                          <Tooltip title={l.name} placement="top">
                            <Box
                              sx={{
                                border: "1px solid grey",
                                margin: "5px",
                                width: "fit-content",
                              }}
                            >
                              <img
                                style={{ width: "50px", height: "50px" }}
                                src={getLimitationImage(l.name)}
                                alt="checked"
                              />
                            </Box>
                          </Tooltip>
                          <Checkbox
                            {...label}
                            checked={selectedLimitations.includes(l.id)}
                            onChange={(e) => {
                              if (e?.target?.checked) {
                                setSelectedLimitations([
                                  ...selectedLimitations,
                                  l.id,
                                ]);
                              } else {
                                const updatedLimitations =
                                  selectedLimitations.filter((i) => i !== l.id);
                                setSelectedLimitations([...updatedLimitations]);
                              }
                            }}
                          />
                        </>
                      );
                    })}
                    {false && (
                      <>
                        <Tooltip title="Chair" placement="top">
                          <Box
                            sx={{
                              border: "1px solid grey",
                              margin: "5px",
                              width: "fit-content",
                            }}
                          >
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={Chair}
                              alt="checked"
                            />
                          </Box>
                        </Tooltip>
                        <Checkbox {...label} />
                        <Tooltip title="Allergy" placement="top">
                          <Box
                            sx={{
                              border: "1px solid grey",
                              margin: "5px",
                              width: "fit-content",
                            }}
                          >
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={Allergy}
                              alt="checked"
                            />
                          </Box>
                        </Tooltip>
                        <Checkbox {...label} />
                        <Tooltip title="NoCapping" placement="top">
                          <Box
                            sx={{
                              border: "1px solid grey",
                              margin: "5px",
                              width: "fit-content",
                            }}
                          >
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={NoCapping}
                              alt="checked"
                            />
                          </Box>
                        </Tooltip>
                        <Checkbox {...label} />
                      </>
                    )}
                  </Box>
                </div>
              </Box>
            </FormControl>
          </FormGroup>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            mt={2}
          >
            <Button
              variant="text"
              size="large"
              component={NavLink}
              to="/admin/workers"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={themeCssButton}
              endIcon={<East />}
            >
              {formData.id ? "Update" : "Submit"}
            </Button>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};

export default AddEditWorkers;
