import {
  markCorrectOption,
  updateOptionImage,
  optionInputChange,
} from "../../../../features/ProductionResourceSteps/stepsSlice";
import UploadFile from "../Media/UploadFile";
import { Check, RadioButtonUnchecked } from "@mui/icons-material";
import {
  Box,
  Grid,
  OutlinedInput,
  InputAdornment,
  Checkbox,
} from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

const Option = ({ option, index, question, qIndex }) => {
  const optionLabels = ["A", "B", "C", "D", "E"];

  const dispatch = useDispatch();
  const isStepEditable = useSelector((state) => state.steps.isStepEditable);

  const handleCheckboxChange = (questionIndex, optionIndex) => {
    dispatch(markCorrectOption({ questionIndex, optionIndex }));
    // setSelectedOption(optionIndex)
  };

  const handleOptionInputChange = (e, questionIndex, optionIndex) => {
    dispatch(
      optionInputChange({ questionIndex, optionIndex, value: e.target.value })
    );
  };

  const handleOptionImageChange = (url, type, file) => {
    dispatch(updateOptionImage({ url, type, file, qIndex, index }));
  };

  return (
    <Grid item md={6}>
      <OutlinedInput
        fullWidth
        startAdornment={
          <InputAdornment
            position="start"
            sx={{ color: option.is_correct ? "#fff" : "#000" }}
          >
            {optionLabels[index]}.
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="start">
            <Checkbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={
                <Check
                  sx={{
                    background: "rgba(227, 236, 250, 0.40)",
                    color: "#fff",
                    borderRadius: "50%",
                  }}
                />
              }
              checked={option.is_correct}
              onChange={() => handleCheckboxChange(qIndex, index)}
            />
          </InputAdornment>
        }
        value={option.option}
        onChange={(e) => handleOptionInputChange(e, qIndex, index)}
        sx={option.is_correct ? { background: "#18A765", color: "#fff" } : {}}
        required
        inputProps={{ readOnly: !!question.id }}
        disabled={!isStepEditable}
      />
      <Box
        sx={{
          width: "12rem",
          height: "10rem",
          boeder: "1px solid #5F6D7E",
          backgroundColor: !option?.file?.url ? "#E6E9EC" : "none",
          borderRadius: "10px",
          mt: 1,
        }}
        position="relative"
      >
        <UploadFile
          accept="image/*"
          optional={true}
          label="image"
          height="10rem"
          file={option?.image}
          handleFileChange={handleOptionImageChange}
          handleDeleteImage={() => {}}
          isDeletable={false}
          isEditable={!question.id}
        />
      </Box>
    </Grid>
  );
};

export default Option;
