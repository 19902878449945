import React from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import useApi from '../../hooks/useAPI'
import { CosmeticaTable, Loading } from '../UIComponents'
import muiStyles from '../../styles/MuiComponentStyles'
import { useCssStateHook } from "../UIComponents/ComonCss";


const ProductionResource = () => {
    const location = useLocation()
    const { getData, loading } = useApi();
    const [steps, setSteps] = React.useState([])
    const productionUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/production`;
    const { themeCssHeader} = useCssStateHook();

    React.useEffect(() => {
        fetchSteps()
    // eslint-disable-next-line
    }, [])

    const fetchSteps = () => {
        try {
            getData(`${productionUrl}/step/all/${location.state.resource.id}`).then(data => {
                setSteps(data || [])
            })
        } catch (error) {
            console.log(error);
        }
    }
    const fields = ['Position', 'Name', 'Description', 'Min Crew', 'Max Crew', 'Video', 'Images', 'Questions']
    const rows = steps.map(step => {
        const {position, name, desc, min_crew, max_crew} = step
        const has_video = step.media.find(m => m.type === 'video') ? 'Yes' : 'No'
        const images_count = step.media?.filter(m => m.type === 'image').length
        return {position: <strong>{position}</strong>, name, desc, min_crew, max_crew, has_video, images_count, questionsCount: 0}
    })

    if (loading) {
        return <Loading />
    }
    
    return <>
        <Box mt={3}>
            <Typography sx={muiStyles.pageTitle}>{location.state.resource.name}</Typography>
            <Typography>{location.state.resource.desc}</Typography>
        </Box>
        <Box mt={3}>
            <CosmeticaTable fields={fields} rows={rows} pagination={false} 
            isHeadercss={themeCssHeader}
            
            />
        </Box>
    </>
}

export default ProductionResource