import React, { useEffect, useState } from "react";
import {
    Box,
    Stack,
    Button,
    Typography,
    TextField,
    FormControl,
    FormLabel,
    FormGroup,
} from "@mui/material";
import { useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useAPI";
import muiStyles from "../../styles/MuiComponentStyles";
import { East } from "@mui/icons-material";
import { Loading } from "../UIComponents";
import { useCssStateHook } from "../UIComponents/ComonCss";


export const categoryList = [
    { category: "Users And Roles", value: "x" },
    { category: "Production Lines", value: "x" },
    { category: "Product and Production Resource", value: "x" },
    { category: "Scheduling Work Order", value: "x" },
    { category: "Zone Management", value: "x" },
];
export const accessList = [
    { name: "None", value: "x", id: 0 },
    { name: "Read-Only", value: "r", id: 1 },
    { name: "Full-Access", value: "w", id: 2 },
];
const AddEditPermission = () => {
    const permissionURL = `${process.env.REACT_APP_BASE_URL}/web/v2/permission`
    const { loading, postData, patchData } = useApi()
    const { id } = useParams();
    const userInfo = useSelector((state) => state.user.user);
    const permissionData = useSelector((state) => state.permission.items);
    let navigate = useNavigate();
    const {themeCssButton,  themeCssPermission} = useCssStateHook();

    const initialState = {
        name: "",
        permission: "",
        tenant_id: 0,
    };
    const [users, setUsers] = useState(initialState);
    const [userPermissions, setUserPermissions] = useState(categoryList);
    const [isNumberEntered, setIsNumberEntered] = useState(false);
    const editdata = (id) => {
        const editpermission = permissionData.find(d => d.id === Number(id))
        setUsers({ id, name: editpermission?.name })
        const pData = JSON.parse(JSON.stringify(userPermissions));
        // eslint-disable-next-line
        pData?.map((ele, index) => {
            ele.value = editpermission?.permission?.split('')[index]
        })
        setUserPermissions(pData);
    }
    useEffect(() => {
        if (id !== undefined) {
            editdata(Number(id))
        }
        // eslint-disable-next-line
    }, [id])

    const handleChange = (e) => {
        const validName = /^[A-Za-z\s]+$/.test(e.target.value);
        setIsNumberEntered(!validName);
        setUsers((prevUser) => {
            return {
                ...prevUser,
                [e.target.name]: e.target.value,
            };
        });
    };
    const handleChangeButton = (e, index) => {
        const data = JSON.parse(JSON.stringify(userPermissions));
        data[index].value = e;
        setUserPermissions(data);
    };
    // const buttonStyle = {
    //     backgroundColor: "#2E3646",
    //     color: "#fff",
    //     "&:hover": {
    //         backgroundColor: "#2E3646",
    //         color: "#fff",
    //         opacity: 0.7,
    //     },
    // };
    const handleSubmit = async (e, users, userPermissions) => {
        e.preventDefault()
        e.target.reset()
        if (id) {
            const accessString = userPermissions?.map((ele) => {
                return ele.value
            })?.join('')
            users = {
                ...users,
                permission: accessString,

            }
            patchData(`${permissionURL}/update`, users).then((res) => {
                navigate("/admin/permission")
            }).catch((err) => {
                console.log("Error ", err)
            })

        } else {
            const accessString = userPermissions?.map((ele) => {
                return ele.value
            })?.join('')
            users = {
                ...users,
                permission: accessString,
                tenant_id: userInfo?.tenant_id,

            }
            postData(`${permissionURL}/create`, users).then((res) => {
                navigate("/admin/permission")
            }).catch((err) => {
                console.log("Error ", err)
            })

        }

    }
    const  handleerror=(e)=>{
        e.preventDefault()
        if(!isNumberEntered){
            handleSubmit(e, users, userPermissions)
        }
    }
    return loading ? (
        <Loading/>
    ) : (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <form
                    style={{ marginTop: "20px" }}
                    onSubmit={(e) => handleerror(e)}
                >
                    
                    <Typography sx={{
                        fontSize: "24px",
                        fontWeight: 700,
                        color: "#2E3646",
                        margin: 2
                    }}
                        align="left">
                        Create Permission Template
                    </Typography>
                    <FormGroup>
                        <FormControl sx={{ mt: 2, width: "500px" }}>
                            <TextField
                                label="Permission name"
                                name="name"
                                value={users.name}
                                variant="outlined"
                                onChange={handleChange}
                                required={true}
                                error={isNumberEntered}
                            />
                            {isNumberEntered && (
                                <p style={{ color: 'red' }}>Please enter a valid name without numbers.</p>
                            )}
                        </FormControl>
                        {userPermissions?.length > 0
                            ? userPermissions?.map((ele, index) => {
                                return (
                                    <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                                        <FormLabel sx={{ mr: 4, width: '170px' }}>{ele.category}</FormLabel>
                                        <Box
                                            // sx={{ mt: 1, display: "flex", flexDirection: "row", height: '40px' , cursor: 'pointer', border:'1px solid #c8c8c8', borderRadius:'4px' }}
                                           sx={muiStyles.Permission}
                                            key={`permission-list-${index + 1}`}
                                        >
                                            
                                            <Typography
                                                sx={{
                                                    backgroundColor:
                                                        ele.value === "x" ? themeCssPermission.backgroundColornone : "#E6E9EC",
                                                    color: ele.value === "x" ? "white" : "#2E3646",
                                                    padding: '11px',
                                                     borderTopLeftRadius:'4px',
                                                     borderBottomLeftRadius:'4px',
                                                     borderTopRightRadius : '0px',
                                                     borderBottomRightRadius : '0px',
                                                     lineHeight:'16px',
                                                    
                                                 
                                                }}
                                              
                                                onClick={() => {
                                                    handleChangeButton("x", index);
                                                }}
                                            >
                                                None
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    backgroundColor:
                                                        ele.value === "r" ? themeCssPermission.backgroundColorread : "#E6E9EC",
                                                    color: ele.value === "r" ? "white" : "#2E3646",
                                                    padding: '11px',
                                                    borderRadius:"0px",
                                                    // borderRight:'1px solid red',
                                                  
                                                    lineHeight:'16px',
                                                  

                                                }}
                                                onClick={() => {
                                                    handleChangeButton("r", index);
                                                }}
                                            >
                                                Read-Only
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    backgroundColor:
                                                        ele.value === "w" ? themeCssPermission.backgroundColorfull : "#E6E9EC",
                                                    color: ele.value === "w" ? "white" : "#2E3646",
                                                    padding: '11px',
                                                    borderRadius:"0px",
                                                    borderTopRightRadius : '4px',
                                                     borderBottomRightRadius : '4px',
                                                    
                                                    lineHeight:'16px',
                                                   
                                                    // borderTopRightRadius: "4px",
                                                    // borderBottomRightRadius: "4px",

                                                }}
                                                onClick={() => {
                                                    handleChangeButton("w", index);
                                                }}
                                            >
                                                Full-Access
                                            </Typography>
                                    
                                        </Box>
                                    </Box>
                                );
                            })
                            : null}


                    </FormGroup>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                        margin={5}
                    >
                        <Button
                            type="reset"
                            variant="text"
                            size="large"
                            component={NavLink}
                            to="/admin/permission"
                            sx={muiStyles.secondaryButton}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            // sx={buttonStyle }
                            sx={themeCssButton}
                            endIcon={<East />}
                        >
                            {users.id ? "Update" : "Submit"}
                        </Button>
                    </Stack>
                </form>
            </Box>
        </Box>
    );
};

export default AddEditPermission;
