import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate()

  const getData = async (url) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(url);
      setLoading(false);
      return response.data;
    } catch (error) {
      setError(error);
      setLoading(false);
      throw error; // Re-throw the error for the caller to handle
    }
  };

  const postData = async (url, data, redirectUrl=null) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(url, data);
      setLoading(false);
      if (redirectUrl) {
        navigate(redirectUrl)
      }
      return response.data;
    } catch (error) {
      setError(error);
      setLoading(false);
      throw error;
    }
  };

  const putData = async (url, data, redirectUrl=null) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.put(url, data);
      setLoading(false);
      if (redirectUrl) {
        navigate(redirectUrl)
      }
      return response.data;
    } catch (error) {
      setError(error);
      setLoading(false);
      throw error;
    }
  };

  const patchData = async (url, data, redirectUrl=null) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.patch(url, data);
      setLoading(false);
      if (redirectUrl) {
        navigate(redirectUrl)
      }
      return response.data;
    } catch (error) {
      setError(error);
      setLoading(false);
      throw error;
    }
  };

  const deleteData = async (url, redirectUrl=null) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.delete(url);
      setLoading(false);
      if (redirectUrl) {
        navigate(redirectUrl)
      }
      return response.data;
    } catch (error) {
      setError(error);
      setLoading(false);
      throw error;
    }
  };

  return { getData, postData, putData, patchData, deleteData, loading, error };
};

export default useApi;
