import { setmdevice } from "../../features/mdevices/mdevicesSlice";
import { setZones } from "../../features/zones/zonesSlice";
import useApi from "../../hooks/useAPI";
import muiStyles from "../../styles/MuiComponentStyles";
import { ModalFormTheme } from "../../styles/MuiThemes";
import { Loading } from "../UIComponents";
import { CosmeticaModal } from "../UIComponents";
import { useCssStateHook } from "../UIComponents/ComonCss";
import { Close } from "@mui/icons-material";
import { East } from "@mui/icons-material";
import {
  Card,
  IconButton,
  Modal,
  ThemeProvider,
  Typography,
  createTheme,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { QRCodeCanvas } from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ModalTheme = createTheme({
  typography: {
    body1: {
      fontWeight: 400,
      lineHeight: "24px",
      textAlign: "center",
      fontSize: "16px",
    },
    subtitle1: {
      color: "#4C4C4C",
      fontWeight: 600,
      margin: "24px 0",
      lineHeight: "24px",
      textAlign: "center",
      fontSize: "24px",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          padding: "20px",
          borderRadius: "10px",
        },
      },
    },
  },
});

const MUnassignedFrom = () => {
  const [showModal, setShowModal] = useState(false);
  const [showZoneModal, setShowZoneModal] = useState(false);
  const [qrData, setQrData] = useState();
  const userInfo = useSelector((state) => state.user.user);
  const licenseData = useSelector((state) => state.mdevices.items);
  const { loading, getData, patchData } = useApi();
  const LicenseURL = `${process.env.REACT_APP_BASE_URL}/web/v2/license`;
  const zones = useSelector((state) => state.zones.items) || [];
  const [assignZone, setAssignZone] = useState({
    zoneID: null,
    licenseID: null,
  });

  const dispatch = useDispatch();
  const { themeCssHeader } = useCssStateHook();
  const callAPI = async () => {
    if (userInfo?.tenant_id !== undefined && userInfo?.zone_id !== undefined) {
      try {
        getData(
          `${LicenseURL}/leader/all/${userInfo?.tenant_id}/${userInfo?.zone_id}`
        ).then((data) => {
          const userInfo = data || [];
          dispatch(setmdevice(userInfo));
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchZones = () => {
    try {
      const zoneUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/zone`;
      const response = getData(
        `${zoneUrl}/all/${userInfo.tenant_id}/page/${1}/count/${99999}`
      );
      response.then((data) => {
        dispatch(setZones(data || []));
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callAPI();
    fetchZones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.tenant_id, userInfo?.zone_id]);

  useEffect(() => {
    if (zones.length < 1) {
      fetchZones();
    }
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };
  const handleZoneClose = () => {
    setShowZoneModal(false);
  };
  const QrhandleClick = (items) => {
    setShowModal(true);
    setQrData(items.license);
  };

  const handleZoneSubmit = (e) => {
    e.preventDefault();
    try {
      const response = patchData(
        `${process.env.REACT_APP_BASE_URL}/web/v2/license/${assignZone.licenseID}/assign/zone/${assignZone.zoneID}`,
        {}
      );
      response.then((data) => {
        callAPI();
        setAssignZone({
          zoneID: null,
          licenseID: null,
        });
        handleZoneClose();
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log("Lic", assignZone);

  return loading ? (
    <Loading />
  ) : (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"left"}>
        {licenseData.map((items) => (
          <Box>
            <Typography
              sx={{
                fontWeight: "bold",
                backgroundColor: themeCssHeader.backgroundColor,
                color: "#ECECEC",
                padding: "10px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              {items.zone}
            </Typography>
            {items.licenses?.map((itemslicense) => (
              <Box>
                <Box
                  margin={"30px"}
                  padding={"25px"}
                  sx={{ border: "2px solid black", cursor: "pointer" }}
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Box width={"50%"}>
                    <QRCodeCanvas
                      value={itemslicense.license}
                      size={350}
                      bgColor={"#ffffff"}
                      fgColor={"#000000"}
                      level={"L"}
                      includeMargin={false}
                      onClick={() => QrhandleClick(itemslicense)}
                    />
                  </Box>
                  <Box
                    width={"50%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Box>
                      <Button
                        variant="outlined"
                        sx={{ mr: 1 }}
                        onClick={() => {
                          setAssignZone((prev) => ({
                            ...prev,
                            licenseID: itemslicense.id,
                          }));
                          setShowZoneModal(true);
                        }}
                      >
                        Assign Zone
                      </Button>
                      {/* <Button variant="outlined">Log</Button> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        ))}
        {licenseData.length < 1 && (
          <Typography
            variant="h5"
            my={3}
            sx={{ width: "100%", textAlign: "center" }}
          >
            No data to display.
          </Typography>
        )}
      </Box>

      {showModal && (
        <QrModel
          showModal={showModal}
          handleClose={handleClose}
          data={qrData}
        />
      )}

      {showZoneModal && (
        <CosmeticaModal
          showModal={showZoneModal}
          closeModal={handleZoneClose}
          title="Update License"
        >
          <Box>
            <ThemeProvider theme={ModalFormTheme}>
              <form onSubmit={handleZoneSubmit}>
                <FormGroup>
                  <FormLabel>Select Zone</FormLabel>
                  <FormControl>
                    {/* <InputLabel>Time Zone*</InputLabel> */}
                    <Select
                      name="zone"
                      required
                      placeholder="zone"
                      value={assignZone.zoneID}
                      onChange={(e) =>
                        setAssignZone((prev) => ({
                          ...prev,
                          zoneID: e.target.value,
                        }))
                      }
                    >
                      {zones?.map((zone) => (
                        <MenuItem value={zone.id} key={zone.id}>
                          {zone.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormGroup>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                  mt={4}
                >
                  <Button
                    type="reset"
                    variant="text"
                    size="large"
                    onClick={handleZoneClose}
                    sx={muiStyles.secondaryButton}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    // sx={muiStyles.primaryButton}
                    sx={muiStyles.primaryButton}
                    endIcon={<East />}
                  >
                    Submit
                  </Button>
                </Stack>
              </form>
            </ThemeProvider>
          </Box>
        </CosmeticaModal>
      )}
    </>
  );
};

const QrModel = ({ showModal, handleClose, data }) => {
  return (
    <>
      <ThemeProvider theme={ModalTheme}>
        <Modal open={showModal} onClose={handleClose}>
          <Card>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Box
                margin={"30px"}
                padding={"20px"}
                sx={{ border: "2px solid black", cursor: "pointer" }}
              >
                <QRCodeCanvas
                  value={data}
                  size={350}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                  includeMargin={false}
                />
              </Box>
            </Box>
          </Card>
        </Modal>
      </ThemeProvider>
    </>
  );
};
export default MUnassignedFrom;
