import "./App.css";
import LinesVariation from "./components/Line/LinesVariation";
import useAxiosTokenInterceptor from "./useAxiosTokenInterceptor";
import {
  Home,
  Profile,
  ExternalApi,
  Zones,
  Permission,
  User,
  Role,
  Line,
  ScheduleJobs,
  Products,
  AddEditProduct,
  AddProductionResource,
  EditProductionResource,
  MDevicesView,
  WDevicesView,
  Shift,
  Skill,
  ProductionResource,
  ProductStages,
  ProductDetails,
  Report,
  SummaryReport ,
  Workers,
  CrewCreate,
  CrewEdit,
  CrewList,
  CrewDetail,
  QualityReport 
} from "./views";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AddEditPermisstion from "./views/Admin/addPermission";
import { NavBar, Footer, Loading, Login } from "./components";
import TenantsViews from "./views/Settings/tenants";
import AddEditWorkers from "./views/Admin/addWorkers";
import { WorkerReports } from "./views/Reports";
// import { ManagementDevice } from "./views/Devices";






const App = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  const [clonedElement, setClonedElement] = React.useState(null);
  useAxiosTokenInterceptor(isAuthenticated);

  if (isLoading) {
    return <Loading />;
  }

  const handleClone = (originalElement) => {
    if (originalElement) {
      const clonedElement = originalElement.cloneNode(true);
      clonedElement.id = 'clonedElement';
      setClonedElement(clonedElement);
    }
  };


  return (
    <div id="app" className="d-flex flex-column h-100">
      <NavBar onClone={handleClone}/>
      <div className="container flex-grow-1" style={{minHeight: 'calc(100vh - 120px)'}}>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/login" exact element={<Login />} />

          <Route path="/external-api" element={<ExternalApi />} />

          <Route path="schedule-jobs">
            <Route path="" element={<ScheduleJobs />} />
          </Route>

          <Route path='crew'>
            <Route path="" element={<CrewList />} />
            <Route path="/crew/create" element={<CrewCreate />} />
            <Route path="/crew/edit/:templateId" element={<CrewEdit />} />
            <Route path="/crew/:templateId" element={<CrewDetail />} />
          </Route>

          <Route path="products">
            <Route path="" element={<Products />} />
            <Route path="add-product" element={<AddEditProduct />} />
            <Route
              path="edit-product/:productId"
              element={<AddEditProduct />}
            />
            <Route
              path="add-production-resouce"
              element={<AddProductionResource />}
            />
            <Route
              path="edit-production-resouce/:resourceId"
              element={<EditProductionResource />}
            />
            <Route
              path=":id"
              element={<ProductDetails />}
            />
          </Route>
          
          <Route path="production-resouce">
            <Route path=":resourceId" element={<ProductionResource />} />
            <Route
              path="add"
              element={<AddProductionResource />}
            />
            <Route
              path="edit/:resourceId"
              element={<EditProductionResource />}
            />
          </Route>

          <Route path="settings">
            <Route path="profile" element={<Profile />} />
            <Route path="tenants" element={<TenantsViews />} />
            {/* <Route path="account" element={<Account />} />
            <Route path="dashboard" element={<Dashboard />} /> */}
          </Route>

          <Route path="device">
            <Route path="Management-devices" element={<MDevicesView />} />
            <Route path="Worker-devices" element={<WDevicesView/>} />
          

          </Route>

          <Route path="admin">
            <Route path="user" element={<User />} />
            <Route path="role" element={<Role />} />
            <Route path="line" element={<Line />} />
            <Route path="line/:id" element={<LinesVariation />} />
            <Route path="zones" element={<Zones />} />
            {/* <Route path="workers" element={<Workers />} /> */}
            <Route path="shift" element={<Shift />} />
            <Route path="skill" element={<Skill />} />
            <Route path="product-stages" element={<ProductStages />} />

          </Route>
          <Route path="/admin/workers">
            <Route path="" element={<Workers />} />
            <Route path="add-workers" element={<AddEditWorkers />} />
            <Route
              path="edit-workers/:id"
              element={<AddEditWorkers />}
            />
          </Route>
          <Route path="/admin/permission">
            <Route path="" element={<Permission />} />
            <Route path="add-permission" element={<AddEditPermisstion />} />
            <Route
              path="edit-permission/:id"
              element={<AddEditPermisstion />}
            />
          </Route>
          <Route path="/admin/reports">
            <Route path="" element={<Report clonedElement={clonedElement} />} />
            <Route
              path="summary/:id"
              element={<SummaryReport clonedElement={clonedElement} />}
            />
            <Route
              path="quality/:id"
              element={<QualityReport clonedElement={clonedElement} />}
            />
          </Route>
          <Route path="/admin/worker-reports" >
          <Route path="" element={<WorkerReports clonedElement={clonedElement} />} />
          </Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
