import { colors } from './colors'

const muiStyles = {
  primaryButton: {
    backgroundColor: colors.primary,
    color: "#fff !important",
    padding: "9px 16px",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "22px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: colors.primary,
      color: "#fff !important",
      opacity: 0.7,
    },
    "&.Mui-disabled": {
      background: colors.primary_disabled,
    }
  },
  secondaryButton: {
    textTransform: "none",
    color: colors.primary,
    fontWeight: 600,
  },
  pageTitle: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "40px",
    color: "#2E3646",
  },
  Permission:{
    mt: "1",
    display: "flex",
    flexDirection: "row",
    height: '40px',
    cursor: 'pointer',
    border: '1px solid #d3cfcf',
    borderRadius: '4px',
    "&:hover": {
      // backgroundColor: "#2E3646",
      // color: "#fff !important",
      // opacity: 0.7,   #c8c8c8 #b5a9a9 #cbc4c4 #dedbdb
      border:"1px solid #cbc4c4",
    }
    }, 
    primaryAddButton: {
      backgroundColor: "#1565C0",
      color: "#fff !important",
      padding: "9px 16px",
      borderRadius: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "15px",
      fontWeight: 600,
      lineHeight: "22px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#0D47A1",
        color: "#fff !important",
        opacity: 0.7,
      },
      "&.Mui-disabled": {
        background: "#2E364688",
      }
    }, 
  
};

export default muiStyles;
