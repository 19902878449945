import {
  setPage,
  setRowsPerPage,
  setUsers,
} from "../../features/Users/allUsersSlice";
import { setRole } from "../../features/role/roleSlice";
import useApi from "../../hooks/useAPI";
import muiStyles from "../../styles/MuiComponentStyles";
import { formatDateTime } from "../../utils/helperFunctions";
import { CosmeticaModal, CosmeticaTable, Loading } from "../UIComponents";
import { useCssStateHook } from "../UIComponents/ComonCss";
import { East } from "@mui/icons-material";
import {
  Box,
  Stack,
  Typography,
  Button,
  createTheme,
  ThemeProvider,
  FormGroup,
  FormControl,
  TextField,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { recommendedRoles } from "../../utils/constants";

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "40px",
          color: "#2E3646",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#fff",
          backgroundColor: "#000",
          padding: "9px 16px",
          borderRadius: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "15px",
          fontWeight: 500,
          lineHeight: "22px",
          textTransform: "none",
          "&:hover": {
            color: "#000",
            backgroundColor: "#fff",
          },
        },
      },
    },
  },
});

const permission = [
  {
    value: 1,
    label: "Full Access",
  },
  {
    value: 2,
    label: "Read Only",
  },
  {
    value: 3,
    label: "None",
  },
];

const Users = () => {
  const { themeCssButton, themeCssHeader } = useCssStateHook();
  const { loading, getData, postData, patchData, deleteData } = useApi();
  const UsersData = useSelector((state) => state.allusers.items);
  const RoleData = useSelector((state) => state.role.items);
  const userInfo = useSelector((state) => state.user.user);
  const totalUsers = useSelector((state) => state.allusers.totalItems);
  const rowsPerPage = useSelector((state) => state.allusers.rowsPerPage);
  const page = useSelector((state) => state.allusers.page);

  const rowsPerPagerole = useSelector((state) => state.role.rowsPerPage);
  const pagerole = useSelector((state) => state.role.page);
  const dispatch = useDispatch();
  const UserURL = `${process.env.REACT_APP_BASE_URL}/web/v2/user`;
  const initialState = {
    email: "",
    first_name: "",
    is_active: true,
    is_admin: true,
    is_super_admin: true,
    is_tablet_user: true,
    is_web_user: true,
    last_name: "",
    permission_id: "",
    // tenant_id: 0,
    // zone_id: 0,
    role_id: "",
  };
  const [editUserData, setEditUserData] = useState(initialState);
  const [showModal, setShowModal] = useState(false);
  const roleURL = `${process.env.REACT_APP_BASE_URL}/web/v2/role`;
  const callAPI = async () => {
    if (userInfo?.tenant_id !== undefined && userInfo?.zone_id !== undefined) {
      try {
        getData(
          `${UserURL}/all/${userInfo?.tenant_id}/${userInfo?.zone_id}/page/${page}/count/${rowsPerPage}`
        ).then((data) => {
          const userInfo = data || [];
          dispatch(setUsers(userInfo));
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const roleApi = async () => {
    if (userInfo?.tenant_id !== undefined) {
      getData(
        `${roleURL}/all/${userInfo.tenant_id}/page/${pagerole}/count/${rowsPerPagerole}`
      ).then((data) => {
        const rolesInfo = data || [];
        dispatch(setRole(rolesInfo));
      });
    }
  };

  useEffect(() => {
    callAPI();
    roleApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.tenant_id, userInfo?.zone_id, rowsPerPage, page]);

  const handleEdit = (id) => {
    const _User = UsersData.find((d) => d.id === id);
    setEditUserData({
      id,
      first_name: _User.first_name,
      last_name: _User.last_name,
      email: _User.email,
      role_id: _User.role_id,
      permission_id: _User.permission_id,
    });
    setShowModal(true);
  };
  const handleSubmit = async (e, users) => {
    e.preventDefault();
    e.target.reset();
    setEditUserData(initialState);
    setShowModal(false);
    if (users?.id) {
      const UpdateData = {
        ...users,
        is_active: true,
        is_tablet_user: true,
        is_web_user: true,
      };
      patchData(`${UserURL}/update`, UpdateData)
        .then((res) => {
          callAPI();
        })
        .catch((err) => {
          console.log("Error ", err);
        });
    } else {
      const CreateData = {
        ...users,
        tenant_id: userInfo?.tenant_id,
        zone_id: userInfo?.zone_id,
      };

      postData(`${UserURL}/create`, CreateData)
        .then((res) => {
          callAPI();
        })
        .catch((err) => {
          console.log("Error ", err);
        });
    }
  };
  const handleCancel = () => {
    setShowModal(false);
    setEditUserData(initialState);
    // setIsEditId("")
  };
  const handleDelete = async (id) => {
    deleteData(`${UserURL}/delete/${id}`)
      .then((res) => {
        callAPI();
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  };
  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage));
  };
  const handleRowsPerPageChange = (newRowsPerPage) => {
    dispatch(setRowsPerPage(newRowsPerPage));
    dispatch(setPage(1));
  };

  const fields = ["Name", "Email Address", "Role", "Created At"];
  const getRows = (data) =>
    data.map((row) => {
      const { id, first_name, last_name, email, role, created_at } = row;
      return {
        id,
        name: `${first_name} ${last_name}`,
        email,
        role: recommendedRoles.includes(role?.toLowerCase()) ?  <Typography sx={{height: '20px', padding: '3px 10px', backgroundColor: '#E3ECFA', borderRadius: '5px', boxSizing: 'content-box', display: 'inline'}}>{role}</Typography> : role,
        created_at: formatDateTime(created_at),
      };
    });
  return loading ? (
    <Loading />
  ) : (
    <Box>
      <ThemeProvider theme={theme}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={5}
        >
          <Typography>Users</Typography>
          <Button
            variant="contained"
            onClick={() => {
              setShowModal(true);
            }}
            sx={themeCssButton}
          >
            Add User
          </Button>
        </Stack>
      </ThemeProvider>
      <Box sx={{ mt: 4 }}>
        <>
          <CosmeticaTable
            fields={fields}
            rows={getRows(UsersData)}
            isDelete
            isEdit
            isHeadercss={themeCssHeader}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            rowsCount={totalUsers}
            page={page}
            rowsPerPage={rowsPerPage}
            isSearch
          />
        </>
      </Box>
      {showModal && (
        <CosmeticaModal
          showModal={showModal}
          title={editUserData?.id ? "Edit User" : "Add User"}
          closeModal={handleCancel}
        >
          <UserForm
            handleClose={() => {
              setShowModal(false);
              setEditUserData(initialState);
            }}
            handleSubmit={handleSubmit}
            userData={editUserData}
            handleCancel={handleCancel}
            roledata={RoleData}
            isHeadercss={themeCssButton}
          />
        </CosmeticaModal>
      )}
    </Box>
  );
};



const UserForm = ({
  handleSubmit,
  userData,
  handleCancel,
  roledata,
  isHeadercss,
}) => {
  const [users, setUsers] = useState(userData);
  const validata = {
    first_name: false,
    last_name: false,
    email: false,
    permission_id: false,
    role_id: false,
  };
  const [isNumberEntered, setIsNumberEntered] = useState(validata);

  const handleChange = (e) => {
    const validName = /^[A-Za-z\s]+$/.test(e.target.value);
    // const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value);
    // const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(e.target.value);
    if (e.target.name === "email") {
      const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value);
      setIsNumberEntered((prevdata) => {
        return { ...prevdata, [e.target.name]: !validEmail };
      });
    } else {
      setIsNumberEntered((prevdata) => {
        return { ...prevdata, [e.target.name]: !validName };
      });
    }

    setUsers((prevUser) => {
      return { ...prevUser, [e.target.name]: e.target.value };
    });
  };
  const buttonStyle = {
    backgroundColor: "#2E3646",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2E3646",
      color: "#fff",
      opacity: 0.7,
    },
  };
  const handleerror = (e) => {
    e.preventDefault();
    if (
      !isNumberEntered.first_name &&
      !isNumberEntered.last_name &&
      !isNumberEntered.email
    ) {
      handleSubmit(e, users);
    }
  };
  return (
    <>
      <form style={{ marginTop: "20px" }} onSubmit={(e) => handleerror(e)}>
        <FormGroup>
          <FormControl sx={{ mt: 2 }}>
            <TextField
              label="First Name"
              name="first_name"
              value={users.first_name}
              variant="outlined"
              onChange={handleChange}
              required
              error={isNumberEntered.first_name}
            />
            {isNumberEntered.first_name && (
              <p style={{ color: "red" }}>
                Please enter a valid name without numbers.
              </p>
            )}
          </FormControl>
          <FormControl sx={{ mt: 2 }}>
            <TextField
              label="Last Name"
              name="last_name"
              value={users.last_name}
              variant="outlined"
              onChange={handleChange}
              required
              error={isNumberEntered.last_name}
            />
            {isNumberEntered.last_name && (
              <p style={{ color: "red" }}>
                Please enter a valid name without numbers.
              </p>
            )}
          </FormControl>
          <FormControl sx={{ mt: 2 }}>
            <TextField
              label="Email Address"
              name="email"
              type={"email"}
              value={users.email}
              variant="outlined"
              onChange={handleChange}
              required
              error={isNumberEntered.email}
            />
            {isNumberEntered.email && (
              <p style={{ color: "red" }}>Please enter a valid email.</p>
            )}
          </FormControl>
          <FormControl sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-helper-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={users.role_id}
              label="Role"
              name="role_id"
              onChange={handleChange}
              required
            >
              {roledata.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-helper-label">
              Permission
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={users.permission_id}
              label="Permission"
              name="permission_id"
              onChange={handleChange}
              required
            >
              {permission.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          mt={2}
        >
          <Button
            type="reset"
            variant="text"
            size="large"
            onClick={() => handleCancel()}
            sx={muiStyles.secondaryButton}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="large"
            // sx={ isHeadercss && ({backgroundColor:isHeadercss.backgroundColor})}
            sx={isHeadercss || buttonStyle}
            endIcon={<East />}
          >
            {users.id ? "Update" : "Add"}
          </Button>
        </Stack>
      </form>
    </>
  );
};
export default Users;
