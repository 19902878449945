import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Typography, Button, createTheme, ThemeProvider, FormGroup, FormControl, TextField } from "@mui/material";
import {ChromePicker}  from "react-color";
import useApi from "../../hooks/useAPI";
import { CosmeticaModal, CosmeticaTable, Loading } from "../UIComponents";
import muiStyles from "../../styles/MuiComponentStyles";
import { setSkill } from "../../features/skill/skillSlice";
import { East } from "@mui/icons-material";
import { formatDateTime } from "../../utils/helperFunctions";
import { useCssStateHook } from "../UIComponents/ComonCss";

const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '40px',
                    color: '#2E3646'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: '#000',
                    padding: '9px 16px',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '22px',
                    textTransform: 'none',
                    '&:hover': {
                        color: '#000',
                        backgroundColor: '#fff'
                    }
                }
            }
        }
    }
});

const Skill = () => {
    const skillData = useSelector((state) => state.skill.items);
    const userInfo = useSelector((state) => state.user.user);
    const { loading, getData, postData, patchData, deleteData } = useApi();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const { themeCssButton, themeCssHeader } = useCssStateHook();
    const initialState = {
        skill: '',
        tenant_id: userInfo?.tenant_id,
        color_code: '#FFFFFF'
    };
    const [editSkillData, setEditSkillData] = useState(initialState);
    const skillURL = `${process.env.REACT_APP_BASE_URL}/web/v2/worker/skill`;

    const callAPI = async () => {
        if (userInfo.tenant_id != null) {
            try {
                getData(`${skillURL}/all/${userInfo.tenant_id}`).then(data => {
                    const skillsInfo = data || [];
                    dispatch(setSkill(skillsInfo));
                })
            } catch (error) {
                console.log(error);
            }
        }
    };
    React.useEffect(() => {
        callAPI();
        // eslint-disable-next-line
    }, [userInfo]);

    const fields = ['Skills', "Color", 'Created at'];
    const getRows = (data) => data.map(row => {
        const { id, skills, color_code, created_at } = row;
        return { id, skills, colorCodeStr: color_code, created_at: formatDateTime(created_at) };
    });

    const handleSubmit = async (e, skills) => {
        e.preventDefault();
        e.target.reset();
        setEditSkillData(initialState);
        setShowModal(false);
        if (skills?.id) {
            patchData(`${skillURL}/update`, skills).then((res) => {
                callAPI();
            }).catch((err) => {
                console.log("Error ", err);
            });
        } else {
            postData(`${skillURL}/create`, skills).then((res) => {
                callAPI();
            }).catch((err) => {
                console.log("Error ", err);
            });
        }
    };

    const handleEdit = (id) => {
        const _Skill = skillData.find(d => d.id === id);
        setEditSkillData({ id, skills: _Skill.skills, color_code: _Skill.color_code });
        setShowModal(true);
    };

    const handleDelete = async (id) => {
        deleteData(`${skillURL}/delete/${id}`).then((res) => {
            callAPI();
        }).catch((err) => {
            console.log("Error ", err);
        });
    };

    const handleCancel = () => {
        setShowModal(false);
        setEditSkillData(initialState);
    };

    const handlePageChange = () => { };
    const handleRowsPerPageChange = () => { };

    return loading ? (
        <Loading />
    ) : (
        <Box>
            <ThemeProvider theme={theme}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mt={5}>
                    <Typography>Skill</Typography>
                    <Button variant="contained" onClick={() => { setShowModal(true) }} sx={themeCssButton}>Add Skill</Button>
                </Stack>
            </ThemeProvider>
            <Box sx={{ mt: 4 }}>
                {console.log("data users skillData: ", skillData)}
                <CosmeticaTable
                    fields={fields}
                    rows={getRows(skillData)}
                    isDelete
                    isEdit
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsCount={""}
                    page={1}
                    rowsPerPage={10}
                    isHeadercss={themeCssHeader}
                />
            </Box>
            {showModal &&
                <CosmeticaModal
                    showModal={showModal}
                    title={editSkillData?.id ? "Edit Skill" : "Add Skill"}
                    closeModal={handleCancel}
                >
                    <SkillForm
                        handleClose={() => { setShowModal(false); setEditSkillData(initialState) }}
                        handleSubmit={handleSubmit}
                        skillData={editSkillData}
                        handleCancel={handleCancel}
                        isHeadercss={themeCssButton}
                    />
                </CosmeticaModal>
            }
        </Box>
    );
};

const SkillForm = ({ handleSubmit, skillData, handleCancel, isHeadercss }) => {
    const [Skill, setSkill] = React.useState(skillData);
    const [color, setColor] = useState(skillData.color_code);

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            setSkill((prevSkill) => ({ ...prevSkill, [name]: value }));
        }
    };

    const handleColorChange = (color) => {
        setColor(color.hex);
        setSkill((prevSkill) => ({ ...prevSkill, color_code: color.hex }));
    };

    const buttonStyle = {
        backgroundColor: '#2E3646',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#2E3646',
            color: '#fff',
            opacity: 0.7,
        }
    };

    return <>
        <form style={{ marginTop: "20px" }} onSubmit={(e) => handleSubmit(e, Skill)}>
            <FormGroup>
                <FormControl sx={{ mt: 2 }}>
                    <TextField
                        label="Skill"
                        name="skills"
                        type="text"
                        value={Skill.skills}
                        variant="outlined"
                        onChange={handleChange}
                        required={true}
                        inputProps={{ style: { color: 'black' } }}
                    />
                </FormControl>
                <FormControl sx={{ mt: 2 }}>
                    <Typography component="legend">Color Code</Typography>
                    <ChromePicker 
                        style={{
                            width: '600px'
                        }}
                        color={color} 
                        onChangeComplete={handleColorChange}
                    />
                </FormControl>
            </FormGroup>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
                <Button
                    type="reset"
                    variant="text"
                    size="large"
                    onClick={() => handleCancel()}
                    sx={muiStyles.secondaryButton}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={isHeadercss || buttonStyle}
                    endIcon={<East />}
                >
                    {Skill.id ? "Update" : "Submit"}
                </Button>
            </Stack>
        </form>
    </>
};

export default Skill;


