import axios from "axios";

export async function fetchFileUrl(file_name) {
    const url = `${process.env.REACT_APP_BASE_URL}/web/v2/media/view`
    try {
        const response = await axios.post(url, {file_name});
        return response.data;
    } catch (error) {
        console.log(error);
        return ''
    }
}

export async function updateStepPosition(data) {
    const url = `${process.env.REACT_APP_BASE_URL}/web/v2/production/step/postion/update`
    try {
        const response = await axios.patch(url, {step_positions: data});
        return response.data;
    } catch (error) {
        console.log(error);
        return ''
    }
}