import React, { } from "react"
import { Grid, FormControl, FormLabel, TextField } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { updateStepInput } from "../../../../features/ProductionResourceSteps/stepsSliceQuality";

const StepDetailsFromQuality = () => {
  const dispatch = useDispatch()
  const currentStep = useSelector(state => state?.stepsquality?.currentStep)
  const isStepEditable = useSelector(state => state?.stepsquality?.isStepEditable)
  const handleInputChange = (e) => {
    dispatch(updateStepInput({key: e?.target?.name, value: e?.target?.value}));
  };

    return <>
      <Grid container rowSpacing={6} columnSpacing={4}>
      <Grid xs={6} >
        <FormControl fullWidth>
          <FormLabel>Title</FormLabel>
          <TextField
            name="name"
            value={currentStep?.name}
            onChange={handleInputChange}
            disabled={!isStepEditable}
            required
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel sx={{marginTop:2}}>Description</FormLabel>
          <TextField
           id="filled-multiline-static"
            name="desc"
            value={currentStep?.desc}
            onChange={handleInputChange}
            disabled={!isStepEditable}
            required
            rows={4}
            sx={{height:"160px"}}
            multiline
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
      </Grid>
      
      </Grid>
    </>
}

export default StepDetailsFromQuality