import React, { useState } from "react";

import { Box,  Button,  FormControl,  FormGroup,  Stack,  TextField,  ThemeProvider,  Typography,  createTheme, } from "@mui/material"
import { CosmeticaModal, CosmeticaTable, Loading } from "../UIComponents";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../hooks/useAPI";
import { setProductStages } from "../../features/productstages/productstagesSlice";
import { formatDateTime } from "../../utils/helperFunctions";
import muiStyles from "../../styles/MuiComponentStyles";
import { East } from "@mui/icons-material";
import { useCssStateHook } from "../UIComponents/ComonCss";



const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '40px',
                    color: '#2E3646'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: '#000',
                    padding: '9px 16px',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '22px',
                    textTransform: 'none',
                    '&:hover': {
                        color: '#000',
                        backgroundColor: '#fff'
                    }
                }
            }
        }
    }
})

const ProductStages = () => {
    const productData = useSelector((state) => state.productstages.items);
    const userInfo = useSelector((state) => state.user.user);
    // const totalLines = useSelector((state) => state.line.totalItems)
    // const rowsPerPage = useSelector(state => state.line.rowsPerPage);
    // const page = useSelector(state => state.line.page)
    const {  themeCssButton,themeCssHeader} = useCssStateHook();
    const {loading, getData, postData, patchData, deleteData } = useApi()
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false)
    // let navigate = useNavigate();
    const initialState = {
        name: '',
        tenant_id: userInfo?.tenant_id,
        zone_id: userInfo?.zone_id,
    }
    const [editProStagesData, setEditProStagesData] = useState(initialState)
    const ProductStagesURL = `${process.env.REACT_APP_BASE_URL}/web/v2/product/stages` 
    const callAPI = async () => {
        if (userInfo.zone_id != null) {
            try {
                getData(`${ProductStagesURL}/all/${userInfo.tenant_id}/${userInfo.zone_id}`).then(data => {
                    const ProductStagesInfo = data || [];
                    dispatch(setProductStages(ProductStagesInfo))
                })
            } catch (error) {
                console.log(error);
            }
        }
    }
    React.useEffect(() => {
        callAPI()
        // eslint-disable-next-line
    }, [userInfo]);

    const fields = ['Name','Created at']
    const getRows = (data) => data.map(row => {
        const { id, name,created_at } = row
        return { id, name,created_at:formatDateTime(created_at) }
    })
    const handleSubmit = async (e, product) => {
        e.preventDefault()
        e.target.reset()
        setEditProStagesData(initialState)
        setShowModal(false)
        if (product?.id) {
            patchData(`${ProductStagesURL}/update`, product).then((res) => {
                callAPI()
            }).catch((err) => {
                console.log("Error ", err)
            })

        } else {
            postData(`${ProductStagesURL}/create`, product).then((res) => {
                callAPI()
            }).catch((err) => {
                console.log("Error ", err)
            })
        
        }
    }
    const handleEdit = (id) => {
        const _products = productData.find(d => d.id === id)
        setEditProStagesData({ id, name: _products.name })
        setShowModal(true)
    }
    const handleDelete = async (id) => {
        deleteData(`${ProductStagesURL}/delete/${id}`).then((res) => {
            callAPI()
        }).catch((err) => {
            console.log("Error ", err)
        })
      
    }
    const handleCancel = () => {
        setShowModal(false);
        setEditProStagesData(initialState);
        // setIsEditId("")
    }
    // const handleOnclickRow = (id) => {
    //     navigate(`/admin/line/${id}`)
    // }
    const handlePageChange = () => {
      
      }
      const handleRowsPerPageChange = () => {
       
      }
    return loading ? (
        <Loading/>
    ) : (
        <Box>
            <ThemeProvider theme={theme}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mt={5}>
                    <Typography>Product Stages</Typography>
                    <Button variant="contained" onClick={() => { setShowModal(true) }}  sx={themeCssButton}>Add Product Stages</Button>
                </Stack>
            </ThemeProvider>
            <Box sx={{ mt: 4 }}>
                    <>
                        <CosmeticaTable
                            fields={fields}
                            rows={getRows(productData)}
                            isDelete
                            isEdit
                            // isRowClick={true}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handlePageChange={handlePageChange}
                            handleRowsPerPageChange={handleRowsPerPageChange}
                            rowsCount={""}
                            page={1}
                            rowsPerPage={10}
                            isHeadercss={themeCssHeader}
                        />
                    </>
            </Box>
            {showModal &&
                <CosmeticaModal
                    showModal={showModal}
                    title={editProStagesData?.id ? "Edit Product Stages " : "Add Product Stages"}
                    closeModal={handleCancel}
                >
                    <ProductStagesForm
                        handleClose={() => { setShowModal(false); setEditProStagesData(initialState) }}
                        handleSubmit={handleSubmit}
                        ProductData={editProStagesData}
                        handleCancel={handleCancel}
                        isHeadercss={themeCssButton}
                    />
                </CosmeticaModal>
            }
           
        </Box>
    );
};
const ProductStagesForm = ({ handleSubmit, ProductData, handleCancel,isHeadercss }) => {
    const [product, setProduct] = useState(ProductData)
    const validata ={
        name:false,
        desc:false,
        short_desc:false
        
    }
    const [isNumberEntered, setIsNumberEntered] = useState(validata);
    const handleChange = (e) => {
        const validName = /^[A-Za-z\s]+$/.test(e.target.value);
       
        
       if(!validName){
        setIsNumberEntered((prevdata) => { return { ...prevdata, [e.target.name]: !validName} });
       }else{
        setIsNumberEntered((prevdata) => { return { ...prevdata, [e.target.name]: !validName} });
       }

  
    

        
       setProduct((prevZone) => { return { ...prevZone, [e.target.name]: e.target.value } })
    }
    const buttonStyle = {
        backgroundColor: '#2E3646',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#2E3646',
            color: '#fff',
            opacity: 0.7,
        }
    }
    const  handleerror=(e)=>{
        e.preventDefault()
        if(!isNumberEntered.name ){
            handleSubmit(e, product)
        }
    }
    return <>
        <form style={{ marginTop: "20px" }} onSubmit={(e) => handleerror(e)}>
            <FormGroup>
                <FormControl sx={{ mt: 2 }}>
                    <TextField
                        label="Name"
                        name="name"
                        value={product.name}
                        variant="outlined"
                        onChange={handleChange} 
                        required
                        error={isNumberEntered.name}/>
                    {isNumberEntered.name && (
                        <p style={{ color: 'red' }}>Please enter a valid name without numbers.</p>
                    )}
                </FormControl>
            </FormGroup>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
                <Button
                    type="reset"
                    variant="text"
                    size="large"
                    onClick={() => handleCancel()}
                    sx={muiStyles.secondaryButton}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={ isHeadercss || buttonStyle}
                    endIcon={<East />}
                >
                    {product.id ? "Update" : "Add"}
                </Button>
            </Stack>
        </form>
    </>
}
export default ProductStages;
