import {
    Box,
    Typography,
    Stack
  } from "@mui/material";
  import React, { useEffect } from "react";
  import useApi from "../../hooks/useAPI";
  import { useSelector, useDispatch } from "react-redux";
  import { Loading, CosmeticaTable } from "../UIComponents";
  import muiStyles from "../../styles/MuiComponentStyles";
  import { formatDate } from '../../utils/helperFunctions';
  import { setReports, setPage, setRowsPerPage } from "../../features/reports/reportSlice";
  import { useCssStateHook } from "../UIComponents/ComonCss";
  import { NavLink } from "react-router-dom";
  
  const Reports = () => {
    const dispatch = useDispatch()
    const {getData, loading} = useApi()
    const user = useSelector(state => state.user.user)
    const reports = useSelector(state => state.reports.items)
    const totalReports = useSelector(state => state.reports.totalItems)
    const rowsPerPage = useSelector(state => state.reports.rowsPerPage);
    const page = useSelector(state => state.reports.page)
    const { themeCssHeader} = useCssStateHook();

    const fields = ["Summary ID", "Product Code", "Work Order", "Production Resource", "Shift", "Start Time", "Line", "Schamatic Type", "Reports"];
    const rows = reports.map((row) => {
      const { work_order_summary_id, product_code, work_order, production_resource_name, shift_name, start_time, line_name, line_variation } = row;
      return { work_order_summary_id: <p style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}}>{work_order_summary_id}</p>, product_code, work_order, production_resource_name, shift_name, start_time: formatDate(start_time), line_name, line_variation, reports: <Stack>
        <NavLink style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}} to={`/admin/reports/summary/${work_order_summary_id}`} state={{product: row}}>Huddle Sheet</NavLink>
        <NavLink style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}} to={`/admin/reports/quality/${work_order_summary_id}`} state={{product: row}}>Quality</NavLink>
      </Stack> };
    });
  
    useEffect(() => {
      if(user.tenant_id) {
        fetchWorkOrdersSummary()
      }
      // eslint-disable-next-line
    }, [user.tenant_id])
  
    const fetchWorkOrdersSummary = () => {
      try {
        const url = `${process.env.REACT_APP_BASE_URL}/web/v2/work/order`;
        getData(`${url}/summaries/${user.tenant_id}/${user.zone_id}/page/${1}/count/${9999}`).then(data => {
          // console.log('Summaries', data)
          dispatch(setReports(data || []))
        })
      } catch (error) {
          console.log(error);
      }
    }

    const handlePageChange = (newPage) => {
      dispatch(setPage(newPage))
    }
  
    const handleRowsPerPageChange = (newRowsPerPage) => {
      dispatch(setRowsPerPage(newRowsPerPage))
    }

  
    return loading ? (
      <Loading />
    ) : (
      <Box>
        <Typography sx={muiStyles.pageTitle} mt={3}>Summary Reports</Typography>
        <Box sx={{ mt: 4 }}>
          <CosmeticaTable
            fields={fields}
            rows={rows}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            rowsCount={totalReports}
            page={page}
            rowsPerPage={rowsPerPage}
            isHeadercss={themeCssHeader}
          />
        </Box>
      </Box>
    );
  };
  
  export default Reports;
  