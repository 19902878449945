import { useState } from "react";




export const useCssStateHook = () => {

    const ThemeStyle = {
        // backgroundColor: "#1E88E5",
        backgroundColor: "#0D47A1",
        "&:hover": {
            backgroundColor: "#0D47A1",
            color: "#fff !important",
            opacity: 0.7,
          },  
    }
    const ThemeStyleHeader = {
        // backgroundColor: "#1E88E5",
        backgroundColor: "#0D47A1",
    }
    const ThemeStylePermission = {
        backgroundColorread: "#4FC3F7",
        backgroundColorfull: "#81C784",
        backgroundColornone: "#E57373",
    }
    const [themeCssButton, setThemeCssButton]=useState(ThemeStyle);
    const [themeCssHeader, setThemeCssHeader]=useState(ThemeStyleHeader);
    const [themeCssPermission, setThemeCssPermission]=useState(ThemeStylePermission);
    // useEffect (()=>{
    //     setThemeCssButton({
    //             backgroundColor: "red",
    //             "&:hover": {
    //                 backgroundColor: "green",
    //                 color: "#fff !important",
    //                 opacity: 0.7,
    //               }, 
    //             }
    //         )
    //         setThemeCssHeader({
    //             backgroundColor: "red",
    //         })

    // },[])
    return {
        themeCssButton,
        setThemeCssButton,
        themeCssHeader,
        setThemeCssHeader,
        themeCssPermission,
        setThemeCssPermission,
    };
}