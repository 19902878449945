import { createSlice } from "@reduxjs/toolkit";
import { updateStepPosition } from "./StepsAPI";

const empltyFile = {
  name: '',
  tags: '',
  type: '',
  url: '',
  file: null
}

export const slice = createSlice({
  name: "stepSlice",
  initialState: {
    items: [],
    currentStepIndex: null,
    currentStep: null,
    enableNextStep: true,
    isEdit: false,
    isStepPositionChanged: false,
    isStepEditable: false
  },
  reducers: {
    setSteps: (state, action) => {
      state.items = JSON.parse(JSON.stringify(action.payload))
    },
    addStep: (state, action) => {
        const totalSteps = state.items.length;
        const emptyStep = {
            name: "",
            desc: "",
            min_crew: "",
            max_crew: "",
            position: totalSteps,
            production_resource_id: action.payload,
            isStepSubmitted: false,
            media: {
              video: {
                name: '',
                tags: '',
                url: '',
                type: '',
                file: null
              },
              images: [
                {
                  name: '',
                  tags: '',
                  url: '',
                  type: '',
                  file: null,
                  child:''
                }
              ],
              good: [
                {
                  name: '',
                  tags: '',
                  url: '',
                  type: '',
                  file: null,
                  child:''
                }
              ],
              bad: [
                {
                  name: '',
                  tags: '',
                  url: '',
                  type: '',
                  file: null,
                  child:''
                }
              ],
              reference: [
                {
                  name: '',
                  tags: '',
                  url: '',
                  type: '',
                  file: null,
                  child:''
                }
              ]
            },
            questions: []
        };
        if (state.isEdit) {
          emptyStep.isNewStep = true
        } else {
          emptyStep.isNewStep = false
        }
        state.items = [...state.items, emptyStep]
        state.currentStepIndex = totalSteps
        state.isStepEditable = true
        state.enableNextStep = false
        state.currentStep = state.items[totalSteps] // to be checked
    },
    clearStep: (state, action) => {
      state.items[state.currentStepIndex].media.images = [{...empltyFile}]
      state.items[state.currentStepIndex].media.video = {...empltyFile}
      state.items[state.currentStepIndex].name = ''
      state.items[state.currentStepIndex].desc = ''
      state.items[state.currentStepIndex].min_crew = ''
      state.items[state.currentStepIndex].max_crew = ''
      state.currentStep = state.items[state.currentStepIndex]
    },
    setCurrentStepIndex: (state, action) => {
        state.currentStepIndex = action.payload;
        if (action.payload !== null || action.payload !== undefined){
          state.currentStep = state.items[action.payload]
        }
    },
    setCurrentStep: (state, action) => {
        state.currentStep = state.currentStepIndex !== null ? state.items[state.currentStepIndex] : null;
    },
    setEnableStepSubmitted: (state, action) => {
        state.enableNextStep = action.payload;
    },
    setCurrentStepSubmitted: (state, action) => {
      state.items[state.currentStepIndex]['isStepSubmitted'] = action.payload
      state.currentStep = state.items[state.currentStepIndex]
    },
    updateImageUrl: (state, action) =>  {
      const { stepIndex, index, url } = action.payload;
      state.items[stepIndex].media.images[index].url = url;
      state.currentStep = state.items[state.currentStepIndex]
    },
    updateImageUrlgood: (state, action) =>  {
      const { stepIndex, index, url } = action.payload;
      state.items[stepIndex].media.good[index].url = url;
      state.currentStep = state.items[state.currentStepIndex]
    },
    updateImageUrlbad: (state, action) =>  {
      const { stepIndex, index, url } = action.payload;
      state.items[stepIndex].media.bad[index].url = url;
      state.currentStep = state.items[state.currentStepIndex]
    },
    updateImageUrlreference: (state, action) =>  {
      const { stepIndex, index, url } = action.payload;
      state.items[stepIndex].media.reference[index].url = url;
      state.currentStep = state.items[state.currentStepIndex]
    },
    updateVideoUrl: (state, action) =>  {
      const { stepIndex, url } = action.payload;
      state.items[stepIndex].media.video.url = url;
      state.currentStep = state.items[state.currentStepIndex]
    },
    updateStepInput: (state, action) => {
      const {key, value} = action.payload
      // state.items[state.currentStepIndex][key] = value
      // state.currentStep = state.items[state.currentStepIndex]
      state.currentStep[key] = value
    },
    updateImageInput: (state, action) => {
      const {key, value, index} = action.payload
      // state.items[state.currentStepIndex].media.images[index][key] = value
      // state.currentStep = state.items[state.currentStepIndex]
      state.currentStep.media.images[index][key] = value
    },
    updateImageInputstep: (state, action) => {
      const {key, value, index,ChildName} = action.payload
      // state.items[state.currentStepIndex].media.images[index][key] = value
      // state.currentStep = state.items[state.currentStepIndex]
      

      if(ChildName === "production:step:good"){
        state.currentStep.media.good[index][key] = value

      }else if(ChildName === "production:step:bad"){
        state.currentStep.media.bad[index][key] = value
      }else if(ChildName === "production:step:reference"){
        state.currentStep.media.reference[index][key] = value
      }else{
        state.currentStep.media.images[index][key] = value
      }
    },
    updateVideoInput: (state, action) => {
      const {key, value} = action.payload
      // state.items[state.currentStepIndex].media.video[key] = value
      // state.currentStep = state.items[state.currentStepIndex]
      state.currentStep.media.video[key] = value
    },
    updateImage: (state, action) => {
      const {file, type, url, index,child} = action.payload
      // state.items[state.currentStepIndex].media.images[index].url = url
      // state.items[state.currentStepIndex].media.images[index].type = type
      // state.items[state.currentStepIndex].media.images[index].file = file
      // state.currentStep = state.items[state.currentStepIndex]
      if(child === "production:step:good"){
        state.currentStep.media.good[index].url = url
        state.currentStep.media.good[index].type = type
        state.currentStep.media.good[index].file = file
        state.currentStep.media.good[index].child = child

      }else if(child === "production:step:bad"){
        state.currentStep.media.bad[index].url = url
        state.currentStep.media.bad[index].type = type
        state.currentStep.media.bad[index].file = file
        state.currentStep.media.bad[index].child = child

      }else if(child === "production:step:reference"){
        state.currentStep.media.reference[index].url = url
        state.currentStep.media.reference[index].type = type
        state.currentStep.media.reference[index].file = file
        state.currentStep.media.reference[index].child = child
      }else{

        state.currentStep.media.images[index].url = url
        state.currentStep.media.images[index].type = type
        state.currentStep.media.images[index].file = file
        state.currentStep.media.images[index].child = child
      }
    },
    updateVideo: (state, action) => {
      const {file, type, url} = action.payload
      // state.items[state.currentStepIndex].media.video.file = file
      // state.items[state.currentStepIndex].media.video.type = type
      // state.items[state.currentStepIndex].media.video.url = url
      // state.currentStep = state.items[state.currentStepIndex]
      state.currentStep.media.video.file = file
      state.currentStep.media.video.type = type
      state.currentStep.media.video.url = url
    },
    clearImage: (state, action) => {
      const {ChildName} = action.payload
      if(ChildName === "production:step:good"){
        const newImages = [...state.currentStep.media.good]
        newImages.pop()
        if (newImages.length < 1) {
          newImages.push({...empltyFile})
        }
        state.currentStep.media.good = newImages

      }else if(ChildName === "production:step:bad"){
        const newImages = [...state.currentStep.media.bad]
        newImages.pop()
        if (newImages.length < 1) {
          newImages.push({...empltyFile})
        } 
        state.currentStep.media.bad = newImages

      }else if(ChildName === "production:step:reference"){
        const newImages = [...state.currentStep.media.reference]
        newImages.pop()
        if (newImages.length < 1) {
          newImages.push({...empltyFile})
        }
        state.currentStep.media.reference = newImages
      }else{
        const newImages = [...state.currentStep.media.images]
        newImages.pop()
        if (newImages.length < 1) {
          newImages.push({...empltyFile})
        }
        state.currentStep.media.images = newImages
      }
    },
    addImage: (state, action) => {
      // const newImages = [...state.currentStep.media.images]
      // newImages.push({...empltyFile})
      // state.currentStep.media.images = newImages

      const {ChildName} = action.payload
      if(ChildName === "production:step:good"){
        const newImages = [...state.currentStep.media.good]
        newImages.push({...empltyFile})
        state.currentStep.media.good = newImages

      }else if(ChildName === "production:step:bad"){
        const newImages = [...state.currentStep.media.bad]
        newImages.push({...empltyFile})
        state.currentStep.media.bad = newImages

      }else if(ChildName === "production:step:reference"){
        const newImages = [...state.currentStep.media.reference]
        newImages.push({...empltyFile})
        state.currentStep.media.reference = newImages
      }else{
        const newImages = [...state.currentStep.media.images]
        newImages.push({...empltyFile})
        state.currentStep.media.images = newImages
      }
    },
    setIsEdit: (state, action) => {
      state.isEdit = action.payload
    },
    deleteVideo: (state, action) => {
      // state.items[state.currentStepIndex].media.video = empltyFile
      // state.currentStep = state.items[state.currentStepIndex]
      state.currentStep.media.video = empltyFile
    },
    deleteImage: (state, action) => {
      const newImages = [...state.currentStep.media.images]
      newImages.splice(action.payload, 1)
      if (newImages.length < 1) {
        newImages.push(empltyFile)
      }
      // state.items[state.currentStepIndex].media.images = newImages
      // state.currentStep = state.items[state.currentStepIndex]
      state.currentStep.media.images = newImages
    },
    changeStepsPosition: (state, action) => {
      let updatedSteps = [...action.payload];
      const stepPositions = []
      updatedSteps = updatedSteps.map((step, index) => {
        step.position = index
        stepPositions.push({id: step.id, position: step.position})
        return step
      })
      if (state.isEdit) {
        updateStepPosition(stepPositions)
        .then((resp) => alert('Positions updated successfully')).catch((err) => alert('Something went wrong, please try again after sometime'))
      }
      state.items = updatedSteps
      state.currentStepIndex = 0
      state.currentStep = state.items[0]
      state.isStepPositionChanged = true
    },
    questionInputChange: (state, action) => {
      const {index, value} = action.payload
      // state.items[state.currentStepIndex].questions[index].question = value
      // state.currentStep = state.items[state.currentStepIndex]
      state.currentStep.questions[index].question = value
    },
    optionInputChange: (state, action) => {
      const {questionIndex, optionIndex, value} = action.payload
      // state.items[state.currentStepIndex].questions[questionIndex].options[optionIndex].option = value
      // state.currentStep = state.items[state.currentStepIndex]
      state.currentStep.questions[questionIndex].options[optionIndex].option = value
    },
    markCorrectOption: (state, action) => {
      const {questionIndex, optionIndex } = action.payload
      // let options = state.items[state.currentStepIndex].questions[questionIndex].options
      let options = state.currentStep.questions[questionIndex].options
      options = options.map((option, index) => {
        if (index === optionIndex) {
          option.is_correct = true
        } else {
          option.is_correct = false
        }
        return option
      })
      state.currentStep.questions[questionIndex].options = options
      // state.items[state.currentStepIndex].questions[questionIndex].options = options
      // state.currentStep = state.items[state.currentStepIndex]
    },
    addQuestion: (state, action) => {
      const emptyQuestion = {
        "has_image": false,
        "options": [
        {
            "has_image": false,
            "is_correct": true,
            "option": "",
            "image": {
              type: '',
              url: '',
              file: null
            } 
        },
        {
            "has_image": false,
            "is_correct": false,
            "option": "",
            "image": {
              type: '',
              url: '',
              file: null
            } 
        },
        ],
        "production_resource_id": null,
        "production_resource_step_id": null,
        "question": "",
        "image": {
          type: '',
          url: '',
          file: null
        }
      }
      const questions = state.currentStep.questions
      questions.push({...emptyQuestion})
      state.currentStep.questions = questions
      // state.items[state.currentStepIndex].questions = questions
      // state.currentStep = state.items[state.currentStepIndex]
    },
    addOption: (state, action) => {
      const questionIndex = action.payload
      const newOption = {
        "has_image": false,
        "is_correct": false,
        "option": "",
        "image": {
          type: '',
          url: '',
          file: null
        } 
      }
      // state.items[state.currentStepIndex].questions[questionIndex].options[optionIndex].option = value
      // state.currentStep = state.items[state.currentStepIndex]
      const options = state.currentStep.questions[questionIndex].options
      options.push(newOption)
      state.currentStep.questions[questionIndex].options = options
    },
    updateQuestion: (state, action) => {
      const {stepIndex, data} = action.payload
      state.items[stepIndex].questions = JSON.parse(JSON.stringify(data))
      state.currentStep = state.items[state.currentStepIndex]
    },
    updateQuestionImageUrl: (state, action) =>  {
      const { stepIndex, qIndex, url } = action.payload;
      state.items[stepIndex].questions[qIndex].image = {url, type: 'image', file: null};
      state.currentStep = state.items[state.currentStepIndex]
    },
    updateOptionImageUrl: (state, action) =>  {
      const { stepIndex, qIndex, oIndex, url } = action.payload;
      state.items[stepIndex].questions[qIndex].options[oIndex].image = {url, type: 'image', file: null};
      state.currentStep = state.items[state.currentStepIndex]
    },
    updateQuestionImage: (state, action) => {
      const {file, type, url, index} = action.payload
      state.currentStep.questions[index].image.url = url
      state.currentStep.questions[index].image.type = type
      state.currentStep.questions[index].image.file = file
    },
    updateOptionImage: (state, action) => {
      const {file, type, url, qIndex, index} = action.payload
      state.currentStep.questions[qIndex].options[index].image.url = url
      state.currentStep.questions[qIndex].options[index].image.type = type
      state.currentStep.questions[qIndex].options[index].image.file = file
    },
    removeQuestion: (state, action) => {
      const index = action.payload
      const questions = state.currentStep.questions
      questions.splice(index, 1)
      state.currentStep.questions = questions
      // state.items[state.currentStepIndex].questions = questions
      // state.currentStep = state.items[state.currentStepIndex]
    },
    setStepEditable: (state, action) => {
      state.isStepEditable = action.payload
    },
    deleteStep: (state, action) => {
      let updatedSteps = [...state.items];
      updatedSteps.splice(state.currentStepIndex, 1);
      const stepPositions = []
      updatedSteps = updatedSteps.map((step, index) => {
        step.position = index
        stepPositions.push({id: step.id, position: step.position})
        return step
      })
      state.items = updatedSteps
      state.currentStepIndex = 0
      state.currentStep = state.items[0]
    },
    cancelStep: (state, action) => {
      state.currentStep = state.items[state.currentStepIndex]
      if (state.currentStep.id) {
        state.currentStep.isStepSubmitted = true
      }
      state.isStepEditable = false
      state.enableNextStep = true
    }
  },
});

export const { setSteps, addStep, setCurrentStepIndex, setCurrentStep, setEnableStepSubmitted, updateImageUrl,updateImageUrlgood,updateImageUrlbad,updateImageUrlreference, updateVideoUrl, updateStepInput, updateImageInput,updateImageInputstep, updateVideoInput, updateImage, updateVideo, clearImage, addImage, clearStep, setCurrentStepSubmitted, setIsEdit, deleteVideo, deleteImage, changeStepsPosition, questionInputChange, optionInputChange, markCorrectOption, addQuestion, updateQuestion, updateQuestionImage, removeQuestion, addOption, updateOptionImage, updateQuestionImageUrl, updateOptionImageUrl, setStepEditable, deleteStep, cancelStep } = slice.actions;

export const getSteps = (state) => {
  return state.stepSlice.items;
};

export default slice.reducer;
