import useApi from "../../../hooks/useAPI";
import muiStyles from "../../../styles/MuiComponentStyles";
import { FormFieldsTheme } from "../../../styles/MuiThemes";
import {
  Box,
  Stack,
  Button,
  Typography,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  FormControl,
  FormLabel,
  ThemeProvider,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { setProducts } from "../../../features/products/productsSlice";

const AddEditProductForm = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user);
  const products = useSelector((state) => state.products.items);
  const { productId } = useParams();
  const location = useLocation();
  const productUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/product`;
  const { postData, patchData, getData } = useApi();
  const stages = useSelector((state) => state.stages.items)
  const [product, setProduct] = React.useState(
    products?.find(p => p.id === productId) || productId
      ? location.state.product
      : {
          product_code: "",
          desc: "",
          specification: "",
          production_stage_id: null,
          specification_revision: "",
        }
  );

  const [isButtonSelected, setIsButtonSelected] = React.useState(
    product.production_stage_id
  );

  const handelSubmit = async (e) => {
    e.preventDefault();
    product.tenant_id = user.tenant_id;
    product.zone_id = user.zone_id;
    if (!isButtonSelected) {
      alert("Please select product stage");
      return;
    }
    try {
      if (productId) {
        await patchData(`${productUrl}/update`, product, "/products");
      } else {
        await postData(`${productUrl}/create`, product, "/products");
      }
    } catch (error) {
      alert(error);
      console.log(error);
    }
  };

  const handleProductStage = (e, newProductStage) => {
    setProduct((prev) => {
      return { ...prev, production_stage_id: newProductStage };
    });
    setIsButtonSelected(newProductStage !== null);
  };

  const handleInputChange = (e) => {
    setProduct((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const fetchProducts = async () => {
      try {
        const response = await getData(
          `${productUrl}/all/${user.tenant_id}/${user.zone_id}`
        );
        dispatch(setProducts(response || []));
      } catch (error) {
        console.log(error);
      }
  };

  useEffect(() => {
    if(user.tenant_id !=null && user.zone_id !== null) {
      fetchProducts()
    }
    // eslint-disable-next-line
  }, [])


  return (
      <Box>
        <form onSubmit={handelSubmit}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={5}
          >
            <Typography sx={muiStyles.pageTitle}>
              {productId ? "Edit" : "Add"} Products
            </Typography>
            <Button
              variant="contained"
              type="submit"
              sx={muiStyles.primaryButton}
            >
              Submit
            </Button>
          </Stack>
          <ThemeProvider theme={FormFieldsTheme}>
            <Grid container rowSpacing={6} columnSpacing={4} mt={3}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <FormLabel>Product Code</FormLabel>
                  <TextField
                    name="product_code"
                    value={product.product_code}
                    onChange={handleInputChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <FormLabel>Product Description</FormLabel>
                  <TextField
                    name="desc"
                    value={product.desc}
                    onChange={handleInputChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <FormLabel>MFPS ID</FormLabel>
                  <TextField
                    name="specification"
                    value={product.specification}
                    onChange={handleInputChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <FormLabel>MFPS Revision Number</FormLabel>
                  <TextField
                    name="specification_revision"
                    value={product.specification_revision}
                    onChange={handleInputChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <FormLabel>Product Stage</FormLabel>
                  <ToggleButtonGroup
                    exclusive
                    aria-label="product statge"
                    fullWidth
                    name="production_stage_id"
                    value={product.production_stage_id}
                    onChange={handleProductStage}
                  >
                    {
                      stages.map(stage => <ToggleButton value={stage.id} key={stage.id} aria-label={stage.name} disableRipple>
                      {stage.name}
                    </ToggleButton>)
                    }
                  </ToggleButtonGroup>
                </FormControl>
              </Grid>
            </Grid>
          </ThemeProvider>
        </form>
      </Box>
  );
};

export default AddEditProductForm;
