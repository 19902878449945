import React from 'react'
import { Typography, Box, Button } from '@mui/material'
import { getWeekNumber } from '../../utils/helperFunctions'
import { colors } from '../../styles/colors'

const AddJobCard = ({handleAddJob, weekNumber}) => {
  
  return (
    <Button
        sx={{
          width: '100%',
          color: 'rgba(52, 58, 59, 1)',
          padding: "8px",
          border: "0.5px solid #EAEAEA",
          borderRadius: "2px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          transition: "transform 0.3s, box-shadow 0.3s",
          marginTop: '4px',
          textTransform: 'none',
          "&:hover": {
            transform: "scale(1.001)",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
          },
          "&:hover .inner-box": {
            backgroundColor: colors.primary,
            color: "#fff",
          },
        }}
        position="relative"
        onClick={() => handleAddJob(true)}
        disabled={weekNumber < getWeekNumber()}
      >
        <Box
          sx={{
            padding: "12px 0",
            backgroundColor: "#E6E9EC",
            borderRadius: "5px",
            width: "100%",
          }}
          className="inner-box"
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "22px",
            }}
          >
            + Add New
          </Typography>
        </Box>
      </Button>
  )
}

export default AddJobCard