import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "wdevicesgetSlice",
  initialState: {
    items: [],
  },
  reducers: {
    setwdeviceassign: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const { setwdeviceassign } = slice.actions;

export const getwdeviceassign = (state) => {
  return state.wdevicesgetSlice.items;
};

export default slice.reducer;
