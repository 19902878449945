import { Search } from "@mui/icons-material";
import { setShift } from "../../../features/shift/shiftSlice";
import useApi from "../../../hooks/useAPI";
import muiStyles from "../../../styles/MuiComponentStyles";
import { formatDate, getTextColor } from "../../../utils/helperFunctions";
import { Loading } from "../../UIComponents";
import {
  Box,
  Stack,
  Typography,
  Paper,
  Breadcrumbs,
  TextField,
  InputAdornment
} from "@mui/material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";

const CrewDetail = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const shifts = useSelector((state) => state.shift.items);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const { loading, getData } = useApi();
  const workersUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/worker`;
  const workOrder = location?.state?.workOrder;
  const template = location?.state?.template;
  const [searchText, setSearchText] = useState("");
  const [workers, setWorkers] = useState([]);
  const [filterdWorkersList, setFilterdWorkersList] = useState([]);
  const [product, setProduct] = useState(null);
  const [productionResource, setProductionResource] = useState(null);
  const [lineVariation, setLineVariation] = React.useState([]);
  const [line, setLine] = React.useState(null);

  React.useEffect(() => {
    if (user.tenant_id && user.zone_id) {
      fetchTemplate();
      fetchShifts();
      fetchProducts();
    }
    // eslint-disable-next-line
  }, [user.tenant_id, user.zone_id]);

  const handleSearch = (e) => {
      let value = e.target.value || "";
      value = value.toLocaleLowerCase();
    const list = workers.filter((item) => {
        return (
            item.first_name.toLocaleLowerCase().includes(value) ||
            item.last_name.toLocaleLowerCase().includes(value) ||
            item.code.toLocaleLowerCase().includes(value)
            );
        });
    setSearchText(value);
    setFilterdWorkersList(list);
  };

  const fetchShifts = async () => {
    const shiftURL = `${process.env.REACT_APP_BASE_URL}/web/v2/shift`;
    try {
      const response =
        (await getData(`${shiftURL}/all/${workOrder.zone_id}`)) || [];
      dispatch(setShift(response));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProducts = async () => {
    try {
      const productUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/product`;
      const response =
        (await getData(
          `${productUrl}/all/${user.tenant_id}/${user.zone_id}`
        )) || [];
      const _product =
        response.find((item) => item.id === workOrder.product_id) || null;
      setProduct(_product);
      fetchResources();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchResources = async (productId) => {
    try {
      const productionResourceUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/production/resource`;
      const lineURL = `${process.env.REACT_APP_BASE_URL}/web/v2/line`;
      const data =
        (await getData(
          `${productionResourceUrl}/all/${workOrder.product_id}`
        )) || [];
      const resource =
        data.find((item) => item.id === workOrder.production_resource_id) ||
        null;
      const lines =
        (await getData(`${lineURL}/all/${user.zone_id}/page/1/count/9999`)) ||
        [];
      const _line = lines?.lines?.find((line) => line.id === resource.line_id);
      fetchLineVariation(resource.line_id, resource.line_variation_id);
      setProductionResource(resource);
      setLine(_line);
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchLineVariation(id, line_variation_id) {
    try {
      const lineURL = `${process.env.REACT_APP_BASE_URL}/web/v2/line`;
      await getData(
        `${lineURL}/variation/all/${id}/page/${1}/count/${999}`
      ).then((data) => {
        const variation = data.variations?.find(
          (variation) => variation.id === line_variation_id
        );
        setLineVariation(variation);
      });
    } catch (error) {
      console.log(error);
    }
  }

  const fetchTemplate = async () => {
    try {
      const response = await getData(
        `${workersUrl}/assignment/template/all/workers/${templateId}`
      );
      setWorkers(response);
      setFilterdWorkersList(response);
    } catch (error) {
      console.log(error);
    }
  };

  //   console.log("teml", workers, templateId);

  return loading ? (
    <Loading />
  ) : (
    <Box>
      <Box mt={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ color: "#000" }} href="/">
            Home
          </Link>
          <Link style={{ color: "#000" }} to="/schedule-jobs">
            Schedule Job
          </Link>
          <Link
            style={{ color: "#000" }}
            to={".."}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            Crew
          </Link>
          <Typography color="text.primary">Crew Create</Typography>
        </Breadcrumbs>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={5}
      >
        <Typography sx={muiStyles.pageTitle}>Crew Sheet</Typography>
        {/* <Button onClick={() => navigate('/crew', {state: {workOrder}})}>Go back</Button> */}
      </Stack>
      <Paper variant="outlined">
        <Box
          mt={4}
          sx={{
            width: "100%",
            height: "200px",
            borderRadius: "6px",
            py: 2,
            px: 5,
          }}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="start"
            sx={{ height: "100%" }}
          >
            <Typography>
              <strong>Work Order</strong>: {workOrder?.work_order}
            </Typography>
            <Typography>
              <strong>Product</strong>: {product?.product_code}
            </Typography>
            <Typography>
              <strong>Line</strong>: {line?.name}
            </Typography>
            <Typography>
              <strong>Start Date</strong>: {formatDate(workOrder?.start_date)}
            </Typography>
            <Typography>
              <strong>Status</strong>: {workOrder.work_order_status}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="start"
            sx={{ height: "100%" }}
          >
            <Typography>
              <strong>Customer Reference</strong>:{" "}
              {workOrder?.customer_reference}
            </Typography>
            <Typography>
              <strong>Production Resource</strong>: {productionResource?.name}
            </Typography>
            <Typography>
              <strong>Schamatic Type</strong>: {lineVariation?.name}
            </Typography>
            <Typography>
              <strong>End Date</strong>: {formatDate(workOrder.end_date)}
            </Typography>
            <Typography>
              <strong>Workers</strong>: {workers?.length}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="start"
            sx={{ height: "100%" }}
          >
            <Typography>
              <strong>Quantity</strong>: {workOrder.quantity}
            </Typography>
            <Typography>
              <strong>Priority</strong>: {workOrder.priority}
            </Typography>
            <Typography>
              <strong>Shift</strong>:{" "}
              {shifts?.find((shift) => shift.id === template?.shift_id)?.name}
            </Typography>
            <Typography>
              <strong>Scheduled Date</strong>:{" "}
              {formatDate(template?.scheduled_date)}
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mt={3}>
        <TextField
          type="text"
          variant="outlined"
          placeholder="Search..."
          onChange={handleSearch}
          value={searchText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Paper
        variant="outlined"
        sx={{
          width: "100%",
          maxHeight: 680,
          overflowY: "scroll",
          px: 2,
          pt: 1,
          borderRadius: 2,
          mt: 1,
          mb: 3,
        }}
      >
        {filterdWorkersList.map((item) => {
          return (
            <Paper
              variant="outlined"
              elevation={3}
              key={item.id}
              sx={{
                borderRadius: "5px",
                p: 2,
                my: 1,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: item.color_code,
                color: getTextColor(item.color_code)
              }}
            >
              <Typography>{item.code}</Typography>
              <Typography>
                {item.first_name} {item.last_name}
              </Typography>
              <Typography>{item.skills}</Typography>
            </Paper>
          );
        })}
      </Paper>
    </Box>
  );
};

export default CrewDetail;
