import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "workerSlice",
  initialState: {
    workers: [],
  },
  reducers: {
    setWorkers: (state, action) => {
      state.workers = action.payload;
    },
  },
});

export const { setWorkers } = slice.actions;

export const getWorkers = (state) => {
  return state.workerSlice.items;
};

export default slice.reducer;
