import {
  questionInputChange,
  removeQuestion,
  // addOption,
  // updateQuestionImage,
} from "../../../../features/ProductionResourceSteps/stepsSlice";
// import UploadFile from "../Media/UploadFile";
import Options from "./Options";
import { HighlightOff, DeleteForever } from "@mui/icons-material";
import {
  Box,
  Grid,
  OutlinedInput,
  Typography,
  InputAdornment,
  IconButton,
  // Button,
  ThemeProvider,
  createTheme
} from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

const QuestionFieldsTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#2E3646",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          display: "flex",
          width: "70%",
          height: "10rem",
          backgroundColor: "#F8F9FB",
          padding: "8px",
          alignItems: "center",
          gap: "16px",
          flex: "1 0 0",
          border: "1px solid #2E3646",
          color: "#2E3646",
          marginTop: "12px",
          marginRight: '1rem',
        },
      },
    },
  },
});

const Question = ({ qIndex, question, handleDelete }) => {
  const dispatch = useDispatch();
  const isStepEditable = useSelector((state) => state.steps.isStepEditable);

  const handleQustionInputChange = (e, index) => {
    dispatch(questionInputChange({ index, value: e.target.value }));
  };

  // const handleQuestionImageChange = (url, type, file) => {
  //   dispatch(updateQuestionImage({ url, type, file, index: qIndex }));
  // };

  const handleRemoveQuestion = (qIndex) => {
    dispatch(removeQuestion(qIndex));
  };

  // const handleAddOption = () => {
  //   dispatch(addOption(qIndex));
  // };

  return (
    <Box mt={2} sx={{ border: "1px solid gray", borderRadius: "6px", p: 2 }}>
      <Typography>Question {qIndex + 1}</Typography>
      <Grid container spacing={2}>
        <Grid item md={12} sx={{display: 'flex', flexDirection: 'row'}}>
          <ThemeProvider theme={QuestionFieldsTheme}>
            <OutlinedInput
              value={question.question}
              multiline
              onChange={(e) => handleQustionInputChange(e, qIndex)}
              startAdornment={
                <InputAdornment position="start" sx={{ color: "#000" }}>
                  Q{qIndex + 1}.
                </InputAdornment>
              }
              endAdornment={
                  <InputAdornment>
                    <IconButton
                      sx={{ "&:hover": { color: "red" } }}
                      onClick={() => question.id ? handleDelete(question.id) : handleRemoveQuestion(qIndex)}
                    >
                      {question.id ? <DeleteForever /> : <HighlightOff />}
                    </IconButton>
                  </InputAdornment>
              }
              required
              inputProps={{ readOnly: !!question.id }}
              disabled={!isStepEditable}
            />
          </ThemeProvider>
          {/* <Box
            sx={{
              width: "25%",
              height: "10rem",
              boeder: "1px solid #5F6D7E",
              backgroundColor: !question?.file?.url ? "#E6E9EC" : "none",
              borderRadius: "10px",
              mt: 1,
              ml: '1'
            }}
            position="relative"
          >
            <UploadFile
              accept="image/*"
              optional={true}
              label="image"
              height="10rem"
              file={question?.image}
              handleFileChange={handleQuestionImageChange}
              handleDeleteImage={() => {}}
            />
          </Box> */}
        </Grid>
        <Options qIndex={qIndex} question={question} />
      </Grid>
      {/* <Box mt={1} sx={{ textAlign: "right" }}>
        <Button
          onClick={handleAddOption}
          disabled={question.options.length >= 5}
        >
          Add Option
        </Button>
      </Box> */}
    </Box>
  );
};

export default Question;
