import { useDispatch } from "react-redux";
import useApi from "../../../hooks/useAPI";
import muiStyles from "../../../styles/MuiComponentStyles";
import { formatDate, isGivenDateGraterThanEqualToday } from "../../../utils/helperFunctions";
import { formatToUTC } from '../../../utils/dateUtils'
import { CosmeticaTable } from "../../UIComponents";
import { useCssStateHook } from "../../UIComponents/ComonCss";
import { Box, Stack, Typography, Button, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { setShift } from "../../../features/shift/shiftSlice";

const CrewList = () => {
  const dispatch = useDispatch()
  const location = useLocation();
  const navigate = useNavigate();
  const { getData } = useApi();
  const user = useSelector((state) => state.user.user);
  const shifts = useSelector((state) => state.shift.items);
  const workOrder = location?.state?.workOrder;
  const { themeCssButton, themeCssHeader } = useCssStateHook();
  const [templates, setTemplates] = useState([]);
  // const today = new Date().toISOString();
  // const scheduledDate = isDateWithinRange(
  //   today,
  //   workOrder?.start_date,
  //   workOrder?.end_date
  // )
  //   ? today
  //   : workOrder?.start_date;

  const handleOnclickRow = (id) => {
    navigate(`/crew/${id}`, {
      state: {workOrder, template: templates.find((item) => item.id === id)}
    })
}

  const fields = ["Name", "Work Order", "Scheduled Date", "Shift"];
  const rows = templates.map((row) => {
    const { id, name, scheduled_date, shift_id } = row;
    const shift = shifts.find((item) => item.id === shift_id)?.name || "";
    return {
      id,
      name: <NavLink style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}} to={`/crew/${id}`} state={{workOrder, template: templates.find((item) => item.id === id)}}>{name}</NavLink>,
      work_order: workOrder?.work_order,
      scheduled_date: formatToUTC(scheduled_date),
      shift,
    };
  });

  useEffect(() => {
    if (user.tenant_id && user.zone_id) {
      fetchTemplates();
      fetchShifts()
    }
    // eslint-disable-next-line
  }, [user.tenant_id, user.zone_id]);

  const fetchTemplates = async () => {
    const workersUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/worker`;
    try {
      const response = await getData(
        `${workersUrl}/assignment/template/all/date/0/wo/${workOrder?.id}/shift/0/tenant/${workOrder?.tenant_id}/zone/${workOrder?.zone_id}`
      );
      setTemplates(response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchShifts = async () => {
    const shiftURL = `${process.env.REACT_APP_BASE_URL}/web/v2/shift`
    try {
      const response = await getData(`${shiftURL}/all/${workOrder.zone_id}`) || []
      dispatch(setShift(response))
    } catch (error) {
        console.log(error);
    }
  }

  const handleEdit = (id) => {
    navigate(`/crew/edit/${id}`, {
      state: { workOrder, template: templates.find((item) => item.id === id) },
    });
  };

  const handleDelete = (id) => {
    console.log(id);
  };

  const handleEnableRowAction = (row) => {
    return isGivenDateGraterThanEqualToday(row.scheduled_date)
  }

  function groupByDate(items) {
    return items.reduce((groups, item) => {
      const date = item.scheduled_date.split('T')[0];
      if (isGivenDateGraterThanEqualToday(formatDate(date))) {
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item.shift_id);
      }
      return groups;
    }, {});
  }

  const getCustomActions = () => {
    return <p>TEST</p>
  }

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={5}
      >
        <Typography sx={muiStyles.pageTitle}>Crew Templates</Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/crew/create", { state: { workOrder, assignedShifts: groupByDate(templates) } })}
          // sx={muiStyles.primaryButton}
          sx={themeCssButton}
          disabled={workOrder.work_order_status_id === 3}
        >
          Add New
        </Button>
      </Stack>
      {
        workOrder.work_order_status_id === 3 && <Box mt={3}>
          <Alert severity="info">Completed tasks cannot be created, edited, or deleted.</Alert>
        </Box>
      }
      <Box sx={{ mt: 4 }}>
        <CosmeticaTable
          fields={fields}
          rows={rows.reverse()}
          isDelete={false && workOrder.work_order_status_id !== 3}
          isView={true}
          fallbackText={'No Crew sheets found, please create one.'}
          isEdit={workOrder.work_order_status_id !== 3}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          //   handlePageChange={handlePageChange}
          //   handleRowsPerPageChange={handleRowsPerPageChange}
          rowsCount={rows.length ?? 0}
          //   page={page}
          //   rowsPerPage={rowsPerPage}
          handleOnclickRow={handleOnclickRow}
          isHeadercss={themeCssHeader}
          enableRowActionFn={handleEnableRowAction}
          customActions={getCustomActions()}
        />
      </Box>
    </Box>
  );
};

export default CrewList;
