import ".././components/Tenants/tenant.css";
import useApi from "../hooks/useAPI";
import { colors } from "../styles/colors";
import MainNav from "./main-nav";
import { useAuth0 } from "@auth0/auth0-react";
import { AppBar, Toolbar, Typography, Container, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const NavBar = ({ onClone }) => {
  // const tenantData = useSelector((state) => state.tenanturl.items);
  // const tenantNm = useSelector((state) => state.tenanturl.itemsname);
  const userInfo = useSelector((state) => state.user.user);
  const [tenanturl, setTenanturl] = useState();
  const [tenantName, setTenantName] = useState();
  const [fileName, setFileName] = useState();
  const { getData, postData } = useApi();

  // const originalElementRef = React.useRef(null);

  // useEffect(() => {
  //   setTimeout(() => {
  //     onClone(originalElementRef.current);
  //   }, 1000);
  //   // eslint-disable-next-line
  // }, [onClone, originalElementRef.current]);

  const tenantURLData = `${process.env.REACT_APP_BASE_URL_ADMIN}/admin/media/tenant`;
  const mediaView = `${process.env.REACT_APP_BASE_URL_ADMIN}/admin/media`;
  const { isAuthenticated } = useAuth0();

  const callAPIData = async () => {
    try {
      getData(`${tenantURLData}/image/tenant/${userInfo.tenant_id}`).then(
        (data) => {
          const urlInfo = data || [];

          setFileName(urlInfo.file_name);
          setTenantName(urlInfo.name);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const callAPIurl = async () => {
    if (fileName != null) {
      try {
        postData(`${mediaView}/view`, {
          file_name: fileName,
        }).then((data) => {
          const logoInfo = data || [];
          setTenanturl(logoInfo.url);
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const logourl = () => {
      if (userInfo.tenant_id) {
        callAPIData();
      }
    };

    if (userInfo.tenant_id !== null) {
      logourl();
    } else {
      if (isAuthenticated) {
        logourl();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.tenant_id]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [localStorage.getItem("Tenanturl"), tenantData, userInfo]);

  useEffect(() => {
    callAPIurl();
    // eslint-disable-next-line
  }, [fileName]);

  return (
    <AppBar
      position="static"
      sx={{
        padding: "0 24px",
        boxShadow: "none",
        borderBottom: "1px solid #5F6D7E",
        height: "70px",
      }}
      color="transparent"
    >
      <Container maxWidth="xl" sx={{ px: 0 }}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component={NavLink}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {isAuthenticated && tenanturl && tenantName ? (
              <>
                <img
                  // ref={originalElementRef}
                  src={`${tenanturl}`}
                  alt="Logo"
                  height={"65px"}
                  id="tenant-logo-el"
                  width={"150px"}
                  className="imagesNav"
                />
                {/* <Typography
                    padding={"10px"}
                    fontSize={"20px"}
                    fontWeight={"bold"}
                  >
                    {tenantName}
                  </Typography> */}
              </>
            ) : (
              <Stack>
                <Typography
                  fontSize={"30px"}
                  fontWeight="800"
                  color={colors.primary}
                >
                  DFA
                </Typography>
                <Typography
                  fontSize={"12px"}
                  marginTop="-8px"
                  color="gray"
                  fontStyle="italic"
                >
                  Digital Floor Automation
                </Typography>
              </Stack>
            )}
          </Typography>
          <MainNav />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
