import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useRef } from "react";

const useAxiosTokenInterceptor = async (isAuthenticated) => {
  const { getAccessTokenSilently } = useAuth0();
  const axiosInterceptor = useRef(null);
  const accessToken = useRef("");
  const tokenPromise = isAuthenticated
    ? getAccessTokenSilently({
        authorizationParams: {
          scope: "offline_access",
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          useRefreshTokens: true,
          cacheLocation: "localstorage",
        },
      })
    : null;

  function setupInterceptor() {
    if (axiosInterceptor.current && !accessToken.current) {
      axios.interceptors.request.eject(axiosInterceptor.current);
      axiosInterceptor.current = null;
    }
    if (!axiosInterceptor.current && tokenPromise) {
      axiosInterceptor.current = axios.interceptors.request.use(
        async (requestConfig) => {
          if (!accessToken.current) {
            accessToken.current = await tokenPromise;
          }
          requestConfig.headers[
            "Authorization"
          ] = `Bearer ${accessToken.current}`;
          return requestConfig;
        }
      );
    }
  }

  setupInterceptor();

  return <></>;
};

export default useAxiosTokenInterceptor;
