import { createTheme } from "@mui/material";

export const FormFieldsTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#2E3646",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          display: "flex",
          width: "100%",
          height: "46px",
          backgroundColor: "#F8F9FB",
          padding: "8px",
          alignItems: "center",
          gap: "16px",
          flex: "1 0 0",
          border: "1px solid #2E3646",
          color: "#2E3646",
          marginTop: "12px",
          // '&:has(> input:-webkit-autofill)': {
          //     background: 'none',
          // },
          // '& input:-internal-autofill-selected': {
          //     background: 'none',
          // }
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          height: "46px",
          marginTop: "12px",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "#2E3646",
          backgroundColor: "#E6E9EC",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "17px",
          textTransform: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: '1px solid black !important',
          "&.Mui-selected": {
            fontWeight: 800,
            color: "#fff",
            backgroundColor: "#0D47A1",
            border: '2px solid black !important',
          },
          "&.Mui-selected:hover": {
            color: "#fff",
            backgroundColor: "#0D47A1",
          },
        },
      },
    },
  },
});

export const ModalFormTheme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: "8px",
          width: "100%",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&:nth-child(2)": {
            marginTop: "16px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '46px',
          '&.Mui-disabled': {
            backgroundColor: '#DEE0E4',
            color: '#2E3646'
          },
        }
      }
    }
  },
});