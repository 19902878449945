import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "roleSlice",
  initialState: {
    items: [],
    totalItems: 0,
    rowsPerPage: 10,
    page: 1,
  },
  reducers: {
    setRole: (state, action) => {
      state.items = action.payload.roles || [];
      state.totalItems = action.payload.count
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload
    }
  },
});

export const { setRole, setPage, setRowsPerPage } = slice.actions;

export const getRole = (state) => {
  return state.roleSlice.items;
};

export default slice.reducer;
