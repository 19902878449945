import { categoryList } from "../Permission/AddEditPermisstion";
import { useCssStateHook } from "./ComonCss";
import DeleteModal from "./DeleteModal";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getTextColor } from "../../utils/helperFunctions";
import {
  Stack,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TextField,
  TableBody,
  TableRow,
  TableCell,
  createTheme,
  ThemeProvider,
  Pagination,
  Typography,
  Select,
  MenuItem,
  Collapse,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";

const TableTheme = createTheme({
  components: {
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          margin: 0,
        },
        displayedRows: {
          margin: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px 16px 0px 0px",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          maxHeight: 800,
          borderRadius: "16px 16px 8px 8px",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#5F6D7E",
          "& .MuiTableCell-root": {
            color: "#ECECEC !important",
            fontSize: "14px",
            fontWeight: 500,
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          "& .MuiPaginationItem-root": {
            "&.Mui-selected": {
              background: "#000",
              color: "#fff",
              "&:hover": {
                background: "#000",
                color: "#fff",
              },
            },
          },
        },
      },
    },

    tableRow: {
      styleOverrides: {
        root: {
          backgroundColor: "green",
          "& .MuiTableRow-root": {
            color: "red !important",
          },
        },
      },
    },
  },
});

const CosmeticaTable = ({
  rows,
  fields,
  isEdit,
  isView,
  isDelete,
  handleEdit,
  handleDelete,
  isRowClick,
  handleOnclickRow,
  fallbackText = "No data to display.",
  ShowAccorrdion,
  pagination = true,
  handlePageChange,
  handleRowsPerPageChange,
  rowsCount,
  page = 1,
  rowsPerPage = 10,
  isHeadercss,
  isSearch = false,
  enableRowActionFn = () => true,
  customActions,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [rowId, setRowId] = React.useState(null);
  const start = page * rowsPerPage - (rowsPerPage - 1);
  const end = Math.min(start + rowsPerPage - 1, rowsCount);
  const { themeCssButton } = useCssStateHook();
  const [filter, setFilter] = useState("");

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDeleteClick = (id) => {
    setRowId(id);
    setShowModal(true);
  };

  const handleFilterChange = (event) => {
    const inputValue = event.target.value;
    setFilter(inputValue);
  };

  return (
    <ThemeProvider theme={TableTheme}>
      <TableContainer>
        {isSearch && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              my: 1,
              mr: 0.5,
            }}
          >
            <TextField
              id="search"
              variant="outlined"
              type="text"
              placeholder="Search......"
              value={filter}
              style={{
                width: "300px",
              }}
              onChange={handleFilterChange}
            />
          </Box>
        )}

        {ShowAccorrdion ? (
          <Table>
            <TableHead
              sx={
                isHeadercss && {
                  backgroundColor: isHeadercss.backgroundColor,
                 
                  fontWeight: 900,
                }
              }
            >
              <TableRow>
                {fields.map((field) => (
                  <TableCell align="left" key={field}>
                    {field}
                  </TableCell>
                ))}
                {(isEdit || isDelete) && <TableCell align="right"></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody >
              {rows.map((row) => (
                <Row
                  key={row.name}
                  row={row}
                  isEdit
                  isDelete
                  handleEdit={handleEdit}
                  handleDelete={handleDeleteClick}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Table>
            <TableHead
              sx={
                isHeadercss && { backgroundColor: isHeadercss.backgroundColor }
              }
            >
              <TableRow>
                {fields.map((field) => (
                  <TableCell align="left" key={field}>
                    {field}
                  </TableCell>
                ))}
                {(isEdit || isDelete) && <TableCell align="right"></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 &&
                // rows
                //   .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                //   .map((row) => (
                rows
                  ?.filter((i) => {
                    const keys = Object.keys(i);
                    let flag = false;
                    keys.forEach((k) => {
                      if (
                        i[k]?.toLowerCase?.()?.includes(filter?.toLowerCase())
                      ) {
                        flag = true;
                      }
                    });
                    return flag;
                  })
                  .map((row) => {
                    const isActionable = enableRowActionFn(row);
                    return (
                      <TableRow
                        key={row.id}
                        // sx={
                        //   row?.color_code
                        //     ? {
                        //         backgroundColor: row.color_code,
                               
                        //         "&.MuiTableRow-root:hover": {
                        //           backgroundColor: "#EEEEEE",
                        //         },
                        //       }
                        //     : {
                        //         "&.MuiTableRow-root:hover": {
                        //           backgroundColor: "#EEEEEE",
                        //         },
                        //       }
                        // }
                        hover
                        sx={{
                          "&.MuiTableRow-root:hover": {
                            backgroundColor: "#EEEEEE",
                          },
                        }}
                      >
                        {Object.keys(row).map((key, i) => {
                          if (key !== "id" && key !== "colorCodeStr") {
                            return (
                              <>
                                {isRowClick &&
                                (key === "name" ||
                                  key === "short_desc" ||
                                  key === "desc" ||
                                  key === "variation_count" ||
                                  key === "created_at" ||
                                  key === "") ? (
                                  <TableCell
                                    hover
                                    sx={
                                      key === "name"
                                        ? {
                                            textDecoration: "underline",
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                           
                                          }
                                        : {
                                            textDecoration: "none",

                                            "&.MuiTableCell-root:hover": {
                                              // textDecoration: "underline" ,
                                              cursor: "pointer",
                                            },
                                          }
                                    }
                                    align="left"
                                    key={i}
                                    onClick={() => handleOnclickRow(row.id)}
                                  >
                                    {row[key]}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align={
                                      key === "variation_count"
                                        ? "center"
                                        : "left"
                                    }
                                    key={i}
                                    sx={{
                                      color: getTextColor(row.color_code),
                                      background:row.color_code,
                                    }}
                                  >
                                    
                                    {row[key==="color_code"?"" :key]}
                                  </TableCell>
                                )}
                              </>
                            );
                          } else if (key === "colorCodeStr") {
                          return <TableCell
                              align={"left"}
                              key={i}
                              sx={{
                                background:row.colorCodeStr,
                              }}
                            >
                              {''}
                            </TableCell>
                          }
                          return null;
                        })}
                        {(isEdit || isView || isDelete) && (
                          <TableCell align="right" 
                          size="medium" 
                          sx={{
                            background:row.color_code
                          }} >
                            <Stack
                              direction="row"
                              spacing={1}
                              justifyContent="flex-end"
                              alignItems="center"
                            >
                              {isActionable && isEdit && (
                                <IconButton
                                  aria-label="Edit"
                                  onClick={() => handleEdit(row.id)}
                                  disabled={!isActionable}
                                  style={{
                                    pointerEvents: "all",
                                    cursor: isActionable
                                      ? "pointer"
                                      : "not-allowed",
                                  }}  
                                >
                                  <EditOutlinedIcon
                                    sx={{
                                      color: "#0A1629",
                                      opacity: isActionable ? 1 : 0.6,
                                    }}
                                  />
                                </IconButton>
                              )}
                              {!isActionable && isView && (
                                <IconButton
                                  aria-label="Edit"
                                  onClick={() => handleOnclickRow(row.id)}
                                >
                                  <VisibilityIcon sx={{ color: "#0A1629" }} />
                                </IconButton>
                              )}
                              {isDelete && (
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => handleDeleteClick(row.id)}
                                  disabled={!isActionable}
                                  style={{
                                    pointerEvents: "all",
                                    cursor: isActionable
                                      ? "pointer"
                                      : "not-allowed",
                                  }}
                                >
                                  <DeleteOutlineOutlinedIcon
                                    color="error"
                                    sx={{ opacity: isActionable ? 1 : 0.6 }}
                                  />
                                </IconButton>
                              )}
                            </Stack>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        )}
        {rows.length < 1 && (
          <Typography
            variant="h5"
            my={3}
            sx={{ width: "100%", textAlign: "center" }}
          >
            {fallbackText}
          </Typography>
        )}
      </TableContainer>
      {pagination && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Typography>
            {rowsCount > 0
              ? `Showing ${start} to ${end} of ${rowsCount} entries`
              : "Showing 0 entries"}
          </Typography>
          {Math.ceil(rowsCount / rowsPerPage) > 1 && (
            <Stack direction="row" spacing={3} alignItems="center">
              <Select
                onChange={(e) => handleRowsPerPageChange(e.target.value)}
                size="small"
                value={rowsPerPage}
              >
                {[5, 10, 25, 50, 100].map((opt) => (
                  <MenuItem key={opt} value={opt}>
                    {opt}
                  </MenuItem>
                ))}
              </Select>
              <Pagination
                count={Math.ceil(rowsCount / rowsPerPage)}
                shape="rounded"
                page={page}
                onChange={(event, newPage) => {
                  handlePageChange(newPage);
                }}
                sx={{
                  "& .MuiPaginationItem-root.Mui-selected": {
                    background: themeCssButton.backgroundColor,
                  },
                }}
              />
            </Stack>
          )}
        </Stack>
      )}

      {/* </Paper> */}
      <DeleteModal
        showModal={showModal}
        handleClose={handleClose}
        handleDelete={() => {
          handleDelete(rowId);
          handleClose();
        }}
        isHeadercss={themeCssButton}
      />
    </ThemeProvider>
  );
};
const Row = (props) => {
  const { row, isEdit, isDelete, handleEdit, handleDelete } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow
        sx={
          row?.color_code
            ? {
                backgroundColor: row.color_code,
                "&.MuiTableRow-root:hover": {
                  backgroundColor: "#EEEEEE",
                },
              }
            : {
                "&.MuiTableRow-root:hover": {
                  backgroundColor: "#EEEEEE",
                },
              }
        }
        hover
      >
        <TableCell component="th" scope="row" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          {row.permissionName.name}
        </TableCell>
        <TableCell>{row.created_at}</TableCell>
        {(isEdit || isDelete) && (
          <TableCell align="right" size="medium">
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              {isEdit && (
                <IconButton
                  aria-label="Edit"
                  onClick={() => handleEdit(row.id)}
                >
                  <EditOutlinedIcon sx={{ color: "#0A1629" }} />
                </IconButton>
              )}
              {isDelete && (
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(row.id)}
                >
                  <DeleteOutlineOutlinedIcon color="error" />
                </IconButton>
              )}
            </Stack>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                {categoryList?.length > 0
                  ? categoryList?.map((ele, index) => {
                      return (
                        <>
                          <Box
                            sx={{ display: "flex", mt: 1, paddingLeft: "17px" }}
                          >
                            <Typography sx={{ width: "200px" }}>
                              {ele.category} :
                            </Typography>
                            &nbsp;
                            <Typography sx={{ fontWeight: "bold" }}>
                              {row["permissionName"].permission?.split("")[
                                index
                              ] === "x"
                                ? "None"
                                : row["permissionName"].permission?.split("")[
                                    index
                                  ] === "r"
                                ? "Read Only"
                                : row["permissionName"].permission?.split("")[
                                    index
                                  ] === "w"
                                ? "Full Access"
                                : "-"}
                            </Typography>
                          </Box>
                        </>
                      );
                    })
                  : null}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default CosmeticaTable;
