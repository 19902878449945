import { createSlice } from "@reduxjs/toolkit";
import { getWeekNumber } from "../../utils/helperFunctions";

export const scheduleJobsSlice = createSlice({
    name: 'scheduleJobSlice',
    initialState: {
        workOrders: [],
        priorities: [],
        workOrderStatus: [],
        currentWeek: getWeekNumber(),
        currentYear: new Date().getFullYear(),
    },
    reducers: {
        setWorkOrders: (state, action) => {
            state.workOrders =  action.payload;
        },
        setPriorities: (state, action) => {
            state.priorities = action.payload
        },
        setWorkOrderStatus: (state, action) => {
            state.workOrderStatus = action.payload
        },
        setCurrentWeek: (state, action) => {
            state.currentWeek = action.payload
        },
        setCurrentYear: (state, action) => {
            state.currentYear = action.payload
        },
        resetState: (state, action) => {
            state = {
                workOrders: [],
                // priorities: [],
                // workOrderStatus: [],
                currentWeek: getWeekNumber(),
                currentYear: new Date().getFullYear(),
            }
        }
    }
})

export const {setWorkOrders, setPriorities, setCurrentWeek, setWeeks, setCurrentYear, setWorkOrderStatus, resetState} = scheduleJobsSlice.actions

export default scheduleJobsSlice.reducer