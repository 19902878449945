import herobg from "../images/CarouselImages/herobg.png";
import image1 from "../images/CarouselImages/image1.jpeg";
// import image2 from "../images/CarouselImages/image2.jpeg";
// import image3 from "../images/CarouselImages/image3.jpeg";
import image4 from "../images/CarouselImages/image4.jpeg";
// import image5 from "../images/CarouselImages/image5.png";
import { Paper, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";

const fadedRead = {
  backdropFilter: "blur(10px)",
  backgroundColor: "rgba(256, 256, 256, 0.7)",
  color: "black",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "24px",
  borderRadius: "16px",
};

const Hero = ({ renderTitle }) => {
  const carouselImages = [herobg, image1, image4]; // image1, image2, image3, image4, image5
  return (
    <div
      className="text-center hero"
      style={{ position: "relative", marginTop: "48px" }}
    >
      <Carousel duration="2000" height="90vh">
        {carouselImages.map((image, i) => {
          return (
            <Paper key={i} position="relative">
              <img
                src={image}
                alt={`CarouselImage ${i + 1}`}
                style={{
                  zIndex: -1,
                  objectFit: "fill",
                  width: "100%",
                  height: "90vh",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(256, 256, 256, 0.3)",
                }}
              ></div>
            </Paper>
          );
        })}
      </Carousel>

      <div>
        <Typography
          position="absolute"
          style={{ ...fadedRead }}
          sx={{
            left: "75%",
            width: "40%",
            marginRight: "34px",
            top: "40%",
            zIndex: 1,
            transform: "translate(-50%, -50%)",
            color: "#fff",
            fontSize: "18px",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontSize: "30px",
              marginBottom: "16px",
            }}
          >
            {renderTitle()}
          </div>
          {/* <Typography
          position="absolute"
          sx={{
            left: "50%",
            top: "15%",
            zIndex: 1,
            transform: "translate(-50%, -50%)",
            color: "#fff",
            fontSize: "48px",
          }}
        >
          {renderTitle()}
        </Typography> */}
          <ul>
            <li>Improved Quality Control and Product Accuracy.</li>
            <li>
              Workers can perform precision work and increase overall quality.
            </li>
            <li>
              Reduced Materials Waste - that helps reduce waste and associated
              costs.
            </li>
            <li>Improved Production</li>
            <li>
              Times and Output - You are able to generate higher volumes in a
              shorter period of time.
            </li>
          </ul>
        </Typography>
      </div>
    </div>
  );
};

export default Hero;
