import React from "react";

const ClassificationTable = ({aql}) => {
  return (
    <table className="c99">
      <tbody>
        <tr className="c48">
          <td className="c21" colSpan={1} rowSpan={1}>
            <p className="c28 c101">
              <span className="c22">Classification of Defect</span>
            </p>
          </td>
          <td className="c55" colSpan={1} rowSpan={1}>
            <p className="c5">
              <span className="c22">C</span>
            </p>
          </td>
          <td className="c55" colSpan={1} rowSpan={1}>
            <p className="c5">
              <span className="c22">C</span>
            </p>
          </td>
          <td className="c42" colSpan={1} rowSpan={1}>
            <p className="c5">
              <span className="c22">M</span>
            </p>
            <p className="c85">
              <span className="c22">(funct)</span>
            </p>
          </td>
          <td className="c42" colSpan={1} rowSpan={1}>
            <p className="c5">
              <span className="c22">M</span>
            </p>
            <p className="c85">
              <span className="c22">(aesth)</span>
            </p>
          </td>
          <td className="c46" colSpan={1} rowSpan={1}>
            <p className="c5">
              <span className="c22">m</span>
            </p>
          </td>
        </tr>
        <tr className="c18">
          <td className="c21" colSpan={1} rowSpan={1}>
            <p className="c28">
              <span className="c22">AQL</span>
            </p>
          </td>
          <td className="c55" colSpan={1} rowSpan={1}>
            <p className="c5">
              <span className="c22">0.1</span>
            </p>
          </td>
          <td className="c55" colSpan={1} rowSpan={1}>
            <p className="c5">
              <span className="c22">0.65</span>
            </p>
          </td>
          <td className="c42" colSpan={1} rowSpan={1}>
            <p className="c5">
              <span className="c22">1.0</span>
            </p>
          </td>
          <td className="c42" colSpan={1} rowSpan={1}>
            <p className="c5">
              <span className="c22">1.5</span>
            </p>
          </td>
          <td className="c46" colSpan={1} rowSpan={1}>
            <p className="c5 c114">
              <span className="c22">4.0</span>
            </p>
          </td>
        </tr>
        <tr className="c48">
          <td className="c21" colSpan={1} rowSpan={1}>
            <p className="c28 c111">
              <span className="c22">Accept/ Reject Criteria</span>
            </p>
          </td>
          {
            aql.map((item, index) => {
              return <td key={index} className="c55" colSpan={1} rowSpan={1}>
                      <p className="c1">
                        <span className="c2">{item.criteria}</span>
                      </p>
                    </td>
            })
          }
          {/* <td className="c55" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c55" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c42" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c42" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c46" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
      </tbody>
    </table>
  );
};

export default ClassificationTable;
