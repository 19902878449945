// eslint-disable-next-line
import React from "react"
import { Grid, FormControl, FormLabel, TextField, Box, Select, MenuItem } from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import { setLine } from "../../../../features/line/lineSlice"
import useApi from "../../../../hooks/useAPI"
// import UploadFile from "../Media/UploadFile"

const ResourceDetailsForm = ({resource, handleInputChange=(() => {})}) => {
    const {getData} = useApi()
    const dispatch = useDispatch()
    const lines = useSelector(state => state.line.items)
    const user = useSelector(state => state.user.user)
    const [lineVariations, setLineVariations] = React.useState([]);
    const lineURL = `${process.env.REACT_APP_BASE_URL}/web/v2/line`;

    React.useEffect(() => {
      if (user.tenant_id && user.zone_id) {
        if (lines.length < 1) {
          fetchLines();
        }
        if (resource?.line_id) {
          fetchLineVariations(resource?.line_id);
        }
      }
      // eslint-disable-next-line
    }, [user.tenant_id, user.zone_id]);

    function fetchLines() {
        const response = getData(
          `${lineURL}/all/${user.zone_id}/page/${1}/count/${999}`
        );
        response.then((data) => {
          dispatch(setLine(data || []));
        }).catch(error => {
          console.log(error);
        });
    }

    function fetchLineVariations(id) {
        const response = getData(
          `${lineURL}/variation/all/${id}/page/${1}/count/${999}`
        );
        response.then((data) => {
          setLineVariations(data.variations || []);
        }).catch(error => {
          console.log(error);
        });
    }

    const handleLineChange = (e) => {
      fetchLineVariations(e.target.value)
      handleInputChange(e)
    }

    // const video = resource.video

    // const handleVideoChange = () => {} 

    // const handleDelete = () => {}

    return <Grid container rowSpacing={6} columnSpacing={4} mt={3}>
    <Grid item xs={6}>
      <FormControl fullWidth>
        <FormLabel>Production Resource Name</FormLabel>
        <TextField name="name" value={resource?.name || ''} onChange={handleInputChange} required />
      </FormControl>
    </Grid>
    <Grid item xs={6}>
      <FormControl fullWidth>
        <FormLabel>Description</FormLabel>
        <TextField name="desc" value={resource?.desc || ''} onChange={handleInputChange} required />
      </FormControl>
    </Grid>
    <Grid item xs={6}>
      <Box width="100%">
        <FormLabel>Line</FormLabel>
        <FormControl fullWidth>
          {/* <InputLabel>Line Name</InputLabel> */}
          <Select
            required
            name="line_id"
            onChange={handleLineChange}
            value={resource?.line_id || ''}
          >
            {lines.map((line) => (
              <MenuItem value={line.id} key={line.id}>
                {line.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Grid>
    <Grid item xs={6}>
      <Box width="100%">
        <FormLabel>Schematic Type</FormLabel>
        <FormControl fullWidth>
          {/* <InputLabel>Line Name</InputLabel> */}
          <Select
            required
            name="line_variation_id"
            onChange={handleInputChange}
            value={resource?.line_variation_id || ''}
          >
            {lineVariations.map((line) => (
              <MenuItem value={line.id} key={line.id}>
                {line.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Grid>
    {/* <Grid item xs={12}>
      <Box mt={4}>
          <Stack direction='row' sx={{height: '290px'}} alignItems='center' spacing={3} >
              <Stack sx={{width: '430px'}}>
              <Typography sx={{color: '#2E3646', fontSize: '20px', lineHeight: '40px', fontWeight: 600}}>Video</Typography>
              <Box sx={{height: '243px', border: '1px solid #5F6D7E', borderRadius: '10px', background: !video?.url ? '#E6E9EC' : 'none'}} mt={2} position='relative'>
                  <UploadFile accept='video/*' label='video' height='100%' file={video} handleFileChange={handleVideoChange} handleDeleteVideo={handleDelete} optional />
              </Box>
              </Stack>
              <Stack spacing={2}>
                  <Grid item xs={6} sx={{width: '460px'}} >
                      <FormControl fullWidth>
                          <FormLabel>Name</FormLabel>
                          <TextField name="name" value={video?.name || ''} onChange={handleInputChange} required={!!video?.url} />
                      </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                      <FormControl fullWidth>
                          <FormLabel>Tags</FormLabel>
                          <TextField name="tags" value={video?.tags || ''} onChange={handleInputChange} />
                      </FormControl>
                  </Grid>
              </Stack>
          </Stack>
      </Box>
    </Grid> */}
  </Grid>
}

export default ResourceDetailsForm