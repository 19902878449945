import useApi from "../../../hooks/useAPI";
import muiStyles from "../../../styles/MuiComponentStyles";
import { Box, Typography } from "@mui/material";
import MaterialReactTable from "material-react-table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const WorkerReportTable = () => {
  const user = useSelector((state) => state.user.user);
  const { getData } = useApi();

  const [workerReportsData, setWorkerReportsData] = useState(null);

  const fetchWorkerReports = () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/web/v2`;
      getData(`${url}/worker/report/all/${user.tenant_id}`).then((data) => {
        setWorkerReportsData(data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWorkerReports();
    // eslint-disable-next-line
  }, []);

  const calculateTotalHours = (worker) => {
    let totalHours = 0;
    if (worker.products) {
      worker.products.forEach((product) => {
        if (product.step_data) {
          product.step_data.forEach((step) => {
            // Convert minute_time to hours and add
            totalHours += step.minute_time / 60;
          });
        }
      });
    }
    return totalHours;
  };

  const combineStepData = (data) => {
    return data.reduce((acc, curr) => {
      const existingIndex = acc.findIndex(
        (item) => item.step_name === curr.step_name
      );
      if (existingIndex !== -1) {
        acc[existingIndex].minute_time += curr.minute_time;
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
  };

  const WorkerReportsColum = [
    {
      header: "Worker Code",
      Header: <Box>WorkerCode</Box>,
      accessorKey: "worker_code",
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return (
          <Box
            sx={{
              alignItems: "center",
              Width: "25px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {value}
          </Box>
        );
      },
      size: 180,
    },
    {
      header: "First name",
      accessorKey: "worker_assignments",
      Cell: ({ cell }) => {
        const value = cell.getValue();
        const fname = value?.split("-")[1];
        return (
          <Box
            sx={{
              alignItems: "center",
              Width: "25px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {fname}
          </Box>
        );
      },
      size: 180,
    },
    {
      header: "Last name",
      accessorKey: "worker_assignments",
      Cell: ({ cell }) => {
        const value = cell.getValue();
        const lname = value?.split("-")[2];
        return (
          <Box
            sx={{
              alignItems: "center",
              Width: "25px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {lname}
          </Box>
        );
      },
      size: 180,
    },
    {
      header: "Total Working Time (Hours)",
      accessorKey: "workingHours",
      Cell: ({ row }) => {
        const totalHours = calculateTotalHours(row.original).toFixed(2);

        return (
          <Box
            sx={{
              alignItems: "center",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              paddingLeft: "40px",
            }}
          >
            {totalHours}
          </Box>
        );
      },
      size: 180,
    },
  ];

  const productDetailsColumns = [
    {
      accessorKey: "product_code",
      header: "Product Code",
      size: 100,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return (
          <Box
            sx={{
              alignItems: "center",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {value}
          </Box>
        );
      },
    },
    {
      accessorKey: "product_name",
      header: "Product Name",
      size: 100,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return (
          <Box
            sx={{
              alignItems: "center",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {value}
          </Box>
        );
      },
    },
    {
      accessorKey: "step_data",
      header: "Product Step",
      size: 100,
      Cell: ({ cell }) => {
        const steps = cell.getValue() || [];
        const modifiedStepData = combineStepData(steps);
        return (
          <div>
            {modifiedStepData.map((step, index) => (
              <div key={index}>{step.step_name}</div>
            ))}
          </div>
        );
      },
    },
    {
      accessorKey: "step_data",
      header: "Working Time",
      size: 90,
      Cell: ({ cell }) => {
        const steps = cell.getValue() || [];
        const modifiedStepData = combineStepData(steps);
        return (
          <div>
            {modifiedStepData.map((step, index) => (
              <div key={index}>{(step.minute_time / 60).toFixed(2)}</div>
            ))}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Typography sx={muiStyles.pageTitle} mb={5} mt={5}>
        Worker Reports
      </Typography>
      <MaterialReactTable
        title="Worker Reports"
        columns={WorkerReportsColum}
        data={workerReportsData || []}
        enableDensityToggle={false}
        initialState={{
          density: "spacious",
        }}
        enableFullScreenToggle={false}
        enableColumnFilters={false}
        enableHiding={false}
        renderDetailPanel={({ row }) => {
          return (
            <MaterialReactTable
              columns={productDetailsColumns}
              data={row.original.products}
              enablePagination={false}
              enableDensityToggle={false}
              initialState={{
                density: "comfort",
              }}
              enableFullScreenToggle={false}
              enableColumnFilters={false}
              enableHiding={false}
            />
          );
        }}
      />
    </>
  );
};

export default WorkerReportTable;
