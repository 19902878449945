import { setZones, setPage, setRowsPerPage } from "../../features/zones/zonesSlice";
import useApi from "../../hooks/useAPI";
import muiStyles from "../../styles/MuiComponentStyles";
import { CosmeticaTable, CosmeticaModal, Loading } from "../UIComponents";
import ZoneForm from "./ZonesForm";
import { Box, Stack, Typography, Button } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { formatDate } from "../../utils/helperFunctions";
import { useCssStateHook } from "../UIComponents/ComonCss";

const Zones = () => {
  const data = useSelector((state) => state.zones.items);
  const rowsPerPage = useSelector(state => state.zones.rowsPerPage);
  const page = useSelector(state => state.zones.page)
  const totalZones = useSelector((state) => state.zones.totalItems)
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [zone, setZone] = React.useState({ name: "", time_zone: "" });
  const [modalTitle, setModalTitle] = React.useState("New Zone");
  const { loading, getData, postData, patchData, deleteData } = useApi();
  const zoneUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/zone`;
  const {  themeCssButton,themeCssHeader} = useCssStateHook();

  React.useEffect(() => {
    if (user.tenant_id) {
      fetchZones();
    }
    // eslint-disable-next-line
  }, [page, rowsPerPage, user.tenant_id]);

  const fetchZones = () => {
    try {
      const response = getData(`${zoneUrl}/all/${user.tenant_id}/page/${page}/count/${rowsPerPage}`);
      response.then((data) => {
        dispatch(setZones(data || []));
      });
    } catch (error) {
      console.log(error);
    }
  };


  const fields = ["Name", "Timezone", "Created at"];
  const rows = data.map((row) => {
    const { id, name, time_zone, created_at } = row;
    return { id, name, time_zone, created_at: formatDate(created_at) };
  });

  const handleSubmit = (e, _zone) => {
    _zone = { ..._zone, tenant_id: user?.tenant_id }; // TODO
    // _zone.timeZone = _zone.time_zone;
    // delete _zone.time_zone;
    e.preventDefault();
    try {
      if (modalTitle === "New Zone") {
        postData(`${zoneUrl}/create`, _zone);
      } else if (modalTitle === "Edit Zone") {
        patchData(`${zoneUrl}/update`, _zone);
      }
      fetchZones();
    } catch (error) {
      console.log(error);
    }
    e.target.reset();
    setShowModal(false);
    setZone({ name: "", time_zone: "" });
  };

  const handleEdit = (id) => {
    const _zone = data.find((d) => d.id === id);
    setZone({ id, name: _zone.name, time_zone: _zone.time_zone });
    setModalTitle("Edit Zone");
    setShowModal(true);
  };

  const handleDelete = (id) => {
    try {
      deleteData(`${zoneUrl}/delete/${id}`);
      fetchZones();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage))
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    dispatch(setRowsPerPage(newRowsPerPage))
  }

  return loading ? (
    <Loading />
  ) : (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={5}
      >
        <Typography sx={muiStyles.pageTitle}>Zones</Typography>
        <Button
          variant="contained"
          onClick={() => {
            setShowModal(true);
            setModalTitle("New Zone");
          }}
          // sx={muiStyles.primaryButton}
          sx={themeCssButton}
        >
          Add Zone
        </Button>
      </Stack>
      <Box sx={{ mt: 4 }}>
        <CosmeticaTable
          fields={fields}
          rows={rows}
          isDelete
          isEdit
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          rowsCount={totalZones}
          page={page}
          rowsPerPage={rowsPerPage}
          isHeadercss={themeCssHeader}
        />
      </Box>
      <CosmeticaModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        title={modalTitle}
       
      >
        <ZoneForm
          handleClose={() => setShowModal(false)}
          handleSubmit={handleSubmit}
          data={zone}
          isHeadercss={themeCssButton}
        />
      </CosmeticaModal>
    </Box>
  );
};

export default Zones;
