const data = {
  work_order_summary_id: 215,
  quality_doc: {
    standard: "na",
    planned_qty: 50000,
    sampling_plan: "y",
    area: "Norden Line",
    severity: "no",
    level: "i",
    sample_size: 80,
  },
  aql: [
    {
      criteria: "0-1",
    },
    {
      criteria: "1-2",
    },
    {
      criteria: "2-3",
    },
    {
      criteria: "3-4",
    },
    {
      criteria: "7-8",
    },
  ],
  filling: true,
  packing: true,
  inspector_id: 1,
  inspector_name: "Pavan",
  work_order: {
    work_order: "865359/1",
    customer: "136",
    qty: 9438,
    product_name: "F136-82003",
  },
  readings: [
    {
      time: "2024-01-03 01:09:31.351723",
      critical1: "1/45",
      critical2: "1/45",
      critical3: "1/45",
      critical4: "1/45",
      critical5: "1/45",
      liquid61: "2/50",
      liquid62: "2/50",
      liquid63: "2/50",
      liquid64: "2/50",
      liquid65: "2/50",
      liquid66: "2/50",
      liquid67: "2/50",
      liquid68: "2/50",
      powder71: "3/55",
      powder72: "3/55",
      powder73: "3/55",
      powder74: "3/55",
      powder75: "3/55",
      powder76: "3/55",
      powder77: "3/55",
      powder78: "3/55",
      powder79: "3/55",
      label8: "4/60",
      label9: "4/60",
      label10: "4/60",
      label11: "4/60",
      label12: "4/60",
      label13: "4/60",
      label14: "4/60",
      shipper15: "5/65",
      shipper16: "5/65",
      shipper17: "5/65",
      shipper18: "5/65",
      shipper19: "5/65",
      shipper20: "5/65",
      shipper21: "5/65",
    },
    {
      time: "2024-01-03 01:15:24.595501",
      critical1: "1/10",
      critical2: "1/10",
      critical3: "1/10",
      critical4: "1/10",
      critical5: "1/10",
      liquid61: "2/15",
      liquid62: "2/15",
      liquid63: "2/15",
      liquid64: "2/15",
      liquid65: "2/15",
      liquid66: "2/15",
      liquid67: "2/15",
      liquid68: "2/15",
      powder71: "3/20",
      powder72: "3/20",
      powder73: "3/20",
      powder74: "3/20",
      powder75: "3/20",
      powder76: "3/20",
      powder77: "3/20",
      powder78: "3/20",
      powder79: "3/20",
      label8: "4/25",
      label9: "4/25",
      label10: "4/25",
      label11: "4/25",
      label12: "4/25",
      label13: "4/25",
      label14: "4/25",
      shipper15: "5/30",
      shipper16: "5/30",
      shipper17: "5/30",
      shipper18: "5/30",
      shipper19: "5/30",
      shipper20: "5/30",
      shipper21: "5/30",
    },
    {
      time: "2024-01-03 01:17:01.557878",
      critical1: "/",
      critical2: "/",
      critical3: "/",
      critical4: "/",
      critical5: "/",
      liquid61: "/",
      liquid62: "/",
      liquid63: "/",
      liquid64: "/",
      liquid65: "/",
      liquid66: "/",
      liquid67: "/",
      liquid68: "/",
      powder71: "/",
      powder72: "/",
      powder73: "/",
      powder74: "/",
      powder75: "/",
      powder76: "/",
      powder77: "/",
      powder78: "/",
      powder79: "/",
      label8: "/",
      label9: "/",
      label10: "/",
      label11: "/",
      label12: "/",
      label13: "/",
      label14: "/",
      shipper15: "/",
      shipper16: "/",
      shipper17: "/",
      shipper18: "/",
      shipper19: "/",
      shipper20: "/",
      shipper21: "/",
    },
    {
      time: "2024-01-03 01:21:41.409491",
      critical1: "/",
      critical2: "/",
      critical3: "/",
      critical4: "/",
      critical5: "/",
      liquid61: "/",
      liquid62: "/",
      liquid63: "/",
      liquid64: "/",
      liquid65: "/",
      liquid66: "/",
      liquid67: "/",
      liquid68: "/",
      powder71: "/",
      powder72: "/",
      powder73: "/",
      powder74: "/",
      powder75: "/",
      powder76: "/",
      powder77: "/",
      powder78: "/",
      powder79: "/",
      label8: "/",
      label9: "/",
      label10: "/",
      label11: "/",
      label12: "/",
      label13: "/",
      label14: "/",
      shipper15: "/",
      shipper16: "/",
      shipper17: "/",
      shipper18: "/",
      shipper19: "/",
      shipper20: "/",
      shipper21: "/",
    },
    {
      time: "2024-01-03 01:23:00.883514",
      critical1: "/",
      critical2: "/",
      critical3: "/",
      critical4: "/",
      critical5: "/",
      liquid61: "/",
      liquid62: "/",
      liquid63: "/",
      liquid64: "/",
      liquid65: "/",
      liquid66: "/",
      liquid67: "/",
      liquid68: "/",
      powder71: "/",
      powder72: "/",
      powder73: "/",
      powder74: "/",
      powder75: "/",
      powder76: "/",
      powder77: "/",
      powder78: "/",
      powder79: "/",
      label8: "/",
      label9: "/",
      label10: "/",
      label11: "/",
      label12: "/",
      label13: "/",
      label14: "/",
      shipper15: "/",
      shipper16: "/",
      shipper17: "/",
      shipper18: "/",
      shipper19: "/",
      shipper20: "/",
      shipper21: "/",
    },
  ],
};

export default data