import React from "react"
import { Backdrop, CircularProgress, Typography } from "@mui/material"

const Loading = ({ description='Loading...', open=true, children, isCustom=false }) => {
    return <Backdrop open={open} style={{ zIndex: 9999, color: '#fff' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {
          children || <>
            <CircularProgress color="inherit" />
            <Typography variant="body1" color="textSecondary" style={{ marginTop: '16px', color: '#fff' }}>
              {description}
            </Typography>
          </>
        }
      </div>
    </Backdrop>
}

export default Loading