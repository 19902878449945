export const formatToUTC = (d) => {
  // Initialize the date object
  const date = new Date(d); // "2024-02-15"

  // Get the day, month, and year from the date
  // Note: getUTCDate(), getUTCMonth(), and getUTCFullYear() are used to ensure the date is in UTC
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // getUTCMonth() returns 0-11, so add 1 to get 1-12
  const year = date.getUTCFullYear();

  // Format the day and month to ensure two digits
  const formattedDay = day < 10 ? "0" + day : day;
  const formattedMonth = month < 10 ? "0" + month : month;

  // Combine the parts into a DD/MM/YYYY format
  const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

  console.log(formattedDate);
  return formattedDate;
};
