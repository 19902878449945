import { addQuestion } from "../../../../features/ProductionResourceSteps/stepsSlice";
import muiStyles from "../../../../styles/MuiComponentStyles";
import useApi from "../../../../hooks/useAPI";
import Question from "./Question";
import { Box, Typography, Button } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

const Questions = ({ fetchSteps }) => {
  const dispatch = useDispatch();
  const questionURL =`${process.env.REACT_APP_BASE_URL}/web/v2/production/step/question`;

  const currentStep = useSelector((state) => state.steps.currentStep);
  const isStepEditable = useSelector((state) => state.steps.isStepEditable);
  const questions = currentStep?.questions || [];
  const [disabled, setDisabled] = React.useState(false);
  const {deleteData} = useApi()

  const handleDelete = async (id) => {
    deleteData(`${questionURL}/delete/${id}`).then((res) => {
        fetchSteps?.();
    }).catch((err) => {
        console.log("Error ", err)
    })
  
}

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (questions.length > 0) {
      questions.forEach((question) => {
        const isQuestionText = question.question.trim() !== "";
        const isOptionsEmpty = question.options.every(
          (obj) => obj.option.trim() !== ""
        );
        const isOptionChecked = question.options.some((obj) => obj.is_correct);
        if (isQuestionText && isOptionsEmpty && isOptionChecked) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      });
    } else {
      setDisabled(false);
    }
  });

  const handleAddQuestion = () => {
    dispatch(addQuestion());
  };

  return (
    <Box width="100%">
      <Typography sx={muiStyles.pageTitle}>
        Production Resource Question
      </Typography>
      {questions.map((question, qIndex) => {
        return <Question key={qIndex} qIndex={qIndex} question={question} handleDelete={handleDelete}/>;
      })}
      <Box mt={3}>
        <Button
          variant="contained"
          sx={muiStyles.primaryButton}
          onClick={handleAddQuestion}
          disabled={disabled || !isStepEditable}
        >
          Add New Question
        </Button>
      </Box>
    </Box>
  );
};

export default Questions;
