import { setLine } from "../../features/line/lineSlice";
import { setProducts } from "../../features/products/productsSlice";
import {
  setCurrentWeek,
  setCurrentYear,
  setPriorities,
  setWorkOrders,
  setWorkOrderStatus,
  resetState,
} from "../../features/scheduleJob/scheduleJobSlice";
import useApi from "../../hooks/useAPI";
import muiStyles from "../../styles/MuiComponentStyles";
import { getWeeksInYear, getWeekNumber } from "../../utils/helperFunctions";
import Calender from "./Calender";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Stack,
  Box,
  Typography,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

const ScheduleJobs = () => {
  const dispatch = useDispatch();
  const { getData } = useApi();
  const user = useSelector((state) => state.user.user);
  const [filterWeek, setFilterWeek] = React.useState("all-weeks");
  const currentWeek = useSelector((state) => state.scheduleJobs.currentWeek);
  const currentYear = useSelector((state) => state.scheduleJobs.currentYear);

  const url = `${process.env.REACT_APP_BASE_URL}/web/v2/work/order`;
  const products = useSelector((state) => state.products.allItems);
  const lines = useSelector((state) => state.line.items);
  const priorities = useSelector((state) => state.scheduleJobs.priorities);
  const workOrderStatus = useSelector(
    (state) => state.scheduleJobs.workOrderStatus
  );
  const productUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/product`;
  const lineURL = `${process.env.REACT_APP_BASE_URL}/web/v2/line`;

  React.useEffect(() => {
    return () => {
      dispatch(resetState());
      dispatch(setCurrentWeek(getWeekNumber()));
      dispatch(setCurrentYear(new Date().getFullYear()));
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (user.tenant_id && user.zone_id) {
      if (priorities.length < 1) {
        fetchPriorities();
      }
      if (workOrderStatus.length < 1) {
        fetchWorkOrderStatus();
      }
      if (products.length < 1) {
        fetchProducts();
      }
      if (lines.length < 1) {
        fetchLines();
      }
      // fetchWorkOrdersSummary();
      // fetchHuddle();
    }
    // eslint-disable-next-line
  }, [user.tenant_id]);

  React.useEffect(() => {
    if (user.tenant_id && user.zone_id) {
      fetchWorkOrders();
    }
    // eslint-disable-next-line
  }, [currentWeek, currentYear, user.tenant_id]);

  const fetchWorkOrders = () => {
    try {
      getData(
        `${url}/all/${user.tenant_id}/${user.zone_id}/${currentWeek}/${currentYear}`
      ).then((data) => {
        data.sort((a, b) => +a.week - +b.week);
        console.log('WORK ORDERS', data[0].work_order_by_week)
        data = data.map(week => {
          week.work_order_by_week = week.work_order_by_week.map(item => {
            // console.log('DATE', item.start_date)
            item.start_date = new Date(item.start_date).toISOString()
            item.end_date = new Date(item.end_date).toISOString()
            return item
          })
          return week
        })
        console.log('WORK ORDERS', data)
        dispatch(setWorkOrders(data || []));
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchWorkOrdersSummary = () => {
  //   try {
  //     getData(
  //       `${url}/summaries/${user.tenant_id}/${
  //         user.zone_id
  //       }/page/${1}/count/${9999}`
  //     ).then((data) => {
  //       console.log("Work Order Summary", data);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const fetchHuddle = () => {
  //   try {
  //     getData(
  //       `${process.env.REACT_APP_BASE_URL}/web/v2/huddle/report/${195}`
  //     ).then((data) => {
  //       console.log("Huddle", data);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchPriorities = () => {
    try {
      getData(`${url}/priority/all/${user.tenant_id}`).then((data) => {
        dispatch(setPriorities(data));
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchWorkOrderStatus = () => {
    try {
      getData(`${url}/status/all`).then((data) => {
        dispatch(setWorkOrderStatus(data));
      });
    } catch (error) {
      console.log(error);
    }
  };

  function fetchProducts() {
    try {
      const response = getData(
        `${productUrl}/all/${user.tenant_id}/${user.zone_id}`
      );
      response.then((data) => {
        dispatch(setProducts(data || []));
      });
    } catch (error) {
      console.log(error);
    }
  }

  function fetchLines() {
    try {
      const response = getData(
        `${lineURL}/all/${user.zone_id}/page/${1}/count/${999}`
      );
      response.then((data) => {
        dispatch(setLine(data || []));
      });
    } catch (error) {
      console.log(error);
    }
  }

  const updateWeek = (direction) => {
    let totalWeeks = getWeeksInYear(currentYear);
    if (direction === "next") {
      let newWeek = currentWeek + 4;
      if (newWeek > totalWeeks) {
        dispatch(setCurrentYear(currentYear + 1));
        dispatch(setCurrentWeek(newWeek - totalWeeks));
      } else {
        dispatch(setCurrentWeek(newWeek));
      }
    } else if (direction === "prev") {
      let newWeek = currentWeek - 4;
      if (newWeek < 1) {
        dispatch(setCurrentYear(currentYear - 1));
        dispatch(setCurrentWeek(totalWeeks + newWeek));
      } else {
        dispatch(setCurrentWeek(newWeek));
      }
    }
  };

  return (
    <>
      <Box my={3} width="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        ></Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={muiStyles.pageTitle}>Scheduled Jobs</Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={4}
          >
            <Select
              value={filterWeek}
              sx={{
                width: "150px",
                height: "40px",
                padding: "6px 12px",
                borderRadius: "8px",
                borderColor: "#E4E5EB",
              }}
              onChange={(e) => setFilterWeek(e.target.value)}
            >
              <MenuItem value="all-weeks">All Weeks</MenuItem>
            </Select>
            <Stack direction="row" alignItems="center">
              <IconButton onClick={() => updateWeek("prev")}>
                <ChevronLeft />
              </IconButton>
              <IconButton onClick={() => updateWeek("next")}>
                <ChevronRight />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
        {/* Calender */}
        <Calender fetchWorkOrders={fetchWorkOrders} />
      </Box>
    </>
  );
};

export default ScheduleJobs;
