import React from "react"
import { CosmeticaTable, Loading } from "../UIComponents";
import {
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../hooks/useAPI";
import { useEffect } from "react";
import { setPermission } from "../../features/permission/permissionSlice";
import { formatDateTime } from "../../utils/helperFunctions";
import { useCssStateHook } from "../UIComponents/ComonCss";


const Permission = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {loading, getData,deleteData } = useApi();
  const user = useSelector((state) => state.user.user);
  const permissionData = useSelector((state) => state.permission.items);
  const permissionURL = `${process.env.REACT_APP_BASE_URL}/web/v2/permission`
  const {  themeCssButton,themeCssHeader} = useCssStateHook();
  useEffect(() => {
    fetchPermission();
    // eslint-disable-next-line
  }, [user]);
  const fields = ["Permissions", "Created at"];
  const getRows = (data) => data.map(row => {
    const { id, name,created_at, permission, } = row
    return { id, permissionName : {name, permission}, created_at:formatDateTime(created_at), }
})
  const fetchPermission = () => {
    if(user.tenant_id !== undefined){
        try {
          const response = getData(
            `${permissionURL}/all/${user.tenant_id}`
          );
          response.then((_data) => {
            dispatch(setPermission(_data || []));
          });
        } catch (error) {
          console.log(error);
        }
    }
  };
  const handleEdit = (id) => {
   navigate(`/admin/permission/edit-permission/${id}`)
}
const handleDelete = async (id) => {
    deleteData(`${permissionURL}/delete/${id}`).then((res) => {
        fetchPermission()
    }).catch((err) => {
        console.log("Error ", err)
    })
  
}
const handlePageChange = () => {
 
}
const handleRowsPerPageChange = () => {

}

  return loading ? (
    <Loading/>
  ) : (
    <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={5}
        >
          <Typography sx={{fontWeight:"bold", fontSize:"20px"}}>Permission Templates</Typography>
          <Button
            variant="contained"
            component={NavLink}
            to="add-permission"
            // sx={muiStyles.primaryButton}
            sx={themeCssButton}
          >
            Add Permission
          </Button>
        </Stack>
      <Box sx={{ mt: 4 }}>
       
          <>
            <CosmeticaTable
              fields={fields}
              rows={getRows(permissionData)}
              isDelete
              isEdit
              isRowCollaps={true}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              ShowAccorrdion
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
              rowsCount={""}
              page={1}
              rowsPerPage={10}
              isHeadercss={themeCssHeader}
            />
  
          </>
      
      </Box>
    </Box>
  );
};

export default Permission;
