import { setLine } from "../../features/line/lineSlice";
import { setProducts } from "../../features/products/productsSlice";
import {
  setPriorities,
  setWorkOrderStatus,
} from "../../features/scheduleJob/scheduleJobSlice";
import useApi from "../../hooks/useAPI";
import muiStyles from "../../styles/MuiComponentStyles";
import { ModalFormTheme } from "../../styles/MuiThemes";
import { formatDate, isTodayGreaterThanGivenDate } from "../../utils/helperFunctions";
import { East } from "@mui/icons-material";
import {
  Box,
  ThemeProvider,
  FormGroup,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Select,
  Divider,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

const AddEditScheduleJob = ({
  job,
  weekNumber,
  setJob,
  handleSubmit,
  closeModal,
  start_date,
  end_date,
}) => {
  const { getData } = useApi();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.allItems);
  const lines = useSelector((state) => state.line.items);
  const [lineVariations, setLineVariations] = React.useState([]);
  const [productionResources, setProductionResources] = React.useState([]);
  const priorities = useSelector((state) => state.scheduleJobs.priorities);
  const workOrderStatus = useSelector(
    (state) => state.scheduleJobs.workOrderStatus
  );
  const user = useSelector((state) => state.user.user);
  const productUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/product`;
  const lineURL = `${process.env.REACT_APP_BASE_URL}/web/v2/line`;
  const workOrderUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/work/order`;
  const productionResourceUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/production/resource`;
  const [product, setProduct] = React.useState(
    products.find((product) => product?.id === job?.product_id) || null
  );
  const today = new Date().toISOString().split('T')[0]
  const startDateGraterThanToday = isTodayGreaterThanGivenDate(start_date)

  React.useEffect(() => {
    if (products.length < 1) {
      fetchProducts();
    }
    if (lines.length < 1) {
      fetchLines();
    }
    if (priorities.length < 1) {
      fetchPriorities();
    }
    if (workOrderStatus.length < 1) {
      fetchWorkOrderStatus();
    }
    if (job?.product_id) {
      fetchResources(job?.product_id);
    }
    // eslint-disable-next-line
  }, []);

  const fetchPriorities = () => {
    try {
      getData(`${workOrderUrl}/priority/all/${user.tenant_id}`).then((data) => {
        dispatch(setPriorities(data));
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchWorkOrderStatus = () => {
    try {
      getData(`${workOrderUrl}/status/all`).then((data) => {
        dispatch(setWorkOrderStatus(data));
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchResources = async (productId) => {
    try {
      const data = await getData(`${productionResourceUrl}/all/${productId}`);
      setProductionResources(data || []);
      if (job?.production_resource_id) {
        const resource = data?.find(resource => resource.id === job?.production_resource_id)
        fetchLineVariations(resource?.line_id)
        setJob(prev => {
          return {...prev, line_id: resource?.line_id || 0, line_variation_id: resource?.line_variation_id || 0}
        })
      }
    } catch (error) {
      console.log(error);
    }
  };

  function fetchProducts() {
    try {
      const response = getData(
        `${productUrl}/all/${user.tenant_id}/${user.zone_id}`
      );
      response.then((data) => {
        dispatch(setProducts(data || []));
      });
    } catch (error) {
      console.log(error);
    }
  }

  function fetchLines() {
    try {
      const response = getData(
        `${lineURL}/all/${user.zone_id}/page/${1}/count/${999}`
      );
      response.then((data) => {
        dispatch(setLine(data || []));
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchLineVariations(id) {
    try {
      const data = await getData(`${lineURL}/variation/all/${id}/page/${1}/count/${999}`);
      setLineVariations(data.variations || []);
    } catch (error) {
      console.log(error);
    }
  }

  const handleProductChange = (e) => {
    setJob((prev) => {
      const product_id = e.target.value;
      const _product = products.find((product) => product.id === product_id);
      setProduct(_product);
      setProductionResources([])
      setLineVariations([])
      return { ...prev, product_id };
    });
    fetchResources(e.target.value);
  };

  const handleProductionResorceChange = async (e) => {
    const resource = productionResources?.find(resource => resource?.id === +e.target.value)
    fetchLineVariations(resource.line_id)
    setJob(prev => {
      return {...prev, line_id: resource?.line_id, line_variation_id: resource?.line_variation_id }
    })
    handleInputChange(e)
  }

  // const handleLineChange = (e) => {
  //   setJob((prev) => {
  //     const line_id = e.target.value;
  //     fetchLineVariations(line_id);
  //     return { ...prev, line_id };
  //   });
  // };

  const handleInputChange = (e) => {
    setJob((prev) => {
      if (e.target.name === "quantity") {
        return { ...prev, quantity: +e.target.value };
      } else if (e.target.name === "start_date") {
        return { ...prev, start_date: new Date(e.target.value).toISOString() };
      } else if (e.target.name === "end_date") {
        return { ...prev, end_date: new Date(e.target.value).toISOString() };
      } else {
        return { ...prev, [e.target.name]: e.target.value };
      }
    });
  };

  return (
      <ThemeProvider theme={ModalFormTheme}>
        <Typography
          sx={{ fontSize: "18px", fontWeight: 400, lineHeight: "40px" }}
        >
          <strong>Week - {weekNumber}</strong> (
          {formatDate(new Date(start_date), "ddMMM")} -{" "}
          {formatDate(new Date(end_date), "ddMMM")})
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              mb={2}
            >
              <Box width="100%">
                <FormLabel>Product Code</FormLabel>
                <FormControl>
                  <Select
                    required
                    value={job.product_id || ""}
                    onChange={handleProductChange}
                    name="product_id"
                    disabled={!!job?.id}
                  >
                    {products.map((product) => (
                      <MenuItem value={product.id} key={product.id}>
                        {product.product_code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <TextField
                  variant="outlined"
                  required
                  value={product?.desc || ""}
                />
              </FormControl>
              <FormControl>
                <FormLabel>MFPS Number</FormLabel>
                <TextField
                  variant="outlined"
                  required
                  disabled
                  value={product?.specification || ""}
                  name="specifiction"
                />
              </FormControl>
            </Stack>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              my={2}
            >
              <FormControl>
                <FormLabel>Revision</FormLabel>
                <TextField
                  variant="outlined"
                  required
                  disabled
                  value={product?.specification_revision || ""}
                />
              </FormControl>
              <Box width="100%">
                <FormLabel>Production Resource</FormLabel>
                <FormControl disabled={productionResources.length < 1}>
                  <Select
                    required
                    value={job.production_resource_id || ""}
                    onChange={handleProductionResorceChange}
                    name="production_resource_id"
                    disabled={!!job?.id}
                  >
                    {productionResources.map((resource) => (
                      <MenuItem value={resource.id} key={resource.id}>
                        {resource.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box width="100%">
                <FormLabel>Priority</FormLabel>
                <FormControl>
                  {/* <InputLabel>Priority</InputLabel> */}
                  <Select
                    required
                    value={job.priority_id || ""}
                    onChange={handleInputChange}
                    name="priority_id"
                  >
                    {priorities.map((priority) => (
                      <MenuItem value={priority.id} key={priority.id}>
                        {priority.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Stack>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              my={2}
            >
              <FormControl>
                <FormLabel>Customer Number</FormLabel>
                <TextField
                  variant="outlined"
                  required
                  name="customer_reference"
                  onChange={handleInputChange}
                  value={job.customer_reference || ""}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Work Order Number (W/O)</FormLabel>
                <TextField
                  variant="outlined"
                  required
                  name="work_order"
                  onChange={handleInputChange}
                  value={job.work_order || ""}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Quantity</FormLabel>
                <TextField
                  type={"number"}
                  variant="outlined"
                  required
                  name="quantity"
                  onChange={handleInputChange}
                  value={job.quantity || ""}
                />
              </FormControl>
            </Stack>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              my={2}
            >
              {/* <FormControl>
                <FormLabel>Line Name</FormLabel>
                <TextField
                  variant="outlined"
                  required
                  name="line_id"
                  onChange={handleInputChange}
                  value={job?.line_id || ""}
                  disabled
                />
              </FormControl>
              <FormControl>
                <FormLabel>Schamatic Type</FormLabel>
                <TextField
                  variant="outlined"
                  required
                  name="line_variation_id"
                  onChange={handleInputChange}
                  value={job?.line_variation_id || ""}
                  disabled
                />
              </FormControl> */}
              <Box width="100%">
                <FormLabel>Line Name</FormLabel>
                <FormControl>
                  <Select
                    required
                    value={job.line_id || ""}
                    // onChange={handleLineChange}
                    name="line_id"
                    disabled
                  >
                    {lines.map((line) => (
                      <MenuItem value={line.id} key={line.id}>
                        {line.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box width="100%">
                <FormLabel>Schamatic Type</FormLabel>
                <FormControl disabled={lineVariations.length < 1}>
                  <Select
                    required
                    value={job.line_variation_id || ""}
                    name="line_variation_id"
                    // onChange={handleInputChange}
                    disabled
                  >
                    {lineVariations.map((lineVariation) => (
                      <MenuItem value={lineVariation.id} key={lineVariation.id}>
                        {lineVariation.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <FormControl>
                <FormLabel>Note</FormLabel>
                <TextField
                  variant="outlined"
                  value={job.note || ""}
                  name="note"
                  onChange={handleInputChange}
                />
              </FormControl>
            </Stack>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              my={2}
            >
              <FormControl>
                <FormLabel>Start Date</FormLabel>
                <TextField
                  type="date"
                  variant="outlined"
                  required
                  value={job.start_date?.split("T")[0] || ""}
                  name="start_date"
                  onChange={handleInputChange}
                  disabled={!!job?.id}
                  inputProps={{min: !startDateGraterThanToday ? start_date?.split('T')[0] : today}}
                  // inputProps={{min: !startDateGraterThanToday ? start_date?.split('T')[0] : today, max: end_date?.split('T')[0]}}
                />
              </FormControl>
              <FormControl>
                <FormLabel>End Date</FormLabel>
                <TextField
                  type="date"
                  variant="outlined"
                  required
                  value={job.end_date?.split("T")[0] || ""}
                  name="end_date"
                  onChange={handleInputChange}
                  disabled={!job?.start_date}
                  inputProps={{min: job?.start_date?.split('T')[0]}}
                />
              </FormControl>
              {/* {job?.id && (
                <Box width="100%">
                  <FormLabel>Work Order Status</FormLabel>
                  <FormControl>
                    <Select
                      required
                      value={job.work_order_status_id || ""}
                      name="work_order_status_id"
                      onChange={handleInputChange}
                    >
                      {workOrderStatus.map((status) => (
                        <MenuItem value={status.id} key={status.id}>
                          {status.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )} */}
            </Stack>
          </FormGroup>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            mt={2}
          >
            <Button
              type="reset"
              variant="text"
              size="large"
              sx={muiStyles.secondaryButton}
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={muiStyles.primaryButton}
              endIcon={<East />}
            >
              Submit
            </Button>
          </Stack>
        </form>
      </ThemeProvider>
  );
};

export default AddEditScheduleJob;
