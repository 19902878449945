import React from "react"
import {Button, Typography, Box, Stack, Modal, Card, createTheme, ThemeProvider, IconButton} from '@mui/material'
import { East, Close } from '@mui/icons-material'
import muiStyles from '../../styles/MuiComponentStyles'
import deleteLogo from '../../images/delete-logo.png'

const ModalTheme = createTheme({
    typography: {
        body1: {
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'center',
            fontSize: '16px'
        }, 
        subtitle1: {
            color: '#4C4C4C',
            fontWeight: 600,
            margin: '24px 0',
            lineHeight: '24px',
            textAlign: 'center',
            fontSize: '24px',
        }
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 450,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    padding: '20px',
                    borderRadius: "10px",
                }
            }
        }
    }
})



const DeleteModal = ({showModal, handleDelete, handleClose, title , isHeadercss}) => {
    return <>
    <ThemeProvider theme={ ModalTheme }>
    <Modal open={showModal} onClose={handleClose}>
      <Card>
        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <IconButton onClick={handleClose}>
            <Close />
            </IconButton>
        </Box>
        <Box sx={{textAlign: 'center'}}>
        <img src={deleteLogo} width="120px" height="120px" alt="delete logo" sx={{padding: '15px'}} />
        <Typography variant='subtitle1'>Are you sure?</Typography>
        <Typography variant='body1'>Do you really want to delete this {title?.toLocaleLowerCase() || 'record'}?</Typography>
        <Typography variant='body1'>This process cannot be undone</Typography>
        </Box>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={6}>
          <Button type="button" variant="text" size="large" sx={muiStyles.secondaryButton} onClick={handleClose}>Cancel</Button>
            <Button 
            type="button"
             variant="contained" 
             size="large"
              onClick={handleDelete}
            //    sx={muiStyles.primaryButton} 
            sx={ isHeadercss || muiStyles.primaryButton}
               endIcon={<East />}>Confirm</Button>
        </Stack>
      </Card>
      </Modal>
      </ThemeProvider>
    </>
}

export default DeleteModal