import React from "react"
import { Close } from "@mui/icons-material";
import {
  Typography,
  Modal,
  Card,
  Box,
  createTheme,
  ThemeProvider,
  IconButton,
} from "@mui/material";

const ModalTheme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: '50%',
          maxWidth: 'auto',
          bgcolor: "background.paper",
          boxShadow: 24,
          padding: "16px 16px 16px 16px",
          borderRadius: "10px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          marginBottom: "16px",
        },
      },
    },
  },
});

const CosmeticaModal = ({ showModal, closeModal, title, children, width=600 }) => {
  return (
    <ThemeProvider theme={ModalTheme}>
      <Modal open={showModal} onClose={closeModal}>
        <Card width={width} >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </Box>
          <Typography variant="h5">{title}</Typography>
          <Box>{children}</Box>
        </Card>
      </Modal>
    </ThemeProvider>
  );
};

export default CosmeticaModal;
