import { fetchUserDetails } from "../features/user/userAPI";
import { setUser } from "../features/user/userSlice";
import { useCssStateHook } from "./UIComponents/ComonCss";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

const settings = [
  { label: "Profile", href: "/settings/profile" },
  { label: "Tenants", href: "/settings/tenants" },
  // { label: "Account", href: "/settings/account" },
  // { label: "Dashboard", href: "/settings/dashboard" }
];
const menuItems = [
  {
    label: "Reports",
    href: "",
    subMenu: [
      { label: "Summary Reports", href: "/admin/reports" },
      { label: "Worker Reports", href: "admin/worker-reports" },
    ],
  },
  { label: "Schedule Job", href: "/schedule-jobs", subMenu: [] },
  { label: "Products", href: "/products", subMenu: [] },
  {
    label: "Admin",
    href: "",
    subMenu: [
      { label: "User", href: "/admin/user" },
      { label: "Role", href: "/admin/role" },
      { label: "Lines", href: "/admin/line" },
      { label: "Zones", href: "/admin/zones" },
      { label: "Workers", href: "/admin/workers" },
      { label: "Shift", href: "/admin/shift" },
      { label: "Skill", href: "/admin/skill" },
      { label: "Permission Template", href: "/admin/permission" },
      { label: "Product Stages", href: "/admin/product-stages" },
    ],
  },
  {
    label: "Devices",
    href: "",
    subMenu: [
      { label: "Management Devices", href: "/device/Management-devices" },
      { label: "Worker Devices", href: "/device/Worker-devices" },
    ],
  },
];

const MainNav = () => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElAdmin, setAnchorElAdmin] = React.useState(null);
  const [clickedMenu, setClickedMenu] = React.useState();
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
  const dispatch = useDispatch();
  const { themeCssButton } = useCssStateHook();

  const currentUser = useSelector((state) => state.user.user);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenMainMenu = (event, menu) => {
    setClickedMenu(menu.label);
    setAnchorElAdmin(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleClearStore = () => {
    localStorage.clear();
  };
  const handleCloseMainMenu = () => {
    setAnchorElAdmin(null);
  };

  React.useEffect(() => {
    if (!isAuthenticated && window.location.pathname !== "/") {
      window.location.href = "/";
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (isAuthenticated && Object.keys(currentUser).length === 0) {
      fetchUserDetails().then((data) => {
        console.log("---- 99999 ", data);
        dispatch(setUser(data || {}));
      });
    }
    // eslint-disable-next-line
  }, []);
  const userInitials = () => {
    let initials = "";
    if (currentUser.first_name) {
      initials += currentUser.first_name.at(0).toUpperCase();
    }
    if (currentUser.last_name) {
      initials += currentUser.last_name.at(0).toUpperCase();
    }
    return initials;
  };
  return (
    <>
      {isAuthenticated ? (
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <>
            {menuItems?.map((menuItem, index) => {
              return (
                <>
                  {menuItem?.subMenu.length > 0 ? (
                    <Box sx={{ flexGrow: 0, px: 1 }} key={index}>
                      <Button
                        key={menuItem.label}
                        onClick={(e) => handleOpenMainMenu(e, menuItem)}
                        disableRipple
                        sx={{
                          my: 2,
                          display: "block",
                          px: 2,
                          color: "#5F6D7E",
                          textTransform: "capitalize",
                        }}
                      >
                        {menuItem.label}
                      </Button>
                      {menuItem.label === clickedMenu && (
                        <Menu
                          sx={{ mt: "45px" }}
                          id="menuItem-appbar"
                          anchorEl={anchorElAdmin}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={Boolean(anchorElAdmin)}
                          onClose={handleCloseMainMenu}
                        >
                          {menuItem.subMenu.map((subMenuItem, i) => {
                            return (
                              <MenuItem
                                key={i}
                                onClick={handleCloseMainMenu}
                                component={NavLink}
                                to={subMenuItem.href}
                                textAlign="center"
                                sx={{
                                  textDecoration: "none",
                                  color: "#5F6D7E",
                                  px: 4,
                                }}
                              >
                                {subMenuItem.label}
                              </MenuItem>
                            );
                          })}
                        </Menu>
                      )}
                    </Box>
                  ) : (
                    <Button
                      key={menuItem.label}
                      component={NavLink}
                      to={menuItem.href}
                      disableRipple
                      sx={{
                        my: 2,
                        display: "block",
                        px: 2,
                        color: "#5F6D7E",
                        textTransform: "capitalize",
                      }}
                    >
                      {menuItem.label}
                    </Button>
                  )}
                </>
              );
            })}
          </>

          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ px: 2 }}>
              <Avatar sx={{ backgroundColor: themeCssButton.backgroundColor }}>
                {userInitials()}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menuItem-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {/* {settings.map((setting) => (
              // console.log("tenants====>",setting,currentUser.is_super_admin)
              

      <MenuItem
                key={setting}
                onClick={handleCloseUserMenu}
                textAlign="center"
                component={NavLink}
                sx={{ textDecoration: "none", color: "#5F6D7E" }}
                to={setting.href}
              >
                {setting.label}
              </MenuItem>
))} */}
            {settings.map((setting) => {
              // console.log("tenants====>",setting,currentUser.is_super_admin)

              return setting.label === "Tenants" &&
                !currentUser.is_super_admin ? null : (
                <MenuItem
                  key={setting}
                  onClick={handleCloseUserMenu}
                  textAlign="center"
                  component={NavLink}
                  sx={{ textDecoration: "none", color: "#5F6D7E" }}
                  to={setting.href}
                >
                  {setting.label}
                </MenuItem>
              );
            })}
            <MenuItem
              key="Logout"
              onClick={() => {
                handleCloseUserMenu();
                logout({ returnTo: "/" });
                dispatch(setUser({}));
                handleClearStore();
              }}
              textAlign="center"
              sx={{ textDecoration: "none", color: "#5F6D7E" }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Box>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <Button
            disableRipple
            sx={{
              my: 2,
              display: "block",
              px: 2,
              textTransform: "capitalize",
              background: themeCssButton.backgroundColor,
              color: "white",
              fontWeight: "bold",
              ...themeCssButton,
            }}
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  scope: "offline_access",
                  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                  useRefreshTokens: true,
                  cacheLocation: "localstorage",
                },
              })
            }
          >
            Login
          </Button>
        </Box>
      )}
    </>
  );
};

export default MainNav;
