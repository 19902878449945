import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "zoneSlice",
  initialState: {
    items: [],
    totalItems: 0,
    rowsPerPage: 10,
    page: 1,
  },
  reducers: {
    setZones: (state, action) => {
      state.items = action.payload.zones;
      state.totalItems = action.payload.count
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload
    }
  },
});

export const { setZones, setPage, setRowsPerPage } = slice.actions;

export const getZones = (state) => {
  return state.zoneSlice.items;
};

export default slice.reducer;
