import useApi from "../../hooks/useAPI";
import { formatDate, formatDateTime } from "../../utils/helperFunctions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
} from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const styles = {
  container: {
    marginTop: "4rem",
  },
  buttonContainer: {
    textAlign: "right",
    marginBottom: "2rem",
  },
};

const SummaryReport = ({ clonedElement }) => {
  const { getData } = useApi();
  const user = useSelector((state) => state.user.user);
  const { id } = useParams();
  const [huddle, setHuddle] = useState(null);
  const [steps, setSteps] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    if (user.tenant_id) {
      fetchHuddle();
    }
    // eslint-disable-next-line
  }, [user.tenant_id]);

  const exportToPdf = () => {
    if (window.html2pdf) {
      const printOptions = {
        margin: 0.25,
        filename: `HuddleSheet-${huddle?.details?.work_order}-${formatDate(
          huddle?.details?.start_time
        )}-${huddle?.details?.shift_name}.pdf`,
        image: { type: "png", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      const element = document.getElementById("printableEl");
      window.html2pdf().set(printOptions).from(element).save();
      return;
    }
    const printableWindow = window.open("", "_blank");
    printableWindow.document.write(
      "<html><head><title>Print</title><style type='text/css'>" +
        "body {" +
        "  position: fixed;" +
        "  left: 0;" +
        "  right: 0;" +
        "  top: 0;" +
        "  bottom: 0;" +
        "  border: 1px solid #dedede;" +
        "  padding: 16px;" +
        "}" +
        "#printable-logo{" +
        "  display: block !important;" +
        "}" +
        "table, th, td {" +
        "  border: 1px solid #dedede;" +
        "  padding: 8px;" +
        "  margin: 8px;" +
        "}" +
        "table { width: 100% }" +
        "</style></head><body>"
    );
    printableWindow.document.write(tableRef.current.outerHTML);
    printableWindow.document.write("</body></html>");
    printableWindow.document.close();
    printableWindow.print();
  };

  const extractUniqueWorkerNames = (data) => {
    if (!data) {
      return null;
    }
    const uniqueWorkers = {};
    data?.huddles?.forEach((huddle) => {
      huddle.all_workers_for_huddles.forEach((worker) => {
        const { name, position } = worker;
        if (!uniqueWorkers[name] || position < uniqueWorkers[name].position) {
          uniqueWorkers[name] = { name, position };
        }
      });
    });
    return Object.values(uniqueWorkers).sort((a, b) => a.position - b.position);
  };

  const fetchHuddle = () => {
    try {
      getData(`${process.env.REACT_APP_BASE_URL}/web/v2/huddle/report/${id}`)
        .then((responseData) => {
          setHuddle(responseData || null);
          setSteps(extractUniqueWorkerNames(responseData || {}));
        })
        .catch((error) => {
          console.error("Error fetching huddle data:", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const MemoizedTableBody = React.memo(({ steps, huddle }) => {
    return (
      <TableBody>
        {steps?.map((step) => (
          <TableRow key={"step-" + step.position}>
            <TableCell>{step?.name}</TableCell>
            {huddle?.huddles?.map((row) => (
              <TableCell>
                {row?.all_workers_for_huddles?.map((item) => {
                  if (item.position === step.position) {
                    return (
                      <p key={"worker-" + item.position}>{item.worker_code}</p>
                    );
                  }
                  return null;
                })}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  });

  console.log('HELLO');
  

  return (
    <div style={styles.container}>
      <div style={styles.buttonContainer}>
        {huddle && (
          <Button variant="contained" color="primary" onClick={exportToPdf}>
            Export to PDF
          </Button>
        )}
      </div>
      <div id="printableEl">
        <TableContainer component={Paper} ref={tableRef} sx={{ p: 1, mb: 5 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "40px",
              justifyContent: "space-between",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: clonedElement?.outerHTML || null,
              }}
            ></div>
          </div>
          <h3>
            WORK ORDER SUMMARY{" "}
            <span style={{ fontSize: "75%" }}>
              (#{id})
            </span>
          </h3>
          <Table sx={{ mb: 2, border: "1px solid black" }}>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  Date:{" "}
                  <strong>
                    {huddle?.details?.start_time
                      ? formatDate(huddle?.details?.start_time)
                      : ""}
                  </strong>
                </TableCell>
                <TableCell>
                  Shift Description:{" "}
                  <strong>{huddle?.details?.shift_name}</strong>
                </TableCell>
                <TableCell>
                  Work Order#: <strong>{huddle?.details?.work_order}</strong>
                </TableCell>
                <TableCell>
                  Customer Order#:{" "}
                  <strong>{huddle?.details?.customer_reference}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Product Code: <strong>{huddle?.details?.product_code}</strong>
                </TableCell>
                <TableCell>
                  MFPS: <strong>{huddle?.details?.specification}</strong>
                </TableCell>
                <TableCell>
                  Production Resource:{" "}
                  <strong>{huddle?.details?.production_resource_nam}</strong>
                </TableCell>
                <TableCell>
                  Line & Schamatic Type:{" "}
                  <strong>
                    {huddle?.details?.line_name} -{" "}
                    {huddle?.details?.line_variation_name}
                  </strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <br />
          <h3>HUDDLE REPORT</h3>
          <Table sx={{ mb: 2, border: "1px solid black" }}>
            <TableHead>
              <TableRow>
                <TableCell>Step Name</TableCell>
                {huddle?.huddles?.map((item) => (
                  <TableCell key={"huddle-" + item.position}>
                    <span>Rotation</span>
                    <br />
                    <span>
                      Start Time:{" "}
                      <strong>
                        {item.start_time ? formatDateTime(item.start_time) : ""}
                      </strong>
                    </span>
                    <br />
                    <span>
                      End Time:{" "}
                      <strong>
                        {item.end_time ? formatDateTime(item.end_time) : ""}
                      </strong>
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <MemoizedTableBody steps={steps} huddle={huddle} />
          </Table>

          <br />
          <h3>WORKERS</h3>
          <Table sx={{ border: "1px solid black" }}>
            <TableHead>
              <TableRow>
                <TableCell>Sl.No.</TableCell>
                <TableCell>Worker ID#</TableCell>
                <TableCell>Worker Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {huddle?.total_workers?.map((worker, index) => (
                <TableRow key={"worker-id-" + worker.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{worker.worker_code}</TableCell>
                  <TableCell>
                    {worker.worker_assignments.split("-").slice(1).join(" ")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{marginTop:"10px",marginLeft:"20px", marginBottom:"80px", display:"flex",flexDirection:"row"}}>
          <h5>Note :</h5><span> {huddle?.huddles.length === 1 ?  huddle?.huddles[0]?.reason :  huddle?.huddles[huddle.huddles.length - 1]?.reason}</span>
          </Box>
      </div>
    </div>
  );
};

export default SummaryReport;
