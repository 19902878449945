import { useNavigate } from "react-router-dom";
import { settenant } from "../../features/tenant/tenantSlice";
// import { settenantname, settenanturl } from "../../features/tenant/tenanturlSlice";
import useApi from "../../hooks/useAPI";
import "./tenant.css";
import { Box, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetails } from "../../features/user/userAPI";
import { setUser } from "../../features/user/userSlice";
import { Loading } from "../UIComponents";


const Tenants = () => {
  const tenantData = useSelector((state) => state.tenant.items);
  const userInfo = useSelector((state) => state.user.user);
  const [mediaData, setMediaData] = useState([]);
  const {loading, getData, postData,patchData } = useApi();
  const mediaView = `${process.env.REACT_APP_BASE_URL_ADMIN}/admin/media`
  const tenantURL = `${process.env.REACT_APP_BASE_URL_ADMIN}/admin/tenant`
  const tenantUpdateURL = `${process.env.REACT_APP_BASE_URL}/web/v2/user`
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const callAPI = async () => {
    if (userInfo.zone_id != null) {
      try {
        getData(`${tenantURL}/all`).then((data) => {
          const tenantInfo = data || [];
          dispatch(settenant(tenantInfo));
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const callAPIImg = async () => {
    const getFileData = (name) => {
        return new Promise((resolve) => {
            postData(`${mediaView}/view`, {
                file_name: name,
              }).then((data) => {
                resolve({status: true, data})
              }).catch((error) => {
                resolve({status: false, error})
              });
        })
    }
    if (tenantData?.length > 0) {
      const mediaViewInfoArray = [];

    for(let i=0; i < tenantData.length; i++) {
        if (tenantData[i]?.image.file_name != null) {
            try {
              let result = await getFileData(tenantData[i]?.image.file_name);
              if(result.status) {
                  mediaViewInfoArray.push(result.data);
              }
            } catch (error) {
              console.log(error);
            }
          }
    }
    setMediaData(mediaViewInfoArray);
    
    }
  };


  const callAPIUpdate = async (tenantId)=>{
    if (tenantId != null) {
        try {
            patchData(`${tenantUpdateURL}/update/tenant/${tenantId}`).then(data => {
                // const tenantInfo = data || [];

                fetchUserDetails().then(data => {

                  dispatch(setUser(data || {}))
                })
                navigate("/", {state :tenantId})
                
            })
        } catch (error) {
            console.log(error);
        }
    }

  }
  useEffect(() => {
    callAPIImg();
  // eslint-disable-next-line
  }, [tenantData]);
  useEffect(() => {
    callAPI();
    // eslint-disable-next-line
  }, [userInfo]);
 
  const listItemStyle = {
   
   
  };

  const getTenantId=(id)=>{
    callAPIUpdate(id);
  }

  return loading ? (
    <Loading/>
  ) : (
    <>
      <Box>
        <ImageList cols={4} gap={8} sx={{overflowY:'hidden'}}>
          {mediaData.length > 0 &&
            tenantData?.map((item, index ) => (
           
                <ImageListItem key={item.url} sx={listItemStyle}>
                <img
                  srcSet={`${mediaData[index].url}`}
                  src={`${mediaData[index].url}`}
                  alt={item.name}
                  loading="lazy"
                  className="images"
                 onClick={(e)=>{getTenantId(item.id)}}
                //  onClick={(e)=>{getTenantId(item.id,item.name,mediaData[index].url)}}
                />
                <ImageListItemBar sx={{textAlign:"center",  fontWeight: "bold",}} title={item.name} position="below" />
                
              </ImageListItem>
            
            ))}
        </ImageList>
  
      </Box>
    </>
  );
};


export default Tenants;