import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "permissionSlice",
  initialState: {
    items: [],
  },
  reducers: {
    setPermission: (state, action) => {
      state.items = action.payload || [];
    },
  },
});

export const { setPermission } = slice.actions;

export const getPermission = (state) => {
  return state.permissionSlice.items;
};

export default slice.reducer;
