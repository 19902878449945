import React from "react"
import { Grid, FormControl, FormLabel, TextField } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { updateStepInput } from "../../../../features/ProductionResourceSteps/stepsSlice";

const StepDetailsForm = () => {
  const dispatch = useDispatch()
  const currentStep = useSelector(state => state.steps.currentStep)
  const isStepEditable = useSelector(state => state.steps.isStepEditable)

  const handleInputChange = (e) => {
    dispatch(updateStepInput({key: e.target.name, value: e.target.value}));
  };

    return <>
      {/* <Typography sx={{color: '#2E3646', fontSize: '20px', lineHeight: '40px', fontWeight: 600}}>Step Details</Typography> */}
      <Grid container rowSpacing={6} columnSpacing={4}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel>Step Name</FormLabel>
          <TextField
            name="name"
            value={currentStep.name}
            onChange={handleInputChange}
            disabled={!isStepEditable}
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel>Description</FormLabel>
          <TextField
            name="desc"
            value={currentStep.desc}
            onChange={handleInputChange}
            disabled={!isStepEditable}
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <FormLabel>Minimum Worker Required</FormLabel>
          <TextField
            type="number"
            name="min_crew"
            value={currentStep.min_crew}
            onChange={handleInputChange}
            InputProps={{ inputProps: { min: 1 } }}
            disabled={!isStepEditable}
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <FormLabel>Maximum Worker Required</FormLabel>
          <TextField
            type="number"
            name="max_crew"
            value={currentStep.max_crew}
            onChange={handleInputChange}
            InputProps={{ inputProps: { min: +currentStep.min_crew+1 } }}
            required
            disabled={!isStepEditable}
          />
        </FormControl>
      </Grid>
      </Grid>
    </>
}

export default StepDetailsForm