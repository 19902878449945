import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Typography, Button, createTheme, ThemeProvider, FormGroup, FormControl, TextField,} from "@mui/material"
import { setLine, setPage, setRowsPerPage } from "../features/line/lineSlice";
import CosmeticaTable from "./UIComponents/Table";
import CosmeticaModal from "./UIComponents/Modal";

import { useNavigate } from "react-router-dom";
import useApi from "../hooks/useAPI";
import muiStyles from "../styles/MuiComponentStyles";
import { formatDateTime } from "../utils/helperFunctions";
import { East } from "@mui/icons-material";
import { Loading } from "./UIComponents";
import { useCssStateHook } from "./UIComponents/ComonCss";


const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '40px',
                    color: '#2E3646'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: '#000',
                    padding: '9px 16px',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '22px',
                    textTransform: 'none',
                    '&:hover': {
                        color: '#000',
                        backgroundColor: '#fff'
                    }
                }
            }
        }
    }
})

const Line = () => {
    const lineData = useSelector((state) => state.line.items);
    const userInfo = useSelector((state) => state.user.user);
    const totalLines = useSelector((state) => state.line.totalItems)
    const rowsPerPage = useSelector(state => state.line.rowsPerPage);
    const page = useSelector(state => state.line.page)
    const {loading, getData, postData, patchData, deleteData } = useApi()
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false)
    let navigate = useNavigate();
    const {  themeCssButton,themeCssHeader} = useCssStateHook();
    const initialState = {
        name: '',
        desc: '',
        short_desc: '',
        tenant_id: userInfo?.tenant_id,
        zone_id: userInfo?.zone_id,
    }
    const [editLineData, setEditLineData] = useState(initialState)
    const lineURL = `${process.env.REACT_APP_BASE_URL}/web/v2/line`
    const callAPI = async () => {
        if (userInfo.zone_id != null) {
            try {
                getData(`${lineURL}/all/${userInfo.zone_id}/page/${page}/count/${rowsPerPage}`).then(data => {
                    const linesInfo = data || [];
                    dispatch(setLine(linesInfo))
                })
            } catch (error) {
                console.log(error);
            }
        }
    }
    React.useEffect(() => {
        callAPI()
        // eslint-disable-next-line
    }, [userInfo,page, rowsPerPage]);

    const fields = ['Name', 'Short Code','Details','Variation count','Created at', '']
    const getRows = (data) => data.map(row => {
        const { id, name,short_desc,desc,variation_count,created_at, time_zone, } = row
        return { id, name,short_desc,desc,variation_count,created_at:formatDateTime(created_at), time_zone, }
    })
    const handleSubmit = async (e, line) => {
        e.preventDefault()
        e.target.reset()
        setEditLineData(initialState)
        setShowModal(false)
        if (line?.id) {
            patchData(`${lineURL}/update`, line).then((res) => {
                callAPI()
            }).catch((err) => {
                console.log("Error ", err)
            })

        } else {
            postData(`${lineURL}/create`, line).then((res) => {
                callAPI()
            }).catch((err) => {
                console.log("Error ", err)
            })
        
        }
    }
    const handleEdit = (id) => {
        const _line = lineData.find(d => d.id === id)
        setEditLineData({ id, name: _line.name, desc: _line.desc, short_desc: _line.short_desc })
        setShowModal(true)
    }
    const handleDelete = async (id) => {
        deleteData(`${lineURL}/delete/${id}`).then((res) => {
            callAPI()
        }).catch((err) => {
            console.log("Error ", err)
        })
      
    }
    const handleCancel = () => {
        setShowModal(false);
        setEditLineData(initialState);
        // setIsEditId("")
    }
    const handleOnclickRow = (id) => {
        navigate(`/admin/line/${id}`)
    }
    const handlePageChange = (newPage) => {
        dispatch(setPage(newPage))
      }
      const handleRowsPerPageChange = (newRowsPerPage) => {
        dispatch(setRowsPerPage(newRowsPerPage))
        dispatch(setPage(1))
      }
    return loading ? (
        <Loading/>
    ) : (
        <Box>
            <ThemeProvider theme={theme}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mt={5}>
                    <Typography>Lines</Typography>
                    <Button variant="contained" onClick={() => { setShowModal(true) }}  sx={themeCssButton}>Add Line</Button>
                </Stack>
            </ThemeProvider>
            <Box sx={{ mt: 4 }}>
                    <>
                        <CosmeticaTable
                            fields={fields}
                            rows={getRows(lineData)}
                            isDelete
                            isEdit
                            isRowClick={true}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleOnclickRow={handleOnclickRow}
                            handlePageChange={handlePageChange}
                            handleRowsPerPageChange={handleRowsPerPageChange}
                            rowsCount={totalLines}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            isHeadercss={themeCssHeader}
                            isSearch
                        />
                    </>
                  
            </Box>
            {showModal &&
                <CosmeticaModal
                    showModal={showModal}
                    title={editLineData?.id ? "Edit Line" : "Add Line"}
                    closeModal={handleCancel}
                >
                    <LineForm
                        handleClose={() => { setShowModal(false); setEditLineData(initialState) }}
                        handleSubmit={handleSubmit}
                        lineData={editLineData}
                        handleCancel={handleCancel}
                        isHeadercss={themeCssButton}
                    />
                </CosmeticaModal>
            }
           
        </Box>
    );
};
const LineForm = ({ handleSubmit, lineData, handleCancel,isHeadercss }) => {
    const [line, setLine] = useState(lineData)
    const validata ={
        name:false,
        desc:false,
        short_desc:false
        
    }
    const [isNumberEntered, setIsNumberEntered] = useState(validata);
    const handleChange = (e) => {
        // const validName = /^[A-Za-z\s]+$/.test(e.target.value);
        const validName = /^.*$/.test(e.target.value);
       
        
       if(!validName){
        setIsNumberEntered((prevdata) => { return { ...prevdata, [e.target.name]: !validName} });
       }else{
        setIsNumberEntered((prevdata) => { return { ...prevdata, [e.target.name]: !validName} });
       }

  
    

        
        setLine((prevZone) => { return { ...prevZone, [e.target.name]: e.target.value } })
    }
    const buttonStyle = {
        backgroundColor: '#2E3646',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#2E3646',
            color: '#fff',
            opacity: 0.7,
        }
    }
    const  handleerror=(e)=>{
        e.preventDefault()
        if(!isNumberEntered.name && !isNumberEntered.desc && !isNumberEntered.short_desc){
            handleSubmit(e, line)
        }
    }
    return <>
        <form style={{ marginTop: "20px" }} onSubmit={(e) => handleerror(e)}>
            <FormGroup>
                <FormControl sx={{ mt: 2 }}>
                    <TextField
                        label="Name"
                        name="name"
                        value={line.name}
                        variant="outlined"
                        onChange={handleChange} 
                        required
                        error={isNumberEntered.name}/>
                    {isNumberEntered.name && (
                        <p style={{ color: 'red' }}>Please enter a valid name without numbers.</p>
                    )}
                </FormControl>
                <FormControl sx={{ mt: 2 }}>
                    <TextField
                        label="Desc"
                        name="desc"
                        value={line.desc}
                        variant="outlined"
                        onChange={handleChange} 
                        required
                        error={isNumberEntered.desc} />
                         {isNumberEntered.desc && (
                        <p style={{ color: 'red' }}>Please enter a valid name without numbers.</p>
                    )}
                </FormControl>
                <FormControl sx={{ mt: 2 }}>
                    <TextField
                        label="Short Desc"
                        name="short_desc"
                        value={line.short_desc}
                        variant="outlined"
                        onChange={handleChange}
                         required
                        error={isNumberEntered.short_desc} />
                         {isNumberEntered.short_desc && (
                        <p style={{ color: 'red' }}>Please enter a valid name without numbers.</p>
                    )}
                </FormControl>
            </FormGroup>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
                <Button
                    type="reset"
                    variant="text"
                    size="large"
                    onClick={() => handleCancel()}
                    sx={muiStyles.secondaryButton}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    // sx={buttonStyle}
                    sx={ isHeadercss || buttonStyle}
                    endIcon={<East />}
                >
                    {line.id ? "Update" : "Add"}
                </Button>
            </Stack>
        </form>
    </>
}
export default Line;
