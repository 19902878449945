// import muiStyles from "../../../../styles/MuiComponentStyles";
// import { East } from "@mui/icons-material";
import {
  Box,
  Typography,
  Stack,
  Grid,
  FormControl,
  FormLabel,
  TextField,
//   Button,
} from "@mui/material";
import UploadFile from "./UploadFile";
import React, { } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { updateImageInput, updateImage, clearImage, addImage, setEnableStepSubmitted, setStepEditable } from "../../../../features/ProductionResourceSteps/stepsSlice"
import useApi from "../../../../hooks/useAPI";
import {  setEnableStepSubmittedQuality, setStepEditableQuality, updateImage, updateImageInput } from "../../../../features/ProductionResourceSteps/stepsSliceQuality";

export const Image = ({ index, imagesLength, fetchSteps,qualityalerts }) => {
  const currentStep = useSelector(state => state.stepsquality.currentStep)
  const isStepEditable = useSelector(state => state.stepsquality.isStepEditable)
  const image = currentStep?.media?.images[index]
  const dispatch = useDispatch()
  const {deleteData} = useApi()

  const handleDelete = async () =>{
    const mediaId = image.id
    const url = `${process.env.REACT_APP_BASE_URL}/web/v2/media/delete/${mediaId}`
    if (image.file_name && image.url) {
      await deleteData(url)
      await fetchSteps()
      dispatch(setEnableStepSubmittedQuality(true))
      dispatch(setStepEditableQuality(false))
    }
  }

  const handleInputChange = (e, imageIndex) => {
      dispatch(updateImageInput({key: e.target.name, value: e.target.value, index: imageIndex}))
  }

  const handleImageChange = (url, type, file, imageIndex) => {
      dispatch(updateImage({url, type, file, index: imageIndex}))
  }

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "362px",
        height: "504px",
        borderRadius: "8px",
        backgroundColor: "rgba(217, 217, 217, 0.30)",
        marginRight: '20px'
      }}
      mt={2}
      position='relative'
    >
      <Box
        sx={{
          width: "342px",
          height: "230px",
          boeder: "1px solid #5F6D7E",
          backgroundColor: !image?.url ? "#E6E9EC" : 'none',
          borderRadius: "10px",
        }}
        position='relative'
      >
        <UploadFile accept='image/*' required label='image' height='230px' file={image} handleFileChange={(url, type, file) => handleImageChange(url, type, file, index)} handleDeleteImage={handleDelete} qualityalerts={qualityalerts} />
      </Box>
      <Stack spacing={2} mt={2}>
        <Grid item xs={6} sx={{ width: "342px" }}>
          <FormControl fullWidth>
            <FormLabel>Name</FormLabel>
            <TextField
              name="name"
              value={image?.name || ''}
              onChange={(e) => handleInputChange(e, index)}
              required={!!image?.url}
              disabled={!isStepEditable}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel>Tags</FormLabel>
            <TextField
              name="tags"
              value={image?.tags || ''}
              onChange={(e) => handleInputChange(e, index)}
              disabled={!isStepEditable}
            />
          </FormControl>
        </Grid>
      </Stack>
    </Stack>
  );
};

const ImagecomponentSingle = ({fetchSteps}) => {
  const images = useSelector(state => state.stepsquality.currentStep)?.media?.images
  // const images11 = useSelector(state => state.stepsquality.currentStep)
  const imagesLength = images?.length || 0

  // const a = images11?.filter(item => item.position === currentStepIndex ? return (item.media.Images): )

//   const defaultImage = {
//     name: "",
//     tags: "",
//     url: "",
//     type: "image",
//     file: null,
//   };
  
//   const imagesOrDefault = images !== undefined ? images : [defaultImage];
  return (
    <Box mt={4}>
      <Typography
        sx={{
          color: "#2E3646",
          fontSize: "20px",
          lineHeight: "40px",
          fontWeight: 600,
        }}
      >
        Images
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center"
        }}
      >
        {images?.map((image, index) => {
          return (
            <Image
              key={index}
              image={image}
              imagesLength={imagesLength}
              index={index}
              fetchSteps={fetchSteps}
              
            />
          );
        })}
              {/* <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        mt={2}
        sx={{ width: "342px", height: '40px' }}
      >
        <Button type="button" sx={muiStyles.secondaryButton} onClick={handleImageCancel} disabled={imagesLength <= 1 || !isStepEditable}>
            Cancel
            </Button>
            <Button type="button" sx={muiStyles.primaryButton} endIcon={<East />} onClick={handleAddImage} disabled={!isStepEditable} >
            Add New
            </Button>
        
      </Stack> */}
      </Box>
    </Box>
  );
};

export default ImagecomponentSingle;
