import React from "react";
import { Android, Apple } from "@mui/icons-material";
import { format, parseISO, isWithinInterval, isAfter, isToday, startOfDay, parse } from "date-fns";

export const formatDate = (date, formatStr = "dd/MM/yyyy") => {
  return format(new Date(date), formatStr);
};

export const formatDateTime = (date, formatStr = "dd/MM/yyyy HH:mm:ss ") => {
  return format(new Date(date), formatStr);
};

export function getWeekNumber(date = new Date()) {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

export function getStartAndEndDateOfWeek(
  weekNumber,
  year = new Date().getFullYear()
) {
  const januaryFourth = new Date(year, 0, 4);
  const firstDayOfYear = new Date(year, 0, 1 + (7 - januaryFourth.getDay()));
  const startDate = new Date(
    firstDayOfYear.getTime() + (weekNumber - 1) * 7 * 24 * 60 * 60 * 1000
  );
  startDate.setDate(startDate.getDate() + 1 - (startDate.getDay() || 7));
  const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);

  return { start: startDate.toISOString(), end: endDate.toISOString() };
}

export const formatIcon = (data,os_version) => {

  if (data.toLowerCase() === "android") {
    return <>
    <Android sx={{ fontSize: 40, marginRight:1 }} color="success" />
    {os_version}
    </>
    // return (data = <Android sx={{ fontSize: 40 }} color="success" />os_version);
  } else if (data.toLowerCase() === "apple") {
    return <>
   <Apple sx={{ fontSize: 40 }} color="success" />
    {os_version}
    </>
   
  } else {
    return <>
     <Android sx={{ fontSize: 40, marginRight:1 }} color="success" />
    {os_version}
    </>
   
  }
};

export function isDateWithinRange(givenDateStr, startDateStr, endDateStr) {
  // Parse the start and end dates as ISO date strings
  const givenDate = parseISO(givenDateStr);
  const startDate = parseISO(startDateStr);
  const endDate = parseISO(endDateStr);

  console.log({givenDate, startDate, endDate})

  // Check if today's date is within the interval [startDate, endDate]
  const isWithinRange = isWithinInterval(givenDate, {
    start: startDate,
    end: endDate,
  });

  return isWithinRange
}

export function isTodayGreaterThanGivenDate(givenDateStr) {
  // Parse the given date as an ISO date string
  const givenDate = parseISO(givenDateStr);
  return isAfter(new Date(), givenDate)
}

export function isTodayGreaterThanEqualGivenDate(givenDateStr) {
  // Parse the given date as an ISO date string
  const givenDate = parseISO(givenDateStr);
  const today = startOfDay(new Date());
  return isAfter(givenDate, today) || isToday(givenDate)
}

export function isGivenDateGraterThanEqualToday(givenDateStr, givenDateFormat='dd/MM/yyyy') {
  // Parse the given date as an ISO date string
  const givenDate = parse(givenDateStr, givenDateFormat, new Date())
  const today = startOfDay(new Date());
  return isToday(givenDate) || isAfter(givenDate, today)
}

export const getWeeksInYear = (year) => (new Date(year, 11, 31).getDay() <= 4) ? 52 : 53;

export const compareObjects = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return true;
  }
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return true;
    }
  }
  return false;
}

export const compareObjectsWithProperties = (obj1, obj2, properties) => {
  for (const property of properties) {
    if (obj1[property] !== obj2[property]) {
      return true; // Difference found in specified property
    }
  }
  return false;
}

export function getTextColor(backgroundColor) {
  const rgb = hexToRgb(backgroundColor);
  if (!rgb) return null;
  
  const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
 
  return luminance > 0.5 ? 'black' : 'white';
}

export function hexToRgb(hex) {
  if (!hex) return null; 
  hex = hex.replace(/^#/, '');

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}