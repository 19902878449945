import React from "react";
import { formatDateTime } from "../../../utils/helperFunctions";

const FillTableOne = ({readings, inspector_name}) => {
  return (
    <table className="c98">
      <tbody>
        <tr className="c40">
          <td className="c110" colSpan={3} rowSpan={2}>
            {/* <ul className="c87 lst-kix_list_1-0 start">
                    <li className="c24 li-bullet-1">
                      <span className="c22">FILLING</span>
                    </li>
                    <li className="c24 li-bullet-1">
                      <span className="c22">PACKING</span>
                    </li>
                  </ul> */}
            <p className="c70">
              <span className="c22">Time:</span>
            </p>
          </td>
          <td className="c65" colSpan={7} rowSpan={1}>
            <p className="c28">
              <span className="c22">Checked By QI Inspector: {inspector_name}</span>
            </p>
          </td>
        </tr>
        <tr className="c48">
        {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading?.time ? formatDateTime(reading.time, 'HH:mm') : 'N/A'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[0]?.time ? formatDateTime(readings[0]?.time, 'HH:mm') : 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[1]?.time ? formatDateTime(readings[1]?.time, 'HH:mm') : 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[2]?.time ? formatDateTime(readings[2]?.time, 'HH:mm') : 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[3]?.time ? formatDateTime(readings[3]?.time, 'HH:mm') : 'N/A'}</span>
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[4]?.time ? formatDateTime(readings[4]?.time, 'HH:mm') : 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[5]?.time ? formatDateTime(readings[5]?.time, 'HH:mm') : 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[6]?.time ? formatDateTime(readings[6]?.time, 'HH:mm') : 'N/A'}</span>
            </p>
          </td> */}
        </tr>
        
        <tr className="c18">
            <td className="c7" colSpan={1} rowSpan={6}>
                <p className="c97">
                <span className="c22">CRITICAL</span>
                </p>
            </td>
          <td className="c58" colSpan={2} rowSpan={1}>
            <p className="c71">
              <span className="c10">
                1. Product identify (Incorrect/ Missing Text/ Print)
              </span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.critical1 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[0]?.critical1 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[1]?.critical1 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[2]?.critical1 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[3]?.critical1 || 'N/A'}</span>
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[4]?.critical1 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[5]?.critical1 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[6]?.critical1 || 'N/A'}</span>
            </p>
          </td> */}
        </tr>
        <tr className="c48">
          <td className="c58" colSpan={2} rowSpan={1}>
            <p className="c78 c86">
              <span className="c10">
                2. Code Legibility/ Presence/ Placement/ orientation (ZERO
                defects allowed)
              </span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.critical2 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[0]?.critical2 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[1]?.critical2 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[2]?.critical2 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[3]?.critical2 || 'N/A'}</span>
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[4]?.critical2 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[5]?.critical2 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[6]?.critical2 || 'N/A'}</span>
            </p>
          </td> */}
        </tr>
        <tr className="c48">
          <td className="c58" colSpan={2} rowSpan={1}>
            <p className="c78">
              <span className="c10">
                3. Imperfection on the Product, e.g. separation, cracks,
                pinholes, foreign
              </span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.critical3 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[0]?.critical3 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[1]?.critical3 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[2]?.critical3 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[3]?.critical3 || 'N/A'}</span>
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[4]?.critical3 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[5]?.critical3 || 'N/A'}</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2">{readings[6]?.critical3 || 'N/A'}</span>
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c58" colSpan={2} rowSpan={1}>
            <p className="c71">
              <span className="c10">4. Sharp edges/ Broken mirror</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.critical4 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c58" colSpan={2} rowSpan={1}>
            <p className="c71">
              <span className="c10">
                5. Other imperfections in the productt
              </span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.critical5 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c14">
          
          <td className="c58 c29" colSpan={9} rowSpan={1}>
            <p className="c28">
              <span className="c68"></span>
            </p>
          </td>
          
        </tr>
        <tr className="c18">
          <td className="c7" colSpan={1} rowSpan={21}>
            <p className="c59">
              <span className="c22">MAJOR COMPONENT APPEARANCE</span>
            </p>
          </td>
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c28">
              <span className="c68">6. Component Appearance Liquids</span>
            </p>
          </td>
          <td className="c13" colSpan={1} rowSpan={21}>
            <p className="c49">
              <span className="c10">
                Any imperfection that may affect the use or sale-ability of the
                unit
              </span>
            </p>
          </td>
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
          <td className="c65 c29" colSpan={7} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c50">
              <span className="c10">6.1 Capy Wand</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.liquid61 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c50">
              <span className="c10">6.2Bottle/JarkTube</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.liquid62 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c50">
              <span className="c10">6.3 Pump/ Pump to prime</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.liquid63 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c50">
              <span className="c10">6.4 Component opens/ closes properly</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.liquid64 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c50">
              <span className="c10">6.5 Pencil Barrel</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.liquid65 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c50">
              <span className="c10">6.6 Shrink Sleeve/ Safety Seal</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.liquid66 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c50">
              <span className="c10">6.7. Shaker balls</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.liquid67 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c50">
              <span className="c10">68. Other</span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.liquid68 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        {/* <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c50">
              <span className="c10">68. Other</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
        </tr> */}
        <tr className="c18">
          <td className="c11 c29" colSpan={1} rowSpan={1}>
            <p className="c50">
              <span className="c10"></span>
            </p>
          </td>
          <td className="c65 c29" colSpan={7} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
        </tr>
        <tr className="c14">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c50">
              <span className="c10">7. Component Appearance Powder</span>
            </p>
          </td>
          <td className="c65 c29" colSpan={7} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">
                7.1 Compact
              </span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.powder71 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">
                7.2 Pan
              </span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.powder72 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">
                7.3 Acetate
              </span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.powder73 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">
                7.4 Mirror
              </span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.powder74 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">
                7.5 Brush/ Sponge
              </span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.powder75 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">
                7.6 Liners/ Shrink Sleeve/ Safety Seal
              </span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.powder76 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">
                7.7 Components fit together properly
              </span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.powder77 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">
                7.8 Locking Mechanism Intact
              </span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.powder78 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">
                7.9 Other
              </span>
            </p>
          </td>
          {
            readings?.map((reading, index) => {
                return <td key={index} className="c0" colSpan={1} rowSpan={1}>
                <p className="c1">
                  <span className="c2">{reading.powder79 || '/'}</span>
                </p>
              </td>
            })
          }
          {/* <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td> */}
        </tr>
        {/* <tr className="c48">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c94">
              <span className="c10">7.10. Brush/ Mirror/ Sponge/ Balls</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">7.11. Unit cartons/ Bags</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">7.12. Liners/ Shrink Sleeve/ Seal</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">7.13. Acetate</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c43">
              <span className="c10">7.14. Others</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c50">
              <span className="c10">8. Component opens/ closes properly</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
        </tr>
        <tr className="c18">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c50">
              <span className="c10">9. Components fit together properly</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
        </tr>
        <tr className="c14">
          <td className="c11" colSpan={1} rowSpan={1}>
            <p className="c28">
              <span className="c10">10.Locking Mechanism Intact</span>
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c4" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
          <td className="c0" colSpan={1} rowSpan={1}>
            <p className="c1">
              <span className="c2" />
            </p>
          </td>
        </tr> */}
      </tbody>
    </table>
  );
};

export default FillTableOne;
