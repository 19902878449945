import { Stack, Divider } from "@mui/material"
import React from "react"
import ImagecomponentSingle from "./ImagecomponentSingle"

const MediaComponentQuality = ({fetchSteps}) => {
    return <Stack divider={<Divider fullWidth />} spacing={3}>      
        <ImagecomponentSingle fetchSteps={fetchSteps} />
    </Stack>
}

export default MediaComponentQuality
