import React from "react";

const Footer = () => (
  <footer style={{position: 'static', bottom: '0', width: '100%', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', height: '50px', borderTop: '1px solid #5F6D7E', backgroundColor: '#E6E9EC'}}>
    {/* <div className="logo" /> */}
    <span>Powered By Bandgi Technologies</span>
  </footer>
);

export default Footer;
