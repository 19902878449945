import {
  setPage,
  setRole,
  setRowsPerPage,
} from "../../features/role/roleSlice";
import useApi from "../../hooks/useAPI";
import muiStyles from "../../styles/MuiComponentStyles";
import { recommendedRoles } from "../../utils/constants";
import { formatDateTime } from "../../utils/helperFunctions";
import { CosmeticaModal, CosmeticaTable, Loading } from "../UIComponents";
import { useCssStateHook } from "../UIComponents/ComonCss";
import { East } from "@mui/icons-material";
import {
  Box,
  Stack,
  Typography,
  Button,
  createTheme,
  ThemeProvider,
  FormGroup,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "40px",
          color: "#2E3646",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#fff",
          backgroundColor: "#000",
          padding: "9px 16px",
          borderRadius: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "15px",
          fontWeight: 500,
          lineHeight: "22px",
          textTransform: "none",
          "&:hover": {
            color: "#000",
            backgroundColor: "#fff",
          },
        },
      },
    },
  },
});

const Role = () => {
  const { themeCssButton, themeCssHeader } = useCssStateHook();
  const roleData = useSelector((state) => state.role.items);
  const userInfo = useSelector((state) => state.user.user);
  const totalrole = useSelector((state) => state.role.totalItems);
  const rowsPerPage = useSelector((state) => state.role.rowsPerPage);
  const page = useSelector((state) => state.role.page);
  const { loading, getData, postData, patchData, deleteData } = useApi();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const recommendedList = recommendedRoles
    ?.map((role) => {
      const found = roleData?.find((obj) => obj.name.toLowerCase() === role);
      return !found ? role : null;
    })
    .filter((role) => !!role);
  const initialState = {
    name: "",
    tenant_id: userInfo?.tenant_id,
  };
  const [editRoleData, setEditRoleData] = useState(initialState);
  const roleURL = `${process.env.REACT_APP_BASE_URL}/web/v2/role`;
  const callAPI = async () => {
    if (userInfo.tenant_id != null) {
      try {
        getData(
          `${roleURL}/all/${userInfo.tenant_id}/page/${page}/count/${rowsPerPage}`
        ).then((data) => {
          const rolesInfo = data || [];
          dispatch(setRole(rolesInfo));
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  React.useEffect(() => {
    callAPI();
    // eslint-disable-next-line
  }, [userInfo, rowsPerPage, page]);

  const fields = ["Name", "Created At"];
  const getRows = (data) =>
    data.map((row) => {
      const { id, name, created_at } = row;
      return {
        id,
        name: recommendedRoles.includes(name?.toLowerCase()) ? (
          <Typography
            sx={{
              height: "20px",
              padding: "3px 10px",
              backgroundColor: "#E3ECFA",
              borderRadius: "5px",
              boxSizing: "content-box",
              display: "inline",
            }}
          >
            {name}
          </Typography>
        ) : (
          name
        ),
        created_at: formatDateTime(created_at),
      };
    });
  const handleSubmit = async (e, role) => {
    e.preventDefault();
    e.target.reset();
    setEditRoleData(initialState);
    setShowModal(false);
    if (role?.id) {
      patchData(`${roleURL}/update`, role)
        .then((res) => {
          callAPI();
        })
        .catch((err) => {
          console.log("Error ", err);
        });
    } else {
      postData(`${roleURL}/create`, role)
        .then((res) => {
          callAPI();
        })
        .catch((err) => {
          console.log("Error ", err);
        });
    }
  };
  const handleEdit = (id) => {
    const _line = roleData.find((d) => d.id === id);
    setEditRoleData({ id, name: _line.name });
    setShowModal(true);
  };
  const handleDelete = async (id) => {
    deleteData(`${roleURL}/delete/${id}`)
      .then((res) => {
        callAPI();
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  };
  const handleCancel = () => {
    setShowModal(false);
    setEditRoleData(initialState);
  };
  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage));
  };
  const handleRowsPerPageChange = (newRowsPerPage) => {
    dispatch(setRowsPerPage(newRowsPerPage));
    dispatch(setPage(1));
  };

  return loading ? (
    <Loading />
  ) : (
    <Box>
      <ThemeProvider theme={theme}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={5}
        >
          <Typography>Roles</Typography>
          <Button
            variant="contained"
            onClick={() => {
              setShowModal(true);
            }}
            sx={themeCssButton}
          >
            Add Role
          </Button>
        </Stack>
      </ThemeProvider>
      <Box sx={{ mt: 4 }}>
        <CosmeticaTable
          fields={fields}
          rows={getRows(roleData)}
          isDelete
          isEdit
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          rowsCount={totalrole}
          page={page}
          rowsPerPage={rowsPerPage}
          isHeadercss={themeCssHeader}
        />
      </Box>
      {showModal && (
        <CosmeticaModal
          showModal={showModal}
          title={editRoleData?.id ? "Edit Role" : "Add Role"}
          closeModal={handleCancel}
        >
          <RoleForm
            handleClose={() => {
              setShowModal(false);
              setEditRoleData(initialState);
            }}
            handleSubmit={handleSubmit}
            roleData={editRoleData}
            handleCancel={handleCancel}
            isHeadercss={themeCssButton}
            recommendedList={recommendedList}
          />
        </CosmeticaModal>
      )}
    </Box>
  );
};
const RoleForm = ({
  handleSubmit,
  roleData,
  handleCancel,
  isHeadercss,
  recommendedList,
}) => {
  const [role, setRole] = React.useState(roleData);
  const [isNumberEntered, setIsNumberEntered] = useState(false);
  const handleChange = (e) => {
    const validName = /^[A-Za-z\s]+$/.test(e.target.value);
    setIsNumberEntered(!validName);

    setRole((prevZone) => {
      return { ...prevZone, [e.target.name]: e.target.value };
    });
  };
  const buttonStyle = {
    backgroundColor: "#2E3646",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2E3646",
      color: "#fff",
      opacity: 0.7,
    },
  };
  const tagStyle = {
    height: "20px",
    padding: "8px 16px",
    backgroundColor: "#E3ECFA",
    borderRadius: "5px",
    boxSizing: "content-box",
    display: "inline",
    cursor: "pointer",
  };

  const handleerror = (e) => {
    e.preventDefault();
    if (!isNumberEntered) {
      handleSubmit(e, role);
    }
  };
  return (
    <form style={{ marginTop: "20px" }} onSubmit={(e) => handleerror(e)}>
      <FormGroup>
        <FormControl sx={{ mt: 2 }}>
          <TextField
            label="Name"
            name="name"
            type="text"
            value={role.name}
            variant="outlined"
            onChange={handleChange}
            required={true}
            error={isNumberEntered}
          />
          {isNumberEntered && (
            <p style={{ color: "red" }}>
              Please enter a valid name without numbers.
            </p>
          )}
        </FormControl>
      </FormGroup>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        mt={1}
        ml={2}
      >
        {recommendedList?.map((item) => {
          item = item.charAt(0).toUpperCase() + item.slice(1);
          return (
            <Typography
              key={item}
              sx={tagStyle}
              onClick={() => setRole({ ...role, name: item })}
            >
              {item}
            </Typography>
          );
        })}
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        mt={2}
      >
        <Button
          type="reset"
          variant="text"
          size="large"
          onClick={() => handleCancel()}
          sx={muiStyles.secondaryButton}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          // sx={ isHeadercss && ({backgroundColor:isHeadercss.backgroundColor})}
          sx={isHeadercss || buttonStyle}
          endIcon={<East />}
        >
          {role.id ? "Update" : "Add"}
        </Button>
      </Stack>
    </form>
  );
};
export default Role;
