import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import zoneReducer from "../features/zones/zonesSlice";
import userReducer from '../features/user/userSlice';
import lineReducer from '../features/line/lineSlice';
import productReducer from '../features/products/productsSlice'
import lineVariationSlice from "../features/lineVariation/lineVariationSlice";
import allUsersSlice from "../features/Users/allUsersSlice";
import permissionSlice from "../features/permission/permissionSlice";
import roleSlice from "../features/role/roleSlice";
import stagesSlice from "../features/stages/stagesSlice";
import mdevicesSlice from "../features/mdevices/mdevicesSlice";
import wdevicesSlice from "../features/mdevices/wdevicesSlice";
import shiftSlice from "../features/shift/shiftSlice";
import stepsSlice from "../features/ProductionResourceSteps/stepsSlice";
import ProductionResourceSlice from "../features/ProductionResourceSteps/ProductionResourceSlice";
import tenantSlice from "../features/tenant/tenantSlice";
import tenanturlSlice from "../features/tenant/tenanturlSlice";
import productstagesSlice from "../features/productstages/productstagesSlice";
import scheduleJobSlice from "../features/scheduleJob/scheduleJobSlice";
import wdevicesgetSlice from"../features/mdevices/wdevicesgetSlice";
import mdevicesgetSlice from"../features/mdevices/mdevicegetSlice";
import reportSlice from "../features/reports/reportSlice";
import workersSlice from "../features/workers/workersSlice";
import stepsqualitySlice from "../features/ProductionResourceSteps/stepsSliceQuality";
import skillSlice from "../features/skill/skillSlice";
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    zones: zoneReducer,
    user: userReducer,
    line: lineReducer,
    products: productReducer,
    lineVariation:lineVariationSlice,
    allusers : allUsersSlice,
    permission:permissionSlice,
    role:roleSlice,
    stages: stagesSlice,
    mdevices:mdevicesSlice,
    wdevices:wdevicesSlice,
    shift: shiftSlice,
    skill: skillSlice,
    steps: stepsSlice,
    productionResource: ProductionResourceSlice,
    tenant:tenantSlice,
    tenanturl:tenanturlSlice,
    productstages:productstagesSlice,
    scheduleJobs: scheduleJobSlice,
    wdevicesget:wdevicesgetSlice,
    mdevicesget:mdevicesgetSlice,
    reports: reportSlice,
    worker: workersSlice,
    stepsquality:stepsqualitySlice,
  },
});
