import React, { forwardRef } from "react";
import { Button, Typography, Box, Stack, Menu, MenuItem, IconButton } from "@mui/material";
import { Edit, Delete, ExpandMore, DragIndicator } from "@mui/icons-material";
import {
    setCurrentStepIndex,
    // changeStepsPosition,
    setStepEditable,
    setCurrentStepSubmitted,
    setEnableStepSubmitted
} from "../../../../features/ProductionResourceSteps/stepsSlice";
import { useSelector, useDispatch } from "react-redux";
import { useCssStateHook } from "../../../UIComponents/ComonCss";
import { setCurrentStepIndexQuality, setCurrentStepSubmittedQuality, setEnableStepSubmittedQuality, setStepEditableQuality } from "../../../../features/ProductionResourceSteps/stepsSliceQuality";

const Item = forwardRef(
  ({ id, withOpacity, isDragging, setShowDeleteModal, step, index, attributes, listeners, ...props }, ref) => {
  const dispatch = useDispatch();
  const { themeCssHeader} = useCssStateHook();
  const steps = useSelector((state) => state.steps.items);
  const currentStepIndex = useSelector((state) => state.steps.currentStepIndex);
  const enableNextStep = useSelector((state) => state.steps.enableNextStep);;
  // const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedStep, setSelectedStep] = React.useState(null);

  const handleMenuClick = (event, card) => {
    setAnchorEl(event.currentTarget);
    setSelectedStep(card);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedStep(null);
  };

  const handleEditClick = (index) => {
    if (selectedStep && selectedStep?.id) {
      dispatch(setStepEditable(true))
      dispatch(setStepEditableQuality(true))
      dispatch(setCurrentStepSubmitted(false))
      dispatch(setEnableStepSubmitted(false))
      dispatch(setEnableStepSubmittedQuality(false))
      dispatch(setCurrentStepSubmittedQuality(false))
      
    }
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
    handleMenuClose();
  };


    const colorCode = (step?.isStepSubmitted) ? '#B2DFDB' : '#EF9A9A';
    
    const inlineStyles = {
        // opacity: withOpacity ? '0.5' : '1',
        cursor: isDragging ? 'grabbing' : 'grab',
        transform: isDragging ? 'scale(1.05)' : 'scale(1)',
    };

    return (
      <div ref={ref} {...props} style={inlineStyles}>
        <Button
          sx={{
            color: "#000",
            backgroundColor: currentStepIndex === index ? colorCode : "#E6E9EC",
            padding: 0,
            borderColor: "#2E3646",
            "&:hover": {
              color: "#000",
              backgroundColor: currentStepIndex === index ? colorCode : "#E6E9EC",
            },
          }}
          disableRipple
          onClick={() => {
            dispatch(setCurrentStepIndex(index));
            dispatch(setCurrentStepIndexQuality(index));

            if (steps[index]?.id) {
              dispatch(setStepEditable(false));dispatch(setStepEditableQuality(false));
            }
          }}
          disabled={!enableNextStep && index !== currentStepIndex}
        >
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            m={0}
            p={0}
            height="100%"
          >
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              width="100%"
              alignItems="center"
              pl={2}
              sx={{
                color: "#fff",
                height: "40%",
                borderRadius: "10px 10px 0 0",
               ...themeCssHeader,
              }}
            >
              <Typography variant="body2">Step {index + 1}</Typography>
              <IconButton
                sx={{ width: "24px", height: "24px", color: "#fff" }}
                onClick={(e) => handleMenuClick(e, step)}
                aria-controls={`menu-${index}`}
                aria-haspopup="true"
                disabled={!enableNextStep && index !== currentStepIndex}
              >
                <ExpandMore />
              </IconButton>
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              width="100%"
              alignItems="center"
              height="60%"
            >
              <Box sx={!enableNextStep ? {pointerEvents: 'none'} : {}}>
                <DragIndicator
                  {...listeners}
                  {...attributes}
                  disabled={!enableNextStep}
                  sx={{':focus': {outline: 'none', boxShadow: 'none'}}}
                />
              </Box>
              <Typography variant="body1">
                {step?.name.length > 15
                  ? `${step?.name.slice(0, 12)}...`
                  : step?.name}
              </Typography>
            </Stack>
          </Stack>
        </Button>
        <Menu
          id={`menu-${index}`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl && selectedStep === step)}
          onClose={handleMenuClose}
        >
          {step?.id && (
            <MenuItem onClick={handleEditClick}>
              <Edit sx={{ fontSize: "16px", mr: 1 }} /> Edit
            </MenuItem>
          )}
          <MenuItem onClick={() => handleDeleteClick(step, index)}>
            <Delete sx={{ fontSize: "16px", mr: 1 }} /> Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }
);

export default Item;
