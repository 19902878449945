import React, {useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Typography, Button, createTheme, ThemeProvider, FormGroup, FormControl, TextField } from "@mui/material"
import { CosmeticaModal, CosmeticaTable, Loading } from "../UIComponents";
import { useParams } from "react-router-dom";
import { setPage, setRowsPerPage, setlineVariation } from "../../features/lineVariation/lineVariationSlice";
import useApi from "../../hooks/useAPI";
import muiStyles from "../../styles/MuiComponentStyles";
import { formatDateTime } from "../../utils/helperFunctions";
import { East } from "@mui/icons-material";
import { useCssStateHook } from "../UIComponents/ComonCss";
const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '40px',
                    color: '#2E3646'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: '#000',
                    padding: '9px 16px',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '22px',
                    textTransform: 'none',
                    '&:hover': {
                        color: '#000',
                        backgroundColor: '#fff'
                    }
                }
            }
        }
    }
})

const LinesVariation = () => {
    const { loading, getData, postData, patchData, deleteData } = useApi()
    const lineVariation = useSelector((state) => state.lineVariation.items);
    const totalLinesVariation = useSelector((state) => state.lineVariation.totalItems)
    const rowsPerPage = useSelector(state => state.lineVariation.rowsPerPage);
    const page = useSelector(state => state.lineVariation.page)
    const lineURL = `${process.env.REACT_APP_BASE_URL}/web/v2/line/variation`
    const { id } = useParams();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false)
    const {  themeCssButton,themeCssHeader} = useCssStateHook();
    const initialState = {
        desc: '',
        line_id: '',
        name: '',
      }
    const [editLineData, setEditLineData] = useState(initialState)
    const callAPI = async () => {
        try {
            getData(`${lineURL}/all/${id}/page/${page}/count/${rowsPerPage}`).then(data => {
                const linesInfo = data || [];
                dispatch(setlineVariation(linesInfo))

            })
        } catch (error) {
            console.log(error);
        }

    }
    React.useEffect(() => {
        callAPI()
        // eslint-disable-next-line
    }, [rowsPerPage,page]);
    const handleEdit = (id) => {
        const _lineVariation = lineVariation.find(d => d.id === id)
        setEditLineData({ id, name: _lineVariation.name, desc: _lineVariation.desc, short_desc: _lineVariation.created_at })
        setShowModal(true)
    }
    const handleCancel = () => {
        setShowModal(false);
        setEditLineData(initialState);
        // setIsEditId("")
    }
    const handleSubmit = async (e, line) => {
        e.preventDefault()
        e.target.reset()
        setEditLineData(initialState)
        setShowModal(false)
        if (line?.id) {
            patchData(`${lineURL}/update`, line).then((res) => {
                callAPI()
            }).catch((err) => {
                console.log("Error ", err)
            })

        } else {
            postData(`${lineURL}/create`, {...line, line_id: Number(id)}).then((res) => {
                callAPI()
            }).catch((err) => {
                console.log("Error ", err)
            })
        }
    }
    const handleDelete = async (id) => {
        deleteData(`${lineURL}/delete/${id}`).then((res) => {
            callAPI()
        }).catch((err) => {
            console.log("Error ", err)
        })
      
    }
    const handlePageChange = (newPage) => {
        dispatch(setPage(newPage))
      }
      const handleRowsPerPageChange = (newRowsPerPage) => {
        dispatch(setRowsPerPage(newRowsPerPage))
        dispatch(setPage(1))
      }

    const fields = ['Schematic Type', 'Description','Created Date']
    const getRows = (data) => data.map(row => {
        const { id, name, desc,created_at } = row
        return { id, name, desc, created_at:formatDateTime(created_at)}
    })
    return loading ? (
        <Loading/>
    ) : (
        <Box>
            <ThemeProvider theme={theme}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mt={5}>
                    <Typography>Schematic Type</Typography>
                    <Button variant="contained" onClick={() => { setShowModal(true) }} sx={themeCssButton}>Add Schematic Type</Button>
                </Stack>
            </ThemeProvider>
            <Box sx={{ mt: 4 }}>
                <CosmeticaTable
                    fields={fields}
                    rows={getRows(lineVariation)}
                    isDelete
                    isEdit
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsCount={totalLinesVariation}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    isHeadercss={themeCssHeader}
                    isSearch
                />
            </Box>
            {showModal &&
                <CosmeticaModal
                    showModal={showModal}
                    title={editLineData?.id ? "Edit Schematic Type" : "Add Schematic Type"}
                    closeModal={handleCancel}
                >
                    <LineForm
                        handleClose={() => { setShowModal(false); setEditLineData(initialState) }}
                        handleSubmit={handleSubmit}
                        lineVariationData={editLineData}
                        handleCancel={handleCancel}
                        isHeadercss={themeCssButton}
                    />
                </CosmeticaModal>
            }
        </Box>
    );
}
const LineForm = ({ handleSubmit, lineVariationData, handleCancel ,isHeadercss}) => {
    const [linevr, setLineVr] = React.useState(lineVariationData)
    const validata ={
        name:false,
        desc:false,
        
    }
    const [isNumberEntered, setIsNumberEntered] = useState(validata);
    const handleChange = (e) => {
        // const validName = /^[A-Za-z\s]+$/.test(e.target.value);
        const validName = /^.*$/.test(e.target.value);
      
      
        if(!validName){
            setIsNumberEntered((prevdata) => { return { ...prevdata, [e.target.name]: !validName} });
           } else {
            setIsNumberEntered((prevdata) => { return { ...prevdata, [e.target.name]: !validName} });
           }
        setLineVr((prevZone) => { return { ...prevZone, [e.target.name]: e.target.value } })
    }
    const buttonStyle = {
        backgroundColor: '#2E3646',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#2E3646',
            color: '#fff',
            opacity: 0.7,
        }
    }
    const  handleerror=(e)=>{
        e.preventDefault()
        if(!isNumberEntered.name && !isNumberEntered.desc){
            handleSubmit(e, linevr)
        }
    }
    return <>
        <form style={{ marginTop: "20px" }} onSubmit={(e) =>handleerror(e) }>
            <FormGroup>
                <FormControl sx={{ mt: 2 }}>
                    <TextField
                        label="Name"
                        name="name"
                        value={linevr.name}
                        variant="outlined"
                        onChange={handleChange}
                         required
                         error={isNumberEntered.name} />
                          {isNumberEntered.name && (
                        <p style={{ color: 'red' }}>Please enter a valid name without numbers.</p>
                    )}
                </FormControl>
                <FormControl sx={{ mt: 2 }}>
                    <TextField
                        label="Desc"
                        name="desc"
                        value={linevr.desc}
                        variant="outlined"
                        onChange={handleChange} required 
                        error={isNumberEntered.desc}/>
                         {isNumberEntered.desc && (
                        <p style={{ color: 'red' }}>Please enter a valid name without numbers.</p>
                    )}
                </FormControl>
            </FormGroup>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
                <Button
                    type="reset"
                    variant="text"
                    size="large"
                    onClick={() => handleCancel()}
                    sx={muiStyles.secondaryButton}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    // sx={buttonStyle}
                    sx={ isHeadercss || buttonStyle}
                    endIcon={<East />}
                >
                    {linevr.id ? "Update" : "Add"}
                </Button>
            </Stack>
        </form>
    </>
}
export default LinesVariation;