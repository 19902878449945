import React from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import useApi from '../../hooks/useAPI'
import { CosmeticaTable } from '../UIComponents'
import muiStyles from '../../styles/MuiComponentStyles'
import { formatDate } from '../../utils/helperFunctions'
import { useCssStateHook } from "../UIComponents/ComonCss";

const ProductDetails = () => {
    const location = useLocation()
    const { getData } = useApi();
    const [productionResoures, setProductionResources] = React.useState([]);
    const productionResourceUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/production/resource`
    const {  themeCssHeader} = useCssStateHook();

    React.useEffect(() => {
        try {
        const response = getData(
            `${productionResourceUrl}/all/${location.state.product.id}`
        );
        response.then((data) => {
            setProductionResources(data);
        });
        } catch (error) {
        console.log(error);
        }
    }
    // eslint-disable-next-line
    , []);

    const fields = [
        "Name",
        "Description",
        "Step Count",
        "Created Date",
    ];
    const rows = productionResoures.map((productionResource) => {
        const { id, desc, created_at, name, step_count } = productionResource;
        return { id, name, desc, step_count, created_at: formatDate(created_at) };
    });
  return (
    <>
        <Box mt={3}>
            <Typography sx={muiStyles.pageTitle}>{location.state.product.product_code}</Typography>
            <Typography>{location.state.product.desc}</Typography>
        </Box>
        <Box mt={3}>
            <CosmeticaTable fields={fields} rows={rows} pagination={false} 
            isHeadercss={themeCssHeader}
            
            />
        </Box>
    </>
  )
}

export default ProductDetails