import useApi from "../../../hooks/useAPI";
import ClassificationTable from "./ClassificationTable";
import FillTableOne from "./FillTableOne";
import FillTableTwo from "./FillTableTwo";
import HeaderTable from "./HeaderTable";
import StandardTable from "./StandardTable";
import reportData from "./data";
import "./qualityReports.css";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const styles = {
  container: {
    marginTop: "4rem",
  },
  buttonContainer: {
    textAlign: "right",
    marginBottom: "2rem",
  },
};

const QualityReport = () => {
  const { id: reportId } = useParams();
  const { getData } = useApi();
  const tableRef = useRef(null);
  const user = useSelector((state) => state.user.user);
  const [resportData, setReportData] = useState(null);
  // const {filling, packing, quality_doc, aql, readings, inspector_name} = reportData
  useEffect(() => {
    if (user.tenant_id && user.zone_id) {
      fetchQualityReportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.tenant_id, user.zone_id]);

  const exportToPdf = () => {
    if (window.html2pdf) {
      const printOptions = {
        margin: 0.25,
        filename: `QualityReportSheet-${reportId}.pdf`,
        image: { type: "png", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      const element = document.getElementById("printableQualityReportEl");
      window.html2pdf().set(printOptions).from(element).save();
      return;
    }
    const printableWindow = window.open("", "_blank");
    printableWindow.document.write(
      "<html><head><title>Print</title><style type='text/css'>" +
        "body {" +
        "  position: fixed;" +
        "  left: 0;" +
        "  right: 0;" +
        "  top: 0;" +
        "  bottom: 0;" +
        "  border: 1px solid #dedede;" +
        "  padding: 16px;" +
        "}" +
        "#printable-logo{" +
        "  display: block !important;" +
        "}" +
        "table, th, td {" +
        "  border: 1px solid #dedede;" +
        "  padding: 8px;" +
        "  margin: 8px;" +
        "}" +
        "table { width: 100% }" +
        "</style></head><body>"
    );
    printableWindow.document.write(tableRef.current.outerHTML);
    printableWindow.document.write("</body></html>");
    printableWindow.document.close();
    printableWindow.print();
  };

  const fetchQualityReportData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/web/v2/quality/report/${reportId}`;
    try {
      const response = await getData(url);
      // console.log(response)
      // console.log('Parsed', JSON.parse(response?.quality_data))
      setReportData(JSON.parse(response?.quality_data));
    } catch (error) {
      console.log(error);
    }
  };

  // console.log('REPort')

  return (
    <div style={styles.container}>
      <div style={styles.buttonContainer}>
        <Button variant="contained" color="primary" onClick={exportToPdf}>
          Export to PDF
        </Button>
      </div>
      <div
        className="c89 doc-content"
        id="printableQualityReportEl"
        ref={tableRef}
      >
        <HeaderTable />
        <p className="c69">
          <span className="c9" />
        </p>
        <StandardTable quality_doc={resportData?.quality_doc ?? {}} />
        <p className="c81 c112">
          <span className="c104" />
        </p>
        <p className="c1">
          <span className="c2" />
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <ClassificationTable aql={resportData?.aql ?? []} />
          {/* <hr style="page-break-before:always;display:none;"> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <p className="c84">
              <span className="c22">
                Legend: C = Critical; M= Major; m= minor
              </span>
            </p>
            <p className="c37">
              <span
                style={{
                  display: "inline-block",
                  border: "1px solid #000000",
                  width: "236.67px",
                  padding: "2px",
                  marginTop: "6px",
                }}
              >
                <p>
                  <strong>
                    <u>Frequency</u>: 20 samples at start of the line then use
                    AQL sample size based on shift quantity every 1/2 hour.
                  </strong>
                </p>
              </span>
            </p>
          </div>
        </div>
        <p className="c66">
          <span className="c108" />
        </p>
        <ul className="c87">
          <li className="c100">
            <span style={{ marginRight: "2rem" }}>
              {!resportData?.filling ? (
                <CheckBoxOutlineBlank />
              ) : (
                <CheckBoxOutlined />
              )}
            </span>
            <span className="c22">FILLING</span>
          </li>
          <li className="c100" style={{ marginTop: "1rem" }}>
            <span style={{ marginRight: "2rem" }}>
              {!resportData?.packing ? (
                <CheckBoxOutlineBlank />
              ) : (
                <CheckBoxOutlined />
              )}
            </span>
            <span className="c22">PACKING</span>
          </li>
        </ul>
        <FillTableOne
          readings={resportData?.readings}
          inspector_name={resportData?.inspector_name}
        />
        <p className="c1">
          <span className="c2" />
        </p>
        <p className="c69">
          <span className="c30" />
        </p>
        <p className="c1">
          <span className="c2" />
        </p>
        <p className="c69">
          <span className="c30" />
        </p>
        <p className="c1">
          <span className="c2" />
        </p>
        <p className="c69">
          <span className="c30" />
        </p>
        <HeaderTable />
        <p className="c1">
          <span className="c2" />
        </p>
        <p className="c69">
          <span className="c30" />
        </p>
        <FillTableTwo readings={resportData?.readings} />
        <p className="c66">
          <span className="c22" />
        </p>
        <p className="c62">
          <span className="c22">
            REMARKS: Write comments based on the results found during AQL
            inspections and corrective and preventative action taken
          </span>
        </p>
        {/* <p className="c60">
            <span
              style={{
                overflow: "hidden",
                display: "inline-block",
                margin: "0.00px 0.00px",
                border: "0.00px solid #000000",
                transform: "rotate(0.00rad) translateZ(0px)",
                WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                width: "672.00px",
                height: "1.33px",
              }}
            >
              <img
                alt=""
                src="images/image2.png"
                style={{
                  width: "672.00px",
                  height: "1.33px",
                  marginLeft: "0.00px",
                  marginTop: "0.00px",
                  transform: "rotate(0.00rad) translateZ(0px)",
                  WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                }}
                title=""
              />
            </span>
            <span
              style={{
                overflow: "hidden",
                display: "inline-block",
                margin: "0.00px 0.00px",
                border: "0.00px solid #000000",
                transform: "rotate(0.00rad) translateZ(0px)",
                WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                width: "672.00px",
                height: "1.33px",
              }}
            >
              <img
                alt=""
                src="images/image2.png"
                style={{
                  width: "672.00px",
                  height: "1.33px",
                  marginLeft: "0.00px",
                  marginTop: "0.00px",
                  transform: "rotate(0.00rad) translateZ(0px)",
                  WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                }}
                title=""
              />
            </span>
            <span
              style={{
                overflow: "hidden",
                display: "inline-block",
                margin: "0.00px 0.00px",
                border: "0.00px solid #000000",
                transform: "rotate(0.00rad) translateZ(0px)",
                WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                width: "672.00px",
                height: "1.33px",
              }}
            >
              <img
                alt=""
                src="images/image2.png"
                style={{
                  width: "672.00px",
                  height: "1.33px",
                  marginLeft: "0.00px",
                  marginTop: "0.00px",
                  transform: "rotate(0.00rad) translateZ(0px)",
                  WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                }}
                title=""
              />
            </span>
            <span
              style={{
                overflow: "hidden",
                display: "inline-block",
                margin: "0.00px 0.00px",
                border: "0.00px solid #000000",
                transform: "rotate(0.00rad) translateZ(0px)",
                WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                width: "672.00px",
                height: "1.33px",
              }}
            >
              <img
                alt=""
                src="images/image2.png"
                style={{
                  width: "672.00px",
                  height: "1.33px",
                  marginLeft: "0.00px",
                  marginTop: "0.00px",
                  transform: "rotate(0.00rad) translateZ(0px)",
                  WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                }}
                title=""
              />
            </span>
            <span
              style={{
                overflow: "hidden",
                display: "inline-block",
                margin: "0.00px 0.00px",
                border: "0.00px solid #000000",
                transform: "rotate(0.00rad) translateZ(0px)",
                WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                width: "672.00px",
                height: "1.33px",
              }}
            >
              <img
                alt=""
                src="images/image2.png"
                style={{
                  width: "672.00px",
                  height: "1.33px",
                  marginLeft: "0.00px",
                  marginTop: "0.00px",
                  transform: "rotate(0.00rad) translateZ(0px)",
                  WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                }}
                title=""
              />
            </span>
            <span
              style={{
                overflow: "hidden",
                display: "inline-block",
                margin: "0.00px 0.00px",
                border: "0.00px solid #000000",
                transform: "rotate(0.00rad) translateZ(0px)",
                WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                width: "672.00px",
                height: "1.33px",
              }}
            >
              <img
                alt=""
                src="images/image2.png"
                style={{
                  width: "672.00px",
                  height: "1.33px",
                  marginLeft: "0.00px",
                  marginTop: "0.00px",
                  transform: "rotate(0.00rad) translateZ(0px)",
                  WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                }}
                title=""
              />
            </span>
            <span
              style={{
                overflow: "hidden",
                display: "inline-block",
                margin: "0.00px 0.00px",
                border: "0.00px solid #000000",
                transform: "rotate(0.00rad) translateZ(0px)",
                WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                width: "672.00px",
                height: "1.33px",
              }}
            >
              <img
                alt=""
                src="images/image2.png"
                style={{
                  width: "672.00px",
                  height: "1.33px",
                  marginLeft: "0.00px",
                  marginTop: "0.00px",
                  transform: "rotate(0.00rad) translateZ(0px)",
                  WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                }}
                title=""
              />
            </span>
          </p> */}
        <p className="c32">
          <span className="c30" style={{fontSize:"14px",marginLeft:"10px",marginTop:"7px"}}>{resportData && resportData?.note}</span>
        </p>
        <p className="c32">
          <span className="c30" />
        </p>
        <p className="c32">
          <span className="c30" />
        </p>
        <p className="c32">
          <span className="c30" />
        </p>
        <p className="c32">
          <span className="c30" />
        </p>
        <p className="c32">
          <span className="c30" />
        </p>
        <p className="c32">
          <span className="c30" />
        </p>
        <p className="c53">
          <span className="c22" />
        </p>
        <p className="c1">
          <span className="c30" />
        </p>
        <p className="c91">
          <span className="c22">
            Reviewed By QA: {reportData.inspector_name}
          </span>
          <span className="c68">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span className="c22">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span className="c68">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </p>
        <p className="c1">
          <span className="c30" />
        </p>
        <p className="c1">
          <span className="c30" />
        </p>
        <p className="c1">
          <span className="c30" />
        </p>
        <p className="c12">
          <span
            style={{
              display: "inline-block",
              width: "100%",
              height: "18.67px",
              borderTop: "1px solid #000",
              backgroundColor: "#6699ff",
            }}
          >
            <p>
              <strong>Electronic Signature</strong>
            </p>
          </span>
        </p>
        <p className="c118">
          <span className="c35">
            Bella Recto;Review;Feb 15, 2023 12:52:55 PM EST
          </span>
        </p>
        <p className="c107">
          <span className="c64">
            &nbsp;Vignesh Prakasam;Final Approval;Feb 21, 2023 9:25:44 PM
            EST&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </p>
      </div>
    </div>
  );
};

export default QualityReport;

// Frequency: 20 samples at start of the line then use AQL sample size based on shift quantity every 1⁄2 hour.
