import {
  setIsEdit,
  setEnableStepSubmitted,
  setCurrentStepSubmitted,
} from "../../features/ProductionResourceSteps/stepsSlice";
import { setCurrentStepSubmittedQuality, setEnableStepSubmittedQuality, setIsEditQuality } from "../../features/ProductionResourceSteps/stepsSliceQuality";
import useApi from "../../hooks/useAPI";
import muiStyles from "../../styles/MuiComponentStyles";
import { FormFieldsTheme } from "../../styles/MuiThemes";
import { compareObjectsWithProperties } from "../../utils/helperFunctions";
import { Loading } from "../UIComponents";
import {
  ProductionResourceStepForm,
  ProductionResourceForm,
} from "./components";
import {
  Box,
  Typography,
  ThemeProvider,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const EditProductionResource = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const steps = useSelector((state) => state.steps.items);
  const stepsquality = useSelector((state) => state.stepsquality.items);
  const currentStepIndex = useSelector((state) => state.steps.currentStepIndex);
  const currentStepqualityIndex = useSelector((state) => state.stepsquality.currentStepIndex);
  const [resource, setResource] = React.useState(location.state.resource);
  const { patchData, postData } = useApi();
  const [loading, setLoading] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState("Loading...");
  const productionUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/production`;
  const mediaUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/media`;
  const questionUrl = `${productionUrl}/step/question`;
  const productionUrln = `${process.env.REACT_APP_BASE_URL}/web/v2/production`;

  dispatch(setIsEdit(true));
  dispatch(setIsEditQuality(true));
  dispatch(setEnableStepSubmitted(true));
  dispatch(setEnableStepSubmittedQuality(true));

  const handleAddResourceSubmit = (e, _resource) => {
    e.preventDefault();
    try {
      patchData(`${productionUrl}/resource/update`, _resource).then((data) => {
        setResource(data);
        navigate(`/products`);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createMedia = (child_id, media_type, child = "production:step") => {
    const req_data = {
      child,
      child_id: child_id,
      parent: "production:resource",
      parent_id: resource.id,
      media_type: media_type.split("/")[0],
      tenant_id: user.tenant_id,
      zone_id: user.zone_id,
    };
    return postData(`${mediaUrl}/create`, req_data);
  };
  const createMediastep = (child_id, media_type, child) => {
    const req_data = {
      child,
      child_id: child_id,
      parent: "production:resource",
      parent_id: resource.id,
      media_type: media_type.split("/")[0],
      tenant_id: user.tenant_id,
      zone_id: user.zone_id,
    };
    return postData(`${mediaUrl}/create`, req_data);
  };
  const createMediaQuality = async (child_id, media_type, child = "production:step:qa") => {
    const req_data = {
      child,
      child_id: child_id,
      parent: "production:resource",
      parent_id: resource.id,
      media_type: media_type.split("/")[0],
      tenant_id: user.tenant_id,
      zone_id: user.zone_id,
    };
  
    // Make the API call
    const responseData = await postData(`${mediaUrl}/create`, req_data);
    // Extract the AWS URL from the response and add it to the response data
    responseData.aws_url = responseData.aws_url || ''; // Ensure aws_url is always present
    return responseData;
  };

  const uploadMedia = async (res, file) => {
    const fileHeaders = new Headers();
    fileHeaders.append("Content-Type", file.type);
    const fileContent = file.file;
    const requestOptions = {
      method: "PUT",
      headers: fileHeaders,
      body: fileContent,
    };
    await fetch(res.aws_url, requestOptions).then(async (resp) => {
      if (resp.ok) {
        const req_data = res;
        req_data.name = file.name || "";
        req_data.tag = file.tags || "";
        delete res.aws_url;
        await postData(`${mediaUrl}/success`, req_data);
      }
    });
  };

  const handleStepSubmit = async (e, step) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (step.id) {
        // Step details
        if (
          compareObjectsWithProperties(step, steps[currentStepIndex], [
            "name",
            "desc",
            "max_crew",
            "min_crew",
          ])
        ) {
          setLoading("Updating step details...");
          const stepDetails = {
            name: step.name,
            id: step.id,
            desc: step.desc,
            max_crew: Number(step.max_crew),
            min_crew: Number(step.min_crew),
          };
          await patchData(`${productionUrl}/step/update`, stepDetails);
        }

        // Video
        if (step.media.video.file) {
          setLoadingText("Uploading Video...");
          await createMedia(step.id, step.media.video.type).then(
            async (res) => {
              await uploadMedia(res, step.media.video);
            }
          );
        }

        // Images
        setLoadingText("Uploading Images...");
        for (let image of step.media.images) {
          if (image.file) {
            await createMedia(step.id, image.type).then(async (res) => {
              await uploadMedia(res, image);
            });
          }
        }
        for (let image of step.media.good) {
          if (image.file) {
            await createMediastep(step.id, image.type,image.child).then(async (res) => {
              await uploadMedia(res, image);
            });
          }
        }
        for (let image of step.media.bad) {
          if (image.file) {
            await createMediastep(step.id, image.type,image.child).then(async (res) => {
              await uploadMedia(res, image);
            });
          }
        }
        for (let image of step.media.reference) {
          if (image.file) {
            await createMediastep(step.id, image.type,image.child).then(async (res) => {
              await uploadMedia(res, image);
            });
          }
        }

        // Questions
        for (let question of step.questions) {
          if (!question.id) {
            const options = question.options.map((option) => {
              const obj = {
                has_image: !!option.image.file,
                is_correct: option.is_correct,
                option: option.option,
              };
              return obj;
            });
            const questionObject = {
              has_image: !!question.image.file,
              options: options,
              question: question.question,
            };
            setLoadingText("Uploading Questions...");
            const req_data = JSON.parse(
              JSON.stringify({
                ...questionObject,
                production_resource_id: step.production_resource_id,
                production_resource_step_id: step.id,
              })
            );
            await postData(`${questionUrl}/create`, {
              questions: req_data,
            }).then(async (data) => {
              if (question.image.file) {
                await createMedia(
                  data.question.id,
                  question.image.type,
                  "production:question"
                ).then(async (res) => {
                  await uploadMedia(res, question.image);
                });
              }
              for (let option of question.options) {
                if (option.image.file) {
                  const respOption = data.options.find(
                    (op) => op.option === option.option
                  );
                  await createMedia(
                    respOption.id,
                    option.image.type,
                    "production:option"
                  ).then(async (res) => {
                    await uploadMedia(res, option.image);
                  });
                }
              }
            });
          }
        }
        dispatch(setEnableStepSubmitted(true));
        dispatch(setCurrentStepSubmitted(true));
      } else {
        const req_data = {
          desc: step.desc,
          max_crew: +step.max_crew,
          min_crew: +step.min_crew,
          name: step.name,
          position: step.position,
          production_resource_id: step.production_resource_id,
        };
        setLoadingText("Creating Step...");
        await postData(`${productionUrl}/step/create`, req_data).then(
          async (data) => {
            // Video
            if (step.media.video.file) {
              setLoadingText("Uploading Video...");
              await createMedia(data.child_id, step.media.video.type).then(
                async (res) => {
                  await uploadMedia(res, step.media.video);
                }
              );
            }
            // Images
            setLoadingText("Uploading Images...");
            for (let image of step.media.images) {
              if (image.file) {
                await createMedia(data.child_id, image.type).then(async (res) => {
                  await uploadMedia(res, image);
                });
              }
            }
            for (let image of step.media.good) {
              if (image.file) {
                await createMediastep(data.child_id, image.type,image.child).then(async (res) => {
                  await uploadMedia(res, image);
                });
              }
            }
            for (let image of step.media.bad) {
              if (image.file) {
                await createMediastep(data.child_id, image.type,image.child).then(async (res) => {
                  await uploadMedia(res, image);
                });
              }
            }
            for (let image of step.media.reference) {
              if (image.file) {
                await createMediastep(data.child_id, image.type,image.child).then(async (res) => {
                  await uploadMedia(res, image);
                });
              }
            }
            // Questions
            for (let question of step.questions) {
              setLoadingText("Uploading Questions...");
              const options = question.options.map((option) => {
                const obj = {
                  has_image: !!option.image.file,
                  is_correct: option.is_correct,
                  option: option.option,
                };
                return obj;
              });
              const questionObject = {
                has_image: !!question.image.file,
                options: options,
                question: question.question,
              };
              const req_data = JSON.parse(
                JSON.stringify({
                  ...questionObject,
                  production_resource_id: step.production_resource_id,
                  production_resource_step_id: step.id,
                })
              );
              await postData(`${questionUrl}/create`, {
                questions: req_data,
              }).then(async (data) => {
                if (question.image.file) {
                  await createMedia(
                    data.question.id,
                    question.image.type,
                    "production:question"
                  ).then(async (res) => {
                    await uploadMedia(res, question.image);
                  });
                }
                for (let option of question.options) {
                  if (option.image.file) {
                    const respOption = data?.options?.find(
                      (op) => op?.option === option.option
                    );
                    await createMedia(
                      respOption?.id,
                      option.image.type,
                      "production:option"
                    ).then(async (res) => {
                      await uploadMedia(res, option.image);
                    });
                  }
                }
              });
              // setLoadingText('Uploading Questions...')
              // const req_data = JSON.parse(JSON.stringify({...question, production_resource_id: step.production_resource_id, production_resource_step_id: data.step_id}))
              // await postData(`${questionUrl}/create`, {questions: req_data})
            }

            dispatch(setEnableStepSubmitted(true));
            dispatch(setCurrentStepSubmitted(true));
          }
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setLoadingText("Loading...");
    }
  };
  const handleSubmitQuality = async (e,currentStep ,currentStepquality) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (currentStepquality.isNewStep !== true) {
        // Step details
        if (
          compareObjectsWithProperties(currentStepquality, stepsquality[currentStepqualityIndex], [
            "name",
            "desc",
          ])
        ) {
          setLoading("Updating step details...");
          const stepDetails = {
            name: currentStepquality.name,
            id: currentStep.id,
            desc: currentStepquality.desc,
          };
          await patchData(`${productionUrln}/step/quality/alerts/update`, stepDetails);
        }


        // Images
        setLoadingText("Uploading Images...");
        for (let image of currentStep.media.images) {
          if (image.file) {
            await createMediaQuality(currentStep.id, image.type).then(async (res) => {
              await uploadMedia(res, image);
            });
          }
        }

       
        dispatch(setEnableStepSubmittedQuality(true));
        dispatch(setCurrentStepSubmittedQuality(true));
      } else {
        const req_data = {
          desc: currentStepquality.desc,
          name: currentStepquality.name,
          // position: step.position,
          // production_resource_id: step.production_resource_id,
          production_resource_step_id: currentStep.id,
        };
        setLoadingText("Creating Step...");
        await postData(`${productionUrln}/step/quality/alerts/create`, req_data).then(
          async (data) => {
            // Images
            setLoadingText("Uploading Images...");
            for (let image of currentStepquality.media.images) {
              if (image.file) {
                await createMediaQuality(data.quality_alert_id, image.type).then(async (res) => {
                  await uploadMedia(res, image);
                });
              }
            }
            dispatch(setEnableStepSubmittedQuality(true));
            dispatch(setCurrentStepSubmittedQuality(true));
          }
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setLoadingText("Loading...");
    }
  };

  return (
    <>
      {loading && (
        <Loading open={loading} description={loadingText}>
          <Box
            sx={{
              width: "300px",
              height: "200px",
              background: "#fff",
              borderRadius: "6px",
            }}
          >
            <Typography
              variant="h5"
              style={{
                color: "#000",
                borderBottom: "1px solid black",
                padding: "5px 10px",
              }}
            >
              Submitting Step
            </Typography>
            <Box
              sx={{
                height: "200px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="success" sx={{ mt: 2 }} />
              <Typography
                variant="body1"
                style={{ marginTop: "16px", color: "#000" }}
              >
                {loadingText}
              </Typography>
            </Box>
          </Box>
        </Loading>
      )}
      <ThemeProvider theme={FormFieldsTheme}>
        <Box mt={3}>
          <Typography sx={muiStyles.pageTitle}>
            Edit Production Resource
          </Typography>
          <Box>
            <ProductionResourceForm
              handleSubmit={handleAddResourceSubmit}
              initialResource={resource}
            />
          </Box>
          <Box>
            <ProductionResourceStepForm
              setLoading={setLoading}
              handleSubmit={handleStepSubmit}
              resource={resource}
              handleSubmitQuality={handleSubmitQuality}
            />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default EditProductionResource;
