import Weeks from "./Weeks";
import { Grid, Box, TextField } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const Calender = ({ fetchWorkOrders }) => {
  const [filter, setFilter] = React.useState("");
  const workOrders = useSelector((state) => state.scheduleJobs.workOrders);

  const handleFilterChange = (event) => {
    const inputValue = event.target.value;
    setFilter(inputValue);
  };

  return (
    <Box mt={3}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          my: 1,
          mr: 0.5,
        }}
      >
        <TextField
          id="search"
          variant="outlined"
          type="text"
          placeholder="Search......"
          value={filter}
          onChange={handleFilterChange}
          style={{
            width:'300px'
          }}
        />
      </Box>
      <Grid container spacing={0.5}>
        {workOrders
          .map((workOrder) => {
            const filterdWorkOrders = workOrder["work_order_by_week"]?.filter(
              (i) => {
                const keys = Object.keys(i);
                let flag = false;
                keys.forEach((k) => {
                  if (i[k]?.toLowerCase?.()?.includes(filter?.toLowerCase())) {
                    flag = true;
                  }
                });
                return flag;
              }
            );
            return {
              week: workOrder.week,
              work_order_by_week: filterdWorkOrders,
            };
          })
          .map((week) => (
            <Weeks
              key={week.week}
              week={week}
              fetchWorkOrders={fetchWorkOrders}
            />
          ))}
      </Grid>
    </Box>
  );
};

export default Calender;
