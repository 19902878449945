import { setProducts, setPage, setRowsPerPage } from "../../features/products/productsSlice";
import { setStages } from "../../features/stages/stagesSlice";
import useApi from "../../hooks/useAPI";
import muiStyles from "../../styles/MuiComponentStyles";
import { CosmeticaTable, Loading } from "../UIComponents";
import { Box, Stack, Typography, Button } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { useCssStateHook } from "../UIComponents/ComonCss";
import { formatDate } from '../../utils/helperFunctions'

const Products = () => {
  // eslint-disable-next-line
  const products = useSelector((state) => state.products.items);
  const rowsPerPage = useSelector(state => state.products.rowsPerPage);
  const page = useSelector(state => state.products.page)
  const totalZones = useSelector((state) => state.products.totalItems)
  const user = useSelector((state) => state.user.user);
  const stages = useSelector((state) => state.stages.items);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, getData } = useApi();
  const productUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/product`;
  const { themeCssHeader} = useCssStateHook();

  React.useEffect(() => {
    if (user.tenant_id && user.zone_id) {
      fetchProducts();
      fetchStages();
    }
    // eslint-disable-next-line
  }, [user]);

  const fetchProducts = async () => {
    if(user.tenant_id !=null && user.zone_id !== null) {
      try {
        const response = await getData(
          `${productUrl}/all/${user.tenant_id}/${user.zone_id}`
        );
        dispatch(setProducts(response || []));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchStages = async () => {
    if(user.tenant_id != null && user.zone_id != null) {
      try {
        const response = await getData(`${productUrl}/stages/all/${user.tenant_id}/${user.zone_id}`)
        dispatch(setStages(response || []));
      } catch (error) {
          console.log(error);
      }
    }
  };

  const mapStage = (stageId) => {
    const stage = stages.find(stage => stage.id === stageId)
    return stage?.name || ''
  }

  const fields = [
    "Product Code",
    "Product Description",
    "MFPS ID",
    "Product Stage",
    "Created Date",
  ];
  const rows = products.map((row) => {
    const {
      id,
      product_code,
      specification,
      desc,
      production_stage_id,
      created_at,
    } = row;
    return {
      id,
      product_code: <NavLink style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}} to={`/products/${id}`} state={{product: row}}>{product_code}</NavLink>,
      desc,
      specification,
      production_stage_id: <Typography sx={{height: '20px', padding: '3px 10px', backgroundColor: '#E3ECFA', borderRadius: '5px', boxSizing: 'content-box', display: 'inline'}}>{mapStage(production_stage_id)}</Typography>,
      created_at: formatDate(created_at),
    };
  });

  const handleEdit = (id) => {
    let product = products.find((item) => item.id === id);
    const {
      id: product_id,
      product_code,
      desc,
      specification,
      production_stage_id,
      specification_revision,
    } =  product
    product = {
      product_id,
      product_code,
      desc,
      specification,
      production_stage_id,
      specification_revision,
    }
    navigate(`/products/edit-product/${id}`, { state: { product } });
  };

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage))
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    dispatch(setRowsPerPage(newRowsPerPage))
  }

  return loading ? (
    <Loading/>
  ) : (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={5}
      >
        <Typography sx={muiStyles.pageTitle}>Products</Typography>
        <Button
          variant="contained"
          sx={muiStyles.primaryButton}
          component={NavLink}
          to="/products/add-product"
        >
          Add Product
        </Button>
      </Stack>
      <Box sx={{ mt: 4 }}>
        <CosmeticaTable
          fields={fields}
          rows={rows}
          isEdit
          handleEdit={handleEdit}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          rowsCount={totalZones}
          page={page}
          rowsPerPage={rowsPerPage}
          isHeadercss={themeCssHeader}
        />
      </Box>
    </Box>
  );
};

export default Products;
