import useApi from "../../hooks/useAPI";
import muiStyles from "../../styles/MuiComponentStyles";
import { CosmeticaTable, Loading } from "../UIComponents";
import {AddEditProductForm} from "./components";
import { Box, Stack, Button, Typography } from "@mui/material";
import React from "react";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { formatDate } from '../../utils/helperFunctions'
import { useCssStateHook } from "../UIComponents/ComonCss";


const AddEditProduct = () => {
  const { getData, deleteData, loading } = useApi();
  const { productId } = useParams();
  const navigate = useNavigate();
  const [productionResoures, setProductionResources] = React.useState([]);
  const productionResourceUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/production/resource`
  const { themeCssHeader} = useCssStateHook();

  const fetchProductResources = async () => {
    try {
      const response = await getData(
        `${productionResourceUrl}/all/${productId}`
      );
      setProductionResources(response)
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    (async () => {
      if (productId) {
        await fetchProductResources()
      }
    })()
    // eslint-disable-next-line
  }, []);

  const handleEdit = (resourceId) => {
    let resource = productionResoures.find(
      (resource) => resource.id === resourceId
    );
    const { id, name, desc, line_id, line_variation_id } = resource;
    resource = { id, name, desc, line_id, line_variation_id };
    navigate(`/production-resouce/edit/${resource.id}`, {
      state: { resource, productId },
    });
  };

  const handleDelete = (id) => {
    deleteData(`${productionResourceUrl}/delete/${id}`, '/products')
  };

  const fields = [
    "Name",
    "Description",
    "Step Count",
    "Created Date",
  ];
  const rows = productionResoures.map((productionResource) => {
    const { id, desc, created_at, name, step_count } = productionResource;
    return { id, name: <NavLink style={{color: 'black', textDecoration: 'none'}} to={`/production-resouce/${id}`} state={{resource: productionResource}}>{name}</NavLink>, desc, step_count, created_at: formatDate(created_at) };
  });

  if (loading) {
    return <Loading />
  }

  return (
      <Box>
        <AddEditProductForm />
        <Box mt={10}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={5}
          >
            <Typography sx={muiStyles.pageTitle}>
              Production Resource
            </Typography>
            <Button
              variant="contained"
              sx={muiStyles.primaryButton}
              onClick={() => {
                navigate("/production-resouce/add", {
                  state: { productId },
                });
              }}
              disabled={!productId}
            >
              Add Production Resource
            </Button>
          </Stack>
          <Box sx={{ mt: 4 }}>
            <CosmeticaTable
              fields={fields}
              rows={rows}
              isEdit
              isDelete
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            isHeadercss={themeCssHeader}
            />
          </Box>
        </Box>
      </Box>
  );
};

export default AddEditProduct;
