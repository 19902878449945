import { createTheme } from "@mui/material";

export const MultiStepTheme = createTheme({
    typography: {
        body1: {
            fontSize: '14px', 
            lineHeight: '24px', 
            fontWeight: 500
        },
        body2: {
            fontSize: '12px', 
            lineHeight: '17px', 
            fontWeight: 700
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    flex: 1,
                    width: '192px',
                    display: 'inline-block',
                    height: '70px', 
                    border: '1px solid #5F6D7E', 
                    borderRadius: '10px', 
                    margin: '20px 20px 0 0',
                    textTransform: 'capitalize'
                }
            }
        },
        MuiCard : {
            styleOverrides: {
                root: {
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'flex-start', 
                    alignItems: 'center', 
                    flexWrap: 'wrap'
                }
            }
        }
    }
})