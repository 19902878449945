import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const skillURL = `${process.env.REACT_APP_BASE_URL}/web/v2/worker/skill`;

export const fetchSkills = createAsyncThunk('skill/fetchSkills', async (tenantId, thunkAPI) => {
        const response = await fetch(`${skillURL}/all/${tenantId}`);
        const data = await response.json();
        return data;
});

export const skillSlice = createSlice({
    name: 'skill',
    initialState: {
        items: [],
        loading: false,
        error: null
    },
    reducers: {
        setSkill: (state, action) => {
            state.items = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSkills.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSkills.fulfilled, (state, action) => {
                state.items = action.payload;
                state.loading = false;
            })
            .addCase(fetchSkills.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export const { setSkill } = skillSlice.actions;
export default skillSlice.reducer;
