import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Typography, Button, createTheme, ThemeProvider, FormGroup, FormControl, TextField, } from "@mui/material"
import useApi from "../../hooks/useAPI";
import { CosmeticaModal, CosmeticaTable, Loading} from "../UIComponents";
import muiStyles from "../../styles/MuiComponentStyles";
import { setShift } from "../../features/shift/shiftSlice";
import { East } from "@mui/icons-material";
import { formatDateTime } from "../../utils/helperFunctions";
import { useCssStateHook } from "../UIComponents/ComonCss";


const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '40px',
                    color: '#2E3646'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: '#000',
                    padding: '9px 16px',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '22px',
                    textTransform: 'none',
                    '&:hover': {
                        color: '#000',
                        backgroundColor: '#fff'
                    }
                }
            }
        }
    }
})

const Shift = () => {
    const shiftData = useSelector((state) => state.shift.items);
    const userInfo = useSelector((state) => state.user.user);
    const {loading, getData, postData, patchData, deleteData } = useApi()
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false)
    const {  themeCssButton,themeCssHeader} = useCssStateHook();
    const initialState = {
        name: '',
        zone_id: userInfo?.zone_id,
        desc: userInfo?.desc

    }
    const [editShiftData, setEditShiftData] = useState(initialState)
    const shiftURL = `${process.env.REACT_APP_BASE_URL}/web/v2/shift`
    const callAPI = async () => {
        if (userInfo.zone_id != null) {
            try {
                getData(`${shiftURL}/all/${userInfo.zone_id}`).then(data => {
                    const shiftsInfo = data || [];
                    dispatch(setShift(shiftsInfo))
                })
            } catch (error) {
                console.log(error);
            }
        }
    }
    React.useEffect(() => {
        callAPI()
        // eslint-disable-next-line
    }, [userInfo]);

    const fields = ['Name',"Desc",'Created at']
    const getRows = (data) => data.map(row => {
        const { id, name,desc,created_at } = row
        return { id, name,desc,created_at:formatDateTime(created_at) }
    })
    const handleSubmit = async (e, shift) => {
        e.preventDefault()
        e.target.reset()
        setEditShiftData(initialState)
        setShowModal(false)
        if (shift?.id) {
            patchData(`${shiftURL}/update`, shift).then((res) => {
                callAPI()
            }).catch((err) => {
                console.log("Error ", err)
            })

        } else {
            postData(`${shiftURL}/create`, shift).then((res) => {
                callAPI()
            }).catch((err) => {
                console.log("Error ", err)
            })

        }
    }
    const handleEdit = (id) => {
        const _Shift = shiftData.find(d => d.id === id)
        setEditShiftData({ id, name: _Shift.name, desc: _Shift.desc })
        setShowModal(true)
    }
    const handleDelete = async (id) => {
        deleteData(`${shiftURL}/delete/${id}`).then((res) => {
            callAPI()
        }).catch((err) => {
            console.log("Error ", err)
        })

    }
    const handleCancel = () => {
        setShowModal(false);
        setEditShiftData(initialState);

    }
    const handlePageChange = () => {
   
      }
      const handleRowsPerPageChange = () => {
    
      }

    return loading ? (
        <Loading/>

    ) : (
        <Box>
            <ThemeProvider theme={theme}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mt={5}>
                    <Typography>Shift</Typography>
                    <Button variant="contained" onClick={() => { setShowModal(true) }}  sx={themeCssButton}>Add Shift</Button>
                </Stack>
            </ThemeProvider>
            <Box sx={{ mt: 4 }}>
            {console.log("data users shiftData: ", shiftData)}
                    <>
                        <CosmeticaTable
                            fields={fields}
                            rows={getRows(shiftData)}
                            isDelete
                            isEdit
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handlePageChange={handlePageChange}
                            handleRowsPerPageChange={handleRowsPerPageChange}
                            rowsCount={""}
                            page={1}
                            rowsPerPage={10}
                            isHeadercss={themeCssHeader}
                         
                        />

                       
                    </>
              
            </Box>
            {showModal &&
                <CosmeticaModal
                    showModal={showModal}
                    title={editShiftData?.id ? "Edit Shift" : "Add Shift"}
                    closeModal={handleCancel}
                >
                    <ShiftForm
                        handleClose={() => { setShowModal(false); setEditShiftData(initialState) }}
                        handleSubmit={handleSubmit}
                        shiftData={editShiftData}
                        handleCancel={handleCancel}
                        isHeadercss={themeCssButton}
                    />
                </CosmeticaModal>
            }

        </Box>
    );
};
const ShiftForm = ({ handleSubmit, shiftData, handleCancel,isHeadercss }) => {
    const [Shift, setShift] = React.useState(shiftData)
    const [isNumberEntered, setIsNumberEntered] = useState(false);
    const handleChange = (e) => {
        const validName = /^[A-Za-z\s]+$/.test(e.target.value);
     
        if (e.target.name === 'desc') {
            // const descPattern = /^[0-9\s]+[A-Za-z\s]+$/.test(e.target.value);
            const descPattern = /^.*$/.test(e.target.value);
            setIsNumberEntered((prevdata) => { return { ...prevdata, [e.target.name]: !descPattern } });
        } else {

            setIsNumberEntered((prevdata) => { return { ...prevdata, [e.target.name]: !validName } });
        }

        setShift((prevShift) => { return { ...prevShift, [e.target.name]: e.target.value } })


    }
    const buttonStyle = {
        backgroundColor: '#2E3646',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#2E3646',
            color: '#fff',
            opacity: 0.7,
        }
    }
    const handleerror = (e) => {
        e.preventDefault()
    
        if(!isNumberEntered.name && !isNumberEntered.desc ){
            handleSubmit(e, Shift)
        }
    }
    return <>
        <form style={{ marginTop: "20px" }} onSubmit={(e) => handleerror(e)}>
            <FormGroup>
                <FormControl sx={{ mt: 2 }}>
                    <TextField
                        label="Shift Name"
                        name="name"
                        type="text"
                        value={Shift.name}
                        variant="outlined"
                        onChange={handleChange}
                        required={true}
                        error={isNumberEntered.name}
                    />
                    {isNumberEntered.name && (
                        <p style={{ color: 'red' }}>Please enter a valid name without numbers.</p>
                    )}
                </FormControl>
                <FormControl sx={{ mt: 2 }}>
                    <TextField
                        label="Desc"
                        name="desc"
                        type="text"
                        value={Shift.desc}
                        variant="outlined"
                        onChange={handleChange}
                        required={true}
                        error={isNumberEntered.desc}
                    />
                    {isNumberEntered.desc && (
                        <p style={{ color: 'red' }}>Please enter a valid formate example: " 8 hours shift ".</p>
                    )}
                </FormControl>

            </FormGroup>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
                <Button
                    type="reset"
                    variant="text"
                    size="large"
                    onClick={() => handleCancel()}
                    sx={muiStyles.secondaryButton}
                >
                    Cancel
                </Button>
                {/* <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={buttonStyle}>{Shift.id ? "Update" : "Add"}</Button> */}

                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    // sx={buttonStyle} 
                    sx={ isHeadercss || buttonStyle}
                    endIcon={<East />}
                >
                    {Shift.id ? "Update" : "Submit"}
                </Button>
            </Stack>
        </form>
    </>
}
export default Shift;
