import { createSlice } from "@reduxjs/toolkit";
export const Slice = createSlice({
  name: "productstagesSlice",
  initialState: {
    items: [],
  },
  reducers: {
    setProductStages: (state, action) => {
   
        state.items = action.payload || [];
    },
  },
});
export const { setProductStages } = Slice.actions;
export const getProductStages = (state) => {
  return state.productstagesSlice.items;
};
export default Slice.reducer;
