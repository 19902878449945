import { Hero, HomeContent } from "../components";
import useApi from "../hooks/useAPI";
import { useAuth0 } from "@auth0/auth0-react";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Loading } from "../components/UIComponents";

const Home = () => {
  const { getData, loading } = useApi();
  const location = useLocation();
  // const tenantData = useSelector((state) => state.tenanturl.itemsname);
  const userInfo = useSelector((state) => state.user.user);
  const tenantURLData = `${process.env.REACT_APP_BASE_URL_ADMIN}/admin/media/tenant`;
  const [tenantName, setTenantName] = useState();

  const callAPIData = async () => {
    try {
      getData(`${tenantURLData}/image/tenant/${userInfo.tenant_id}`).then(
        (data) => {
          const urlInfo = data || [];
          setTenantName(urlInfo.name);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  // setTenant(location.state)
  useEffect(() => {
    const logourl = () => {
      if (userInfo.tenant_id && location.state === userInfo.tenant_id) {
        // setTenant(userInfo.tenant_id)
        callAPIData();
      } else {
        if (isAuthenticated) {
          callAPIData();
        }
      }
    };
    if (userInfo.tenant_id !== null) {
      logourl();
    } else {
      if (isAuthenticated) {
        logourl();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const { isAuthenticated } = useAuth0();

  const title = () => {
    return isAuthenticated && tenantName
      ? ` Welcome to ${tenantName} `
      : "Welcome to DFA";
  };

  if (loading) {
    return <Loading />
  }

  return (
    <Fragment>
      <Hero renderTitle={title} />
      <HomeContent />
    </Fragment>
  );
};

export default Home;
