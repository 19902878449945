import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, Typography, createTheme, ThemeProvider} from "@mui/material";
import WUnassignedFrom from "./WDeviceQr";
import useApi from "../../hooks/useAPI";
import { setwdeviceassign } from "../../features/mdevices/wdevicesgetSlice";
import { CosmeticaTable, Loading } from "../UIComponents";
import { formatDateTime, formatIcon } from "../../utils/helperFunctions";
import { useCssStateHook } from "../UIComponents/ComonCss";



const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '40px',
                    color: '#2E3646'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: '#000',
                    padding: '9px 16px',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '22px',
                    textTransform: 'none',
                    '&:hover': {
                        color: '#000',
                        backgroundColor: '#fff'
                    }
                   
                }
            }
        }
    }
})

const WDevices = () => {
    const assignworkerData = useSelector((state) => state.wdevicesget.items);
    const userInfo = useSelector((state) => state.user.user);
    const workerURL =`${process.env.REACT_APP_BASE_URL}/web/v2/license`
    const { getData , deleteData,loading} = useApi()
    const dispatch = useDispatch();
    const [assignData, setAssignData] = useState(true)
    const[UnassignedData, setUnassignedData] =useState(false)
    const {  themeCssButton,themeCssHeader} = useCssStateHook();
    const callAPI = async () => {
        if (userInfo.tenant_id != null) {
            try {
                getData(`${workerURL}/worker/assigned/all/${userInfo.tenant_id}`).then(data => {
                    const workerInfo = data || [];
                    dispatch(setwdeviceassign(workerInfo))
                })
            } catch (error) {
                console.log(error);
            }
        }
    }
    React.useEffect(() => {
        callAPI()
        // eslint-disable-next-line
    }, [userInfo]);
    

    const fields = ['\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0OS','App Version','Name','Identifier','Created at' ]
    const getRows = (data) => data.map(row => {
        const { id,os,os_version,app_version, device_name,identifier,created_at } = row
        return { id, os:formatIcon(os,os_version),app_version, device_name,identifier,created_at:formatDateTime(created_at) }
    })
  
    const handleDelete = async (id) => {
        deleteData(`${workerURL}/delete/${id}`).then((res) => {
            callAPI()
        }).catch((err) => {
            console.log("Error ", err)
        })
      
    }
 
    const handleDevices = (value) => {
        if(value === "Assigned"){
            setAssignData(true)
            setUnassignedData(false)
            callAPI()
        }else{
            setUnassignedData(true)
            setAssignData(false)
            
        }

    }
    const handlePageChange = () => {
   
    }
    const handleRowsPerPageChange = () => {
  
    }
  
    return loading ? (
        <Loading/>
    ) : (
        <>
        <Box>
            <ThemeProvider theme={theme}>
              
                    <Stack direction="row" justifyContent="space-between" alignItems="center" mt={5}>
                        <Typography>Worker Devices</Typography>
                        <Box sx={{ display: 'flex' }}>
                            <Typography
                                sx={{
                                    backgroundColor: assignData ? themeCssButton.backgroundColor : "white",
                                    color: assignData ? "white" : "#5F6D7E",
                                     borderRadius: "6px",
                                    padding: "3px 24px",
                                    fontSize: "18px",
                                    cursor:"pointer",
                                    
                                }}
                                onClick={() => {
                                    handleDevices("Assigned");
                                }}>Assigned</Typography>
                            <Typography
                                sx={{
                                    backgroundColor: UnassignedData ? themeCssButton.backgroundColor  : "white",
                                    color: UnassignedData ? "white" : "#5F6D7E",
                                     borderRadius: "6px",
                                    padding: "3px 24px",
                                    fontSize: "18px",
                                    cursor:"pointer",
                                }}
                                onClick={() => {
                                    handleDevices("Unassigned");
                                }}>Unassigned</Typography>
                        </Box>
                    </Stack>

            </ThemeProvider>
            <Box sx={{ mt: 4 }}>
                { assignData  ?
                    <>
                        <CosmeticaTable
                            fields={fields}
                            rows={getRows(assignworkerData)}
                            isDelete
                            // isEdit
                            // handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handlePageChange={handlePageChange}
                            handleRowsPerPageChange={handleRowsPerPageChange}
                            rowsCount={""}
                            page={1}
                            rowsPerPage={10}
                            isHeadercss={themeCssHeader}
                             />
                    </>
                    : UnassignedData && (
                        <WUnassignedFrom/>
                    ) }
            </Box>
        </Box>
        </>
    );
};

export default WDevices;
