
import muiStyles from "../../../../styles/MuiComponentStyles";
import { East } from "@mui/icons-material";
import {
  Box,
  Typography,
  Stack,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Button,
} from "@mui/material";
import UploadFile from "./UploadFile";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateImage, clearImage, addImage, setEnableStepSubmitted, setStepEditable, updateImageInputstep } from "../../../../features/ProductionResourceSteps/stepsSlice"
import useApi from "../../../../hooks/useAPI";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export const Image = ({index, image, imagesLength, fetchSteps,ChildName }) => {
  // const currentStep = useSelector(state => state.steps.currentStep)
  const isStepEditable = useSelector(state => state.steps.isStepEditable)
  // const imagetest = currentStep?.media?.images


  // const imagetest = image.filter(item => item.child === ChildName )

  // eslint-disable-next-line array-callback-return
  // const imagedata = imagetest.map((item) => {
  //   if (item.child === ChildName) {
  //     return item;
  //   } else {
  //     // Provide a value to return when the condition is not met
  //     return null; // Or any other value you want to return for blank images
  //   }
  // });
  // const image = imagedata[index]
  const dispatch = useDispatch()
  const {deleteData} = useApi()

  const handleAddImage = () => {
    dispatch(addImage({ChildName}))
  }

  const handleImageCancel = () => {
      dispatch(clearImage({ChildName}))
  }

  const handleDelete = async () =>{
    const mediaId = image.id
    const url = `${process.env.REACT_APP_BASE_URL}/web/v2/media/delete/${mediaId}`
    if (image.file_name && image.url) {
      await deleteData(url)
      await fetchSteps()
      dispatch(setEnableStepSubmitted(true))
      dispatch(setStepEditable(false))
    }
  }

  const handleInputChange = (e, imageIndex) => {
    console.log("test data changed", e.target.name,"test data changed vv", e.target.value,"test data changed inde", imageIndex ,"test data changed chnm",ChildName)
      dispatch(updateImageInputstep({key: e.target.name, value: e.target.value, index: imageIndex ,ChildName:ChildName}))
  }

  const handleImageChange = (url, type, file, imageIndex) => {
    console.log('url, type, file, imageIndex', url, type, file, imageIndex)
      dispatch(updateImage({url, type, file, index: imageIndex,child:ChildName}))
  }

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "362px",
        height: "504px",
        borderRadius: "8px",
        backgroundColor: "rgba(217, 217, 217, 0.30)",
        marginRight: '20px'
      }}
      mt={2}
      position='relative'
    >
      <Box
        sx={{
          width: "342px",
          height: "230px",
          boeder: "1px solid #5F6D7E",
          backgroundColor: !image?.url ? "#E6E9EC" : 'none',
          borderRadius: "10px",
        }}
        position='relative'
      >
        <UploadFile accept='image/*' optional={true} label='image' height='230px' file={image} handleFileChange={(url, type, file) => handleImageChange(url, type, file, index)} handleDeleteImage={handleDelete} />
      </Box>
      <Stack spacing={2} mt={2}>
        <Grid item xs={6} sx={{ width: "342px" }}>
          <FormControl fullWidth>
            <FormLabel>Name</FormLabel>
            <TextField
              name="name"
              value={image?.name || ''}
              onChange={(e) => handleInputChange(e, index)}
              required={!!image?.url}
              disabled={!isStepEditable}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel>Tags</FormLabel>
            <TextField
              name="tags"
              value={image?.tags || ''}
              onChange={(e) => handleInputChange(e, index)}
              disabled={!isStepEditable}
            />
          </FormControl>
        </Grid>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        mt={2}
        sx={{ width: "342px", height: '40px' }}
      >
        {
            index === (imagesLength - 1) && <>
            <Button type="button" sx={muiStyles.secondaryButton} onClick={handleImageCancel} disabled={imagesLength <= 1 || !isStepEditable}>
                Cancel
                </Button>
                <Button type="button" sx={muiStyles.primaryButton} endIcon={<East />} onClick={handleAddImage} disabled={!isStepEditable} >
                Add New
                </Button>
            </>
        }
        
      </Stack>
    </Stack>
  );
};

const ImagesComponent = ({fetchSteps,ChildName,Name,imagesdata}) => {
  // const images = useSelector(state => state.steps.currentStep)?.media?.images
  // const imagesgood =  useSelector(state => state.steps.currentStep)?.media?.good
  // const imagesbad =  useSelector(state => state.steps.currentStep)?.media?.bad
  // const imagesreference =  useSelector(state => state.steps.currentStep)?.media?.reference
  const imagesLength = imagesdata?.length || 0
  // const a = images?.filter(item => item.child === ChildName )


//   const data = {
//     "name": "",
//     "tags": "",
//     "url": "",
//     "type": "image/jpeg",
//     "file": null,
//     "child": ""
// }

  return (
    <>
    {/* <Box mt={4}>
      <Typography
        sx={{
          color: "#2E3646",
          fontSize: "20px",
          lineHeight: "40px",
          fontWeight: 600,
        }}
      >
        Images
         {Name} 
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center"
        }}
      >
        {images?.map((image, index) => {
      return (
        <Image
          key={index}
          image={image}
          imagesLength={imagesLength}
          index={index}
          fetchSteps={fetchSteps}
        />
      );
    })}

      </Box>
    </Box> */}
    {/* <Box mt={4}>
        <Typography
          sx={{
            color: "#2E3646",
            fontSize: "20px",
            lineHeight: "40px",
            fontWeight: 600,
          }}
        >
    
          {Name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          {imagesdata?.map((image, index) => {
            return (
              <Image
                key={index}
                image={image}
                imagesLength={imagesLength}
                index={index}
                fetchSteps={fetchSteps}
                ChildName={ChildName}
              // tabValueset={tabValue0}
              />
            );
          })}
          
        </Box>
      </Box> */}
        <Box mt={4}>
          <Accordion>
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id={Name}
          sx={{
            flexDirection: 'row-reverse', // Align items in reverse order
            justifyContent: 'space-between', // Distribute space between items
            paddingRight: 'unset', // Remove any default right padding
          }}
        >
        <Typography
          sx={{
            color: "#2E3646",
            fontSize: "15px",
            lineHeight: "30px",
            fontWeight: 600,
            paddingLeft: '10px'
          }}
        >
          
          {Name}
        </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          {imagesdata?.map((image, index) => {
            return (
              <Image
                key={index}
                image={image}
                imagesLength={imagesLength}
                index={index}
                fetchSteps={fetchSteps}
                ChildName={ChildName}
              // tabValueset={tabValue0}
              />
            );
          })}
          
        </Box>
        </AccordionDetails>
          </Accordion>
      </Box>
      </>
  );
};

export default ImagesComponent;
