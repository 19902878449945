import {
  setEnableStepSubmitted,
  setSteps,
  setCurrentStepIndex,
  setIsEdit,
} from "../../features/ProductionResourceSteps/stepsSlice";
import { setIsEditQuality } from "../../features/ProductionResourceSteps/stepsSliceQuality";
import useApi from "../../hooks/useAPI";
import muiStyles from "../../styles/MuiComponentStyles";
import { FormFieldsTheme } from "../../styles/MuiThemes";
import { Loading } from "../UIComponents";
import {
  ProductionResourceStepForm,
  ProductionResourceForm,
} from "./components";
import {
  Box,
  Typography,
  ThemeProvider,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";

const AddProductionResource = () => {
  const user = useSelector((state) => state.user.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { resourceId } = useParams();
  const [resource, setResource] = React.useState({ name: "", desc: "" });
  const [showStepForm, setShowStepForm] = React.useState(
    resourceId !== undefined
  );
  const [showResourceForm, setShowResourceForm] = React.useState(
    resourceId === undefined
  );
  const [loading, setLoading] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState("Loading...");
  const { postData } = useApi();
  const productionUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/production`;
  const mediaUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/media`;
  const questionUrl = `${productionUrl}/step/question`;

  dispatch(setSteps([]));
  dispatch(setCurrentStepIndex(null));
  dispatch(setIsEdit(false));
  dispatch(setIsEditQuality(false));

  const handleAddResourceSubmit = (e, _resource) => {
    e.preventDefault();
    _resource.tenant_id = user.tenant_id;
    _resource.zone_id = user.zone_id;
    _resource.product_id = +location.state.productId;
    try {
      postData(`${productionUrl}/resource/create`, _resource).then((data) => {
        setShowResourceForm(false);
        setShowStepForm(true);
        setResource(data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createMedia = (data, media_type, child = "production:step") => {
    const req_data = {
      child,
      child_id: data.child_id,
      parent: data.parent,
      parent_id: data.parent_id,
      media_type: media_type.split("/")[0],
      tenant_id: user.tenant_id,
      zone_id: user.zone_id,
    };
    return postData(`${mediaUrl}/create`, req_data);
  };

  const uploadMedia = async (res, file) => {
    const fileHeaders = new Headers();
    fileHeaders.append("Content-Type", file.type);
    const fileContent = file.file;
    const requestOptions = {
      method: "PUT",
      headers: fileHeaders,
      body: fileContent,
    };
    await fetch(res.aws_url, requestOptions).then(async (resp) => {
      if (resp.ok) {
        const req_data = res;
        req_data.name = file.name || "";
        req_data.tag = file.tag || "";
        delete res.aws_url;
        await postData(`${mediaUrl}/success`, req_data);
      }
    });
  };

  const handleStepSubmit = async (e, step) => {
    e.preventDefault();
    setLoading(true);
    try {
      const req_data = {
        desc: step.desc,
        max_crew: +step.max_crew,
        min_crew: +step.min_crew,
        name: step.name,
        position: step.position,
        production_resource_id: step.production_resource_id,
      };
      setLoadingText("Creating Step...");
      await postData(`${productionUrl}/step/create`, req_data).then(
        async (data) => {
          // Video
          if (step.media.video.file) {
            setLoadingText("Uploading Video...");
            await createMedia(data, step.media.video.type, data.child).then(async (res) => {
              await uploadMedia(res, step.media.video);
            });
          }
          // Images
          setLoadingText("Uploading Images...");
          for (let image of step.media.images) {
            if (image.file) {
              await createMedia(data, image.type, data.child).then(async (res) => {
                await uploadMedia(res, image);
              });
            }
          }
          for (let question of step.questions) {
            setLoadingText("Uploading Questions...");
            const options = question.options.map((option) => {
              const obj = {
                has_image: !!option.image.file,
                is_correct: option.is_correct,
                option: option.option,
              };
              return obj;
            });
            const questionObject = {
              has_image: !!question.image.file,
              options: options,
              question: question.question,
            };
            const req_data = JSON.parse(
              JSON.stringify({
                ...questionObject,
                production_resource_id: step.production_resource_id,
                production_resource_step_id: step.id,
              })
            );
            await postData(`${questionUrl}/create`, {
              questions: req_data,
            }).then(async (data) => {
              if (question.image.file) {
                await createMedia(
                  data.question.id,
                  question.image.type,
                  "production:question"
                ).then(async (res) => {
                  await uploadMedia(res, question.image);
                });
              }
              for (let option of question.options) {
                if (option.image.file) {
                  const respOption = data?.options?.find(
                    (op) => op?.option === option.option
                  );
                  await createMedia(
                    respOption?.id,
                    option.image.type,
                    "production:option"
                  ).then(async (res) => {
                    await uploadMedia(res, option.image);
                  });
                }
              }
            });
            // const req_data = JSON.parse(JSON.stringify({...question, production_resource_id: step.production_resource_id, production_resource_step_id: data.step_id}))
            // await postData(`${questionUrl}/create`, {questions: req_data})
          }
          dispatch(setEnableStepSubmitted(true));
          navigator(`/production-resouce/edit/${step.production_resource_id}`, {
            state: { resource },
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setLoadingText("Loading...");
  };

  return (
    <>
      {
        <Loading open={loading} description={loadingText}>
          <Box
            sx={{
              width: "300px",
              height: "200px",
              background: "#fff",
              borderRadius: "6px",
            }}
          >
            <Typography
              variant="h5"
              style={{
                color: "#000",
                borderBottom: "1px solid black",
                padding: "5px 10px",
              }}
            >
              Creating Step
            </Typography>
            <Box
              sx={{
                height: "200px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="success" sx={{ mt: 2 }} />
              <Typography
                variant="body1"
                style={{ marginTop: "16px", color: "#000" }}
              >
                {loadingText}
              </Typography>
            </Box>
          </Box>
        </Loading>
      }
      <ThemeProvider theme={FormFieldsTheme}>
        <Box mt={3}>
          <Typography sx={muiStyles.pageTitle}>
            Add Production Resource
          </Typography>
          <Box>
            {showResourceForm && (
              <ProductionResourceForm handleSubmit={handleAddResourceSubmit} />
            )}
          </Box>
          <Box>
            {showStepForm && (
              <ProductionResourceStepForm
                handleSubmit={handleStepSubmit}
                resource={resource}
                setLoading={setLoading}
              />
            )}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AddProductionResource;
