import Option from "./Option";
import React from "react";

const Options = (props) => {
  return (
    <>
      {props.question.options.map((option, index) => (
        <Option {...props} option={option} index={index} key={index} />
      ))}
    </>
  );
};

export default Options;
