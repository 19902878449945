import { Box, Stack, Typography, Grid, FormControl, FormLabel, TextField } from "@mui/material"
import UploadFile from "./UploadFile"
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { updateVideo, updateVideoInput, deleteVideo } from "../../../../features/ProductionResourceSteps/stepsSlice"
import useApi from "../../../../hooks/useAPI";
    
const VideoComponent = () => {
    const dispatch = useDispatch()
    const {deleteData} = useApi()
    const video = useSelector(state => state.steps.currentStep)?.media?.video
    const isStepEditable = useSelector(state => state.steps.isStepEditable)
    
    const handleInputChange = (e) => {
        dispatch(updateVideoInput({key: e.target.name, value: e.target.value}))
    }

    const handleVideoChange = (url, type, file) => {
        dispatch(updateVideo({url, type, file}))
    }

    const handleDelete = () => {
        const mediaId = video.id
        const url = `${process.env.REACT_APP_BASE_URL}/web/v2/media/delete/${mediaId}`
        if (video.file_name && video.url) {
            deleteData(url).then(() => dispatch(deleteVideo()))
        }
    }
    

    return <Box mt={4}>
        <Stack direction={{ sm: 'column', md: 'row' }} alignItems='center' spacing={3} >
            <Stack sx={{width: '430px'}}>
            <Typography sx={{color: '#2E3646', fontSize: '20px', lineHeight: '40px', fontWeight: 600}}>Video*</Typography>
            <Box sx={{height: '243px', border: '1px solid #5F6D7E', borderRadius: '10px', background: !video?.url ? '#E6E9EC' : 'none'}} mt={2} position='relative'>
                <UploadFile accept='video/*' label='video' height='100%' file={video} handleFileChange={handleVideoChange} handleDeleteVideo={handleDelete} />
            </Box>
            </Stack>
            <Stack spacing={2}>
                <Grid item xs={6} sx={{width: '460px'}} >
                    <FormControl fullWidth>
                        <FormLabel>Name</FormLabel>
                        <TextField name="name" value={video?.name || ''} onChange={handleInputChange} required={!!video?.url} disabled={!isStepEditable} />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <FormLabel>Tags</FormLabel>
                        <TextField name="tags" value={video?.tags || ''} onChange={handleInputChange} disabled={!isStepEditable} />
                    </FormControl>
                </Grid>
            </Stack>
        </Stack>
    </Box>
}

export default VideoComponent