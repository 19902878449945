import { createSlice } from "@reduxjs/toolkit";

export const reportSlice = createSlice({
  name: "reportSlice",
  initialState: {
    allItems: [],
    items: [],
    totalItems: 0,
    rowsPerPage: 10,
    page: 1,
  },
  reducers: {
    setReports: (state, action) => {
      const data = action.payload
      state.allItems = data
      state.totalItems = data.length
      state.items = data.slice((state.page - 1) * state.rowsPerPage, state.page * state.rowsPerPage);
    },
    setPage: (state, action) => {
      const page = action.payload
      state.page = page
      state.items = state.allItems.slice((page - 1) * state.rowsPerPage, page * state.rowsPerPage)
    },
    setRowsPerPage: (state, action) => {
      const rowsPerPage = action.payload
      state.rowsPerPage = rowsPerPage
      state.items = state.allItems.slice((state.page - 1) * rowsPerPage, state.page * rowsPerPage);
    }
  },
});

export const { setReports, setPage, setRowsPerPage } = reportSlice.actions;

export const getReports = (state) => {
  return state.reportSlice.items;
};

export default reportSlice.reducer;
