import React from "react";
import logo from '../../../images/logo.png'

const HeaderTable = () => {
  const data = {
    title: 'In-Process Visual Attribute Inspection Report',
    documentNumber: 'FR-00067',
    revision: 10,
    effectiveDate: 'Mar 7, 2023',
    relatedDocuments: 'PR-00043 - In-Process Inspection and Testing'
  }
  return (
    <div>
      <p className="c33 c81">
        <span className="c61" />
      </p>
      <table className="c99">
        <tbody>
          <tr className="c19">
            <td className="c105" colSpan={2} rowSpan={2}>
              <p className="c1">
                <span
                  style={{
                    overflow: "hidden",
                    display: "inline-block",
                    margin: "0.00px 0.00px",
                    border: "0.00px solid #000000",
                    transform: "rotate(0.00rad) translateZ(0px)",
                    WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                    width: "163.47px",
                    height: "18.61px",
                  }}
                >
                  <img
                    alt=""
                    src={logo}
                    style={{
                      width: "163.47px",
                      height: "18.61px",
                      marginLeft: "0.00px",
                      marginTop: "0.00px",
                      transform: "rotate(0.00rad) translateZ(0px)",
                      WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                    }}
                    title=""
                  />
                </span>
              </p>
            </td>
            <td className="c57" colSpan={5} rowSpan={1}>
              <p className="c95">
                <span className="c27">Approved</span>
              </p>
            </td>
          </tr>
          <tr className="c19">
            <td className="c57" colSpan={5} rowSpan={1}>
              <p className="c95">
                <span className="c27">Form</span>
              </p>
            </td>
          </tr>
          <tr className="c19">
            <td className="c90" colSpan={1} rowSpan={1}>
              <p className="c93">
                <span className="c27">Title:</span>
              </p>
            </td>
            <td className="c39" colSpan={6} rowSpan={1}>
              <p className="c93">
                <span className="c27">
                  {data.title}
                </span>
              </p>
            </td>
          </tr>
          <tr className="c109">
            <td className="c90" colSpan={1} rowSpan={1}>
              <p className="c6">
                <span className="c9">Document #:</span>
              </p>
            </td>
            <td className="c51" colSpan={2} rowSpan={1}>
              <p className="c6">
                <span className="c9">{data.documentNumber}</span>
              </p>
            </td>
            <td className="c41" colSpan={1} rowSpan={1}>
              <p className="c6">
                <span className="c9">Revision:</span>
              </p>
            </td>
            <td className="c54" colSpan={1} rowSpan={1}>
              <p className="c6">
                <span className="c9">{data.revision}</span>
              </p>
            </td>
            <td className="c44" colSpan={1} rowSpan={1}>
              <p className="c6">
                <span className="c9">Effective Date:</span>
              </p>
            </td>
            <td className="c26" colSpan={1} rowSpan={1}>
              <p className="c6">
                <span className="c9">{data.effectiveDate}</span>
              </p>
            </td>
          </tr>
          <tr className="c115">
            <td className="c90" colSpan={1} rowSpan={1}>
              <p className="c77">
                <span className="c102">Related Documents:</span>
              </p>
            </td>
            <td className="c39" colSpan={6} rowSpan={1}>
              <p className="c63">
                <span className="c47">
                  {data.relatedDocuments}
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HeaderTable;
