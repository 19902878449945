import { Box, Typography, Stack, IconButton } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { AccessAlarm, WarningAmber, Done, Group } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { isTodayGreaterThanEqualGivenDate } from "../../utils/helperFunctions";

const JobCard = ({ workOrder, handleJobDetails }) => {
  const navigate = useNavigate()
  const products = useSelector(state => state.products.allItems)
  const product = products?.find(product => product.id === workOrder.product_id)

  const colorCodes = [
    {priority: 'Low', color_code: '#4CAF50', background_color_code: '#E8F5E9'},
    {priority: 'Medium', color_code: '#2196F3', background_color_code: '#E3F2FD'},
    {priority: 'High', color_code: '#F44336', background_color_code: '#FFEBEE'},   
  ]

  
  const colorCode = colorCodes?.find(code => code.priority === workOrder?.priority)
  
  const iconStyle = {fontSize: '14px !important', zIndex: '10 !important'}

  const statusIcons = [
    {id: 1, icon: <AccessAlarm color="primary" sx={iconStyle} />},
    {id: 2, icon: <WarningAmber color="warning" sx={iconStyle}/>},
    {id: 3, icon: <Done color="success" sx={iconStyle}/>}
  ]

  const statusIcon = statusIcons?.find(icon => icon.id === workOrder.work_order_status_id)

  const isWorkOrderInDate = isTodayGreaterThanEqualGivenDate(workOrder?.end_date)

  return (
    <Box
      sx={{
        padding: "2px",
        border: "0.5px solid #EAEAEA",
        borderRadius: "2px",
        display: "flex",
        position: 'relative',
        alignItems: "center",
        cursor: "pointer",
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "scale(1.001)",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
        },
      }}
      key={workOrder?.id}
      onClick={() => handleJobDetails(workOrder)}
    >
      <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{backgroundColor: colorCode.background_color_code, borderRadius: '5px', padding: '4px', width: '100%', clipPath: 'polygon(100% 0, 100% 50%, 75% 100%, 0 100%, 0 0)'}} position='relative'>
        <Box
          width="4px"
          height="48px"
          sx={{
            backgroundColor: colorCode.color_code,
            marginRight: "8px",
          }}
        ></Box>
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 800,
            }}
          >
            {workOrder.work_order}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            {workOrder.customer_reference}
          </Typography>
          <Typography
            sx={{
              fontSize: "10px",
              fontWeight: 600,
            }}
          >
            {product?.desc?.length > 33 ? `${product?.desc.slice(0, 30)}...` : product?.desc}
          </Typography>
        </Box>
      </Stack>
      {<Box style={{position: 'absolute', bottom: '1px', right: '3px'}}>
            <IconButton sx={{m:0, p:0, mr: 1}} onClick={() => navigate('/crew', { state: { workOrder } })} style={{
              cursor: isWorkOrderInDate ? 'pointer' : 'not-allowed',
            }} disabled={!isWorkOrderInDate}>
              <Group color="primary" sx={{...iconStyle, opacity: isWorkOrderInDate ? 1 : 0 }} /> 
            </IconButton>
            {statusIcon?.icon}
      </Box>}
    </Box>
  );
};

export default JobCard;
