import React from "react";

import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user } = useAuth0();
  const { name, email } = user || {};
  const date = new Date(user.updated_at);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are 0-indexed, so we add 1
  const year = date.getFullYear();

  // const formattedDate = `${day}-${month < 10 ? `0${month}` : month}-${year}`;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const formattedDateTime = `${day}-${month < 10 ? `0${month}` : month}-${year} ${hours}:${minutes}:${seconds}`;

  return (
    <div>
      <div className="row align-items-center profile-header">
        <div className="col-md-12 mb-3 mt-3 d-flex">
            <div>
              <h2>{name}</h2>
              <p style={{fontSize:"20px"}}>{email}</p>
            </div>
        </div>
        
      </div>
      <div className="row">
      <p>Nick Name: {user.nickname}</p><br/>
        <p>Name:{user.name}</p>
         <p>Updated At: {formattedDateTime}</p>
      </div>
    </div>
  );

};

export default Profile;
