import axios from "axios";

const fetchUserDetails = async () => {
    const axiosConfig = {
        url: `${process.env.REACT_APP_BASE_URL}/web/v2/user/logged`,
        method: "GET",
    };
    const response = await axios(axiosConfig)
    return  response.data
}

export { fetchUserDetails }