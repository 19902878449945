import { setShift } from "../../../features/shift/shiftSlice";
import { setWorkers } from "../../../features/workers/workersSlice";
import useApi from "../../../hooks/useAPI";
import muiStyles from "../../../styles/MuiComponentStyles";
import {
  formatDate,
  isDateWithinRange,
  getTextColor,
} from "../../../utils/helperFunctions";
import { Loading } from "../../UIComponents";
import { RemoveCircleOutline, AddCircleOutline } from "@mui/icons-material";
import {
  Box,
  Stack,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Paper,
  Breadcrumbs,
  Grid,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";

const CrewCreate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const workers = useSelector((state) => state.worker.workers);
  const shifts = useSelector((state) => state.shift.items);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const { loading, getData, postData } = useApi();
  const workersUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/worker`;
  // const workOrders = useSelector(state => state.scheduleJobs.workOrders)
  const workOrder = location?.state?.workOrder;
  const assignedShifts = location?.state?.assignedShifts;
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [workersList, setWorkersList] = useState(() => [...workers]);
  const [filterdWorkersList, setFilterdWorkersList] = useState(() => [
    ...workers,
  ]);
  const [selectedShift, setSelectedShift] = useState("");
  const [product, setProduct] = useState(null);
  const [productionResource, setProductionResource] = useState(null);
  const today = new Date().toISOString();
  const [scheduledDate, setScheduleDate] = useState(() => {
    return isDateWithinRange(today, workOrder?.start_date, workOrder?.end_date)
      ? today
      : workOrder?.start_date;
  });
  const [lineVariation, setLineVariation] = React.useState([]);
  const [line, setLine] = React.useState(null);

  React.useEffect(() => {
    if (user.tenant_id && user.zone_id) {
      fetchWorkers();
      fetchShifts();
      fetchProducts();
    }
    // eslint-disable-next-line
  }, [user.tenant_id, user.zone_id]);

  React.useEffect(() => {
    setWorkersList([...workers]);
    setFilterdWorkersList([...workers]);
    // eslint-disable-next-line
  }, [workers, shifts]);

  const handleSelectWorker = (id) => {
    const worker = workersList.find((item) => item.id === id);
    setSelectedWorkers([...selectedWorkers, worker]);
    setWorkersList(workersList.filter((item) => item.id !== id));
    setFilterdWorkersList(filterdWorkersList.filter((item) => item.id !== id));
  };

  const handleRemoveWorker = (id) => {
    const worker = selectedWorkers.find((item) => item.id === id);
    setWorkersList([...workersList, worker]);
    if (
      worker.first_name.toLocaleLowerCase().includes(searchText) ||
      worker.last_name.toLocaleLowerCase().includes(searchText) ||
      worker.color_code.toLocaleLowerCase().includes(searchText) ||
      worker.code.toLocaleLowerCase().includes(searchText)
    ) {
      setFilterdWorkersList([...filterdWorkersList, worker]);
    }
    setSelectedWorkers(() => {
      return selectedWorkers.filter((item) => item.id !== id);
    });
  };

  const handleSearch = (e) => {
    let value = e.target.value || "";
    setSearchText(value);
    value = value.toLocaleLowerCase();
    const list = workersList.filter((item) => {
      return (
        item.first_name.toLocaleLowerCase().includes(value) ||
        item.last_name.toLocaleLowerCase().includes(value) ||
        item.color_code.toLocaleLowerCase().includes(value) ||
        item.code.toLocaleLowerCase().includes(value)
      );
    });
    setFilterdWorkersList(list);
  };

  const handleSave = async () => {
    if (scheduledDate === "") {
      alert("Please Select date!");
      return;
    }
    if (selectedShift === "") {
      alert("Please Select shift!");
      return;
    }
    if (selectedWorkers.length < 1) {
      alert("Please Select workers!");
      return;
    }
    const data = {
      name: `${workOrder.work_order}-${product.product_code}`,
      scheduled_date: dayjs(scheduledDate).toISOString(),
      shift_id: selectedShift,
      tenant_id: user.tenant_id,
      work_order_id: workOrder.id,
      zone_id: user.zone_id,
    };
    const { assignment_template_id } = await postData(
      `${workersUrl}/assignment/template/create`,
      data
    );
    await postData(
      `${process.env.REACT_APP_BASE_URL}/web/v2/template/worker/create`,
      {
        worker_assignment_template_id: assignment_template_id,
        worker_id: selectedWorkers.map((item) => item.id).join(","),
      }
    );
    navigate("/crew", { state: { workOrder } });
  };

  const fetchWorkers = async () => {
    try {
      const response =
        (await getData(
          `${workersUrl}/all/${user.tenant_id}/${workOrder.zone_id}`
        )) || [];
      dispatch(setWorkers(response));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchShifts = async () => {
    const shiftURL = `${process.env.REACT_APP_BASE_URL}/web/v2/shift`;
    try {
      const response =
        (await getData(`${shiftURL}/all/${workOrder.zone_id}`)) || [];
      dispatch(setShift(response));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProducts = async () => {
    try {
      const productUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/product`;
      const response =
        (await getData(
          `${productUrl}/all/${user.tenant_id}/${user.zone_id}`
        )) || [];
      const _product =
        response.find((item) => item.id === workOrder.product_id) || null;
      setProduct(_product);
      fetchResources();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchResources = async (productId) => {
    try {
      const productionResourceUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/production/resource`;
      const lineURL = `${process.env.REACT_APP_BASE_URL}/web/v2/line`;
      const data =
        (await getData(
          `${productionResourceUrl}/all/${workOrder.product_id}`
        )) || [];
      const resource =
        data.find((item) => item.id === workOrder.production_resource_id) ||
        null;
      const lines =
        (await getData(`${lineURL}/all/${user.zone_id}/page/1/count/9999`)) ||
        [];
      const _line = lines?.lines?.find((line) => line.id === resource.line_id);
      fetchLineVariation(resource.line_id, resource.line_variation_id);
      setProductionResource(resource);
      setLine(_line);
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchLineVariation(id, line_variation_id) {
    try {
      const lineURL = `${process.env.REACT_APP_BASE_URL}/web/v2/line`;
      await getData(
        `${lineURL}/variation/all/${id}/page/${1}/count/${999}`
      ).then((data) => {
        const variation = data.variations?.find(
          (variation) => variation.id === line_variation_id
        );
        setLineVariation(variation);
      });
    } catch (error) {
      console.log(error);
    }
  }

  return loading ? (
    <Loading />
  ) : (
    <Box>
      <Box mt={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ color: "#000" }} href="/">
            Home
          </Link>
          <Link style={{ color: "#000" }} to="/schedule-jobs">
            Schedule Job
          </Link>
          <Link
            style={{ color: "#000" }}
            to={".."}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            Crew
          </Link>
          <Typography color="text.primary">Crew Create</Typography>
        </Breadcrumbs>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={5}
      >
        <Typography sx={muiStyles.pageTitle}>Crew Sheet</Typography>
        {/* <Button onClick={() => navigate('/crew', {state: {workOrder}})}>Go back</Button> */}
      </Stack>
      <Paper variant="outlined">
        <Box
          mt={4}
          sx={{
            width: "100%",
            height: "200px",
            borderRadius: "6px",
            py: 2,
            px: 5,
          }}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="start"
            sx={{ height: "100%" }}
          >
            <Typography>
              <strong>Work Order</strong>: {workOrder?.work_order}
            </Typography>
            <Typography>
              <strong>Product</strong>: {product?.product_code}
            </Typography>
            <Typography>
              <strong>Start Date</strong>: {formatDate(workOrder?.start_date)}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="start"
            sx={{ height: "100%" }}
          >
            <Typography>
              <strong>Customer Reference</strong>:{" "}
              {workOrder?.customer_reference}
            </Typography>
            <Typography>
              <strong>Production Resource</strong>: {productionResource?.name}
            </Typography>
            <Typography>
              <strong>End Date</strong>: {formatDate(workOrder.end_date)}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="start"
            sx={{ height: "100%" }}
          >
            <Typography>
              <strong>Quantity</strong>: {workOrder.quantity}
            </Typography>
            <Typography>
              <strong>Priority</strong>: {workOrder.priority}
            </Typography>
            <Typography>
              <strong>Status</strong>: {workOrder.work_order_status}
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Paper variant="outlined" sx={{ my: 3 }}>
        <Box
          mt={4}
          sx={{ width: "100%", height: "15%", borderRadius: "6px" }}
          display="flex"
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box sx={{ width: "30%", height: "100%", p: 2 }}>
            <TextField
              type="text"
              variant="outlined"
              required
              placeholder="Search..."
              fullWidth
              onChange={handleSearch}
              value={searchText}
            />
          </Box>
          <Box sx={{ width: "66%", height: "100%", mr: 2 }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              p={2}
              sx={{ height: "15%" }}
            >
              {/* <TextField type="date" variant="outlined" required sx={{width: '200px'}} /> */}
              <Box sx={{ width: 200 }}>
                <Typography>
                  <strong>Line</strong>: {line?.name}
                </Typography>
                <Typography>
                  <strong>Schamatic Type</strong>: {lineVariation?.name}
                </Typography>
                {/* <Typography><strong>Date</strong>: {formatDate(scheduledDate)}</Typography> */}
              </Box>
              <TextField
                type="date"
                variant="outlined"
                required
                sx={{ width: "200px" }}
                value={scheduledDate.split("T")[0]}
                inputProps={{
                  max: workOrder?.end_date.split("T")[0],
                  min: today.split("T")[0],
                }}
                onChange={(e) => {
                  setScheduleDate(e.target.value ?? "");
                  setSelectedShift("");
                }}
              />
              <Select
                name="shift"
                value={selectedShift}
                sx={{ width: "200px" }}
                required
                onChange={(e) => setSelectedShift(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {shifts.length > 0 &&
                  shifts.map((shift) => {
                    const d = scheduledDate.split("T")[0];
                    const l = assignedShifts[d];
                    return (
                      <MenuItem
                        key={shift?.id}
                        value={shift?.id}
                        disabled={
                          assignedShifts.hasOwnProperty(d) &&
                          l.includes(shift?.id)
                        }
                      >
                        {shift?.name}
                      </MenuItem>
                    );
                  })}
              </Select>
              <Box>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ ...muiStyles.primaryButton, px: 5 }}
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Typography pt={1}>
                  {selectedWorkers?.length} workers added
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{ width: "100%", height: "680px", borderRadius: "6px" }}
          display="flex"
          flexDirection={"row"}
          alignItems="center"
        >
          <Box sx={{ width: "68%", height: "100%", p: 2 }}>
            <Paper
              variant="outlined"
              sx={{
                width: "100%",
                height: "100%",
                overflowY: "scroll",
                px: 2,
                pt: 1,
                borderRadius: "5px",
                overflowX: "hidden",
              }}
            >
              <Grid container spacing={1}>
                {/* Display the first three items in the first row */}
                {filterdWorkersList.slice(0, 3).map((item) => (
                  <Grid item xs={4} key={item.id}>
                    <Paper
                      variant="outlined"
                      elevation={3}
                      sx={{
                        borderRadius: "5px",
                        p: 1,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: item.color_code,
                        color: getTextColor(item.color_code),
                      }}
                    >
                      <Box>
                        <Typography>
                          {item.code} - {item.first_name} {item.last_name}
                        </Typography>
                      </Box>
                      <IconButton onClick={() => handleSelectWorker(item.id)}>
                        <AddCircleOutline
                          style={{
                            color:
                              getTextColor(item.color_code) === "black"
                                ? "#1976d2"
                                : getTextColor(item.color_code),
                          }}
                        />
                      </IconButton>
                    </Paper>
                  </Grid>
                ))}
                {/* Display the rest of the items in columns */}
                {filterdWorkersList.slice(3).map((item) => (
                  <Grid item xs={4} key={item.id}>
                    <Paper
                      variant="outlined"
                      elevation={3}
                      sx={{
                        borderRadius: "5px",
                        p: 1,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: item.color_code,
                        color: getTextColor(item.color_code),
                      }}
                    >
                      <Box>
                        <Typography>
                          {item.code} - {item.first_name} {item.last_name}
                        </Typography>
                      </Box>
                      <IconButton onClick={() => handleSelectWorker(item.id)}>
                        <AddCircleOutline
                          style={{
                            color:
                              getTextColor(item.color_code) === "black"
                                ? "#1976d2"
                                : getTextColor(item.color_code),
                          }}
                        />
                      </IconButton>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Box>

          <Box sx={{ width: "32%", height: "100%", p: 2 }}>
            <Paper
              variant="outlined"
              sx={{
                width: "100%",
                height: "100%",
                overflowY: "scroll",
                px: 2,
                pt: 1,
                borderRadius: "5px",
              }}
            >
              {selectedWorkers.map((item) => {
                const textColor = getTextColor(item.color_code);
                return (
                  <Paper
                    variant="outlined"
                    elevation={3}
                    key={item.id}
                    sx={{
                      borderRadius: "5px",
                      p: 2,
                      my: 1,
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: item.color_code,
                      color: textColor,
                    }}
                  >
                    <Typography mr={2}>{item.code}</Typography>
                    <Typography mr={2}>
                      {item.first_name} {item.last_name}
                    </Typography>
                    <Typography>{item.skills}</Typography>
                    <IconButton onClick={() => handleRemoveWorker(item.id)}>
                      <RemoveCircleOutline
                        style={{
                          color: textColor === "black" ? "#d32f2f" : textColor,
                        }}
                      />
                    </IconButton>
                  </Paper>
                );
              })}
            </Paper>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default CrewCreate;
