import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    user: {}
  },
  reducers: {
    setUser: (state, action) => {
        state.user = action.payload
    },
  },
});

export const { setUser } = userSlice.actions;

export const getUser = (state) => {
  return state.userSlice?.user;
};

export default userSlice.reducer;
