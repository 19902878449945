import { useAuth0 } from "@auth0/auth0-react";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <button
      onClick={() =>
        loginWithRedirect({
          authorizationParams: {
            scope: "offline_access",
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            useRefreshTokens: true,
            cacheLocation: "localstorage",
          },
        })
      }
    >
      {" "}
      Log In
    </button>
    
  );
};

export default Login;
