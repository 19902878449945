import VideoComponent from "./VideoComponent"
import ImagesComponent from "./ImagesComponent"
import { Stack, Divider } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"

const MediaComponent = ({fetchSteps}) => {
    const imagesgood =  useSelector(state => state.steps.currentStep)?.media?.good
    const imagesbad =  useSelector(state => state.steps.currentStep)?.media?.bad
    const imagesreference =  useSelector(state => state.steps.currentStep)?.media?.reference
    return <Stack divider={<Divider fullWidth />} spacing={3}>      
        <VideoComponent fetchSteps={fetchSteps} />
        {/* <ImagesComponent fetchSteps={fetchSteps} /> */}
        <ImagesComponent fetchSteps={fetchSteps} ChildName="production:step:good" Name="Good" imagesdata={imagesgood} />
        <ImagesComponent fetchSteps={fetchSteps} ChildName="production:step:bad" Name="Bad" imagesdata={imagesbad}/>
        <ImagesComponent fetchSteps={fetchSteps} ChildName="production:step:reference" Name="Reference" imagesdata={imagesreference}/>
    </Stack>
}

export default MediaComponent

