import React, { useEffect, useState } from "react";
import { NavLink,useNavigate } from "react-router-dom";
import { setWorkers } from "../../features/workers/workersSlice";
import useApi from "../../hooks/useAPI";
import muiStyles from "../../styles/MuiComponentStyles";
import { CosmeticaTable, Loading } from "../UIComponents";
import { useCssStateHook } from "../UIComponents/ComonCss";
// import ColorPicker from 'material-ui-color-picker'
// import { MuiColorInput } from 'mui-color-input'
// import { setZones } from "../../features/zones/zonesSlice";
// import {  East } from "@mui/icons-material";
// import Chair from "../../images/workerimages/Chair.png";
// import Allergy from "../../images/workerimages/Allergy.png";
// import NoCapping from "../../images/workerimages/NoCapping.png";
// import Checkbox from '@mui/material/Checkbox';

import {
  // FormGroup,
  // FormControl,
  // TextField,
  Button,
  Box,
  Stack,
  Typography,
  // InputLabel,
  // Select,
  // MenuItem,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

// const initialState = {
//   first_name: "",
//   last_name: "",
//   color_code: "",
//   code: "",
//   skill: "",
//   zone_id: "", 
// };

const Workers = () => {
  const workers = useSelector((state) => state.worker.workers);
  let navigate = useNavigate();
  // const [showModal, setShowModal] = useState(false);
  // const [modalTitle, setModalTitle] = useState(""); // Define modalTitle here
  // const userInfo = useSelector((state) => state.user.user);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const { loading, getData } = useApi();
  const [limitations, setLimitations] = useState([]);
  const workersUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/worker`;
  const zoneUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/zone`;
  const { themeCssButton, themeCssHeader } = useCssStateHook();
  // const [editWorkersData, setEditWorkersData] = useState(initialState);
  // const [zones, setZones] = useState([]);


  const fetchWorkers = async () => {
    try {
      const zonesData = await getData(
        `${zoneUrl}/all/${user.tenant_id}/page/${1}/count/${9999}`
      );
      // setZones(zonesData.zones);
      const zoneWiseWorkers = [];
      if (zonesData?.count > 0) {
        for (const zone of zonesData?.zones || []) {
          const response = await getData(
            `${workersUrl}/all/${user.tenant_id}/${zone?.id}`
          );
          zoneWiseWorkers.push({ ...zone, workers: response });
        }
      }
      dispatch(setWorkers(zoneWiseWorkers || []));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLimitations = async () => {
    try {
      const response = await getData(
        `${workersUrl}/limitations/all/${user.tenant_id}`
      );
      setLimitations(response)
    //   const t = await getFileData('1/1/worker:limitation_1/_0/i_1_1714646706083')
      console.log(' setLimitations ', response)
    //   console.log(' setLimitations Images', t)
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    if (user.tenant_id) {
      fetchWorkers();
      fetchLimitations();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.tenant_id, workersUrl, zoneUrl]);

  

  const handleEdit = (id) => {
    navigate(`/admin/workers/edit-workers/${id}`)

    // const worker = workers.find((worker) =>
    //   worker.workers.some((w) => w.id === id)
    // );
    // if (worker) {
    //   const editedWorker = worker.workers.find((w) => w.id === id);
    //   setEditWorkersData({
    //     id: editedWorker.id,
    //     first_name: editedWorker.first_name,
    //     last_name: editedWorker.last_name,
    //     color_code: editedWorker.color_code,
    //     code: editedWorker.code,
    //     skill: editedWorker.skill,
    //   });
    //   setShowModal(true);
    //   setModalTitle("Edit Workers");
    // }
  };

  // const handleCancel = () => {
  //   setShowModal(false);
  //   setEditWorkersData(initialState);
  // };

  // const handleSubmit = async (e, workersData) => {

  //   e.preventDefault();
  //   e.target.reset();
  //   setShowModal(false);

  //   if (workersData?.id) {
  //     try {
  //       const updateData = { ...workersData };
  //       await patchData(`${workersUrl}/update`, updateData);
  //       fetchWorkers();
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //     try {
  //       const createData = {
  //         ...workersData,
  //         tenant_id: userInfo?.tenant_id,
  //         zone_id: userInfo?.zone_id,
  //       };
  //       await postData(
  //         `${workersUrl}/add/${user.tenant_id}/${workersData.zone_id}`,
  //         createData
  //       );
  //       fetchWorkers();
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }

  //   setEditWorkersData(initialState);
  // };

  // const handleAddWorkers = () => {
  //   setShowModal(true);
  //   setModalTitle("Add Workers");
  //   setEditWorkersData(initialState);
  // };

  return loading ? (
    <Loading />
  ) : (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={5}
      >
        <Typography sx={muiStyles.pageTitle}>Workers</Typography>
        <Button
            variant="contained"
            component={NavLink}
            to="add-workers"
            // sx={muiStyles.primaryButton}
            sx={themeCssButton}
          >
           Add Workers
          </Button>
        {/* <Button
          variant="contained"
          onClick={handleAddWorkers}
          sx={themeCssButton}
        >
          Add Workers
        </Button> */}
      </Stack>
      {/* "Colour Code" */}
      {workers.map((item) => {
        const fields = ["Worker Code","Name", "Skills","Accommodations",""];    
        const rows = item?.workers.map((row) => {
          let workerLimitations = '';
          if (row.limitations) {
            const limitationsArray = row.limitations.split(",")
            workerLimitations = limitationsArray.map(lid => limitations.find(i => i.id.toString() === lid)?.name ?? "").join (", ")

          }
          return {
          id: row.id,
          code: row.code,
          name: `${row.first_name} ${row.last_name}`,
          
          skills: row.skills,
          accommodations: workerLimitations,
          // eslint-disable-next-line no-dupe-keys
          color_code: row.color_code,
          // colorCode: <span style={{ borderLeft: `20px solid ${row.color_code}`, paddingLeft: '10px'}}>{row.color_code}</span>,
          }
        });

        
        return (
          <Box sx={{ mt: 5 }} key={item.id}>
            <Box sx={{ mb: 2 }} display="flex" flexDirection="row" >
              <Typography sx={{ fontSize: "18px", fontWeight: "bold"}}>
                Zone:
              </Typography>
              <Typography sx={{ fontSize: "18px", ml: 1 }}>
                {item?.name}
              </Typography>
            </Box>
            <CosmeticaTable
              fields={fields}
              rows={rows}
              isEdit
              handleEdit={handleEdit}
              isSearch
              rowsCount={item.workers.length}
              isHeadercss={themeCssHeader}
              pagination={false}
              
              
            />

            {/* {showModal && (
              <CosmeticaModal
                showModal={showModal}
                title={editWorkersData?.id ? "Edit Workers" : "Add Workers"}
                closeModal={handleCancel}
              >
                <WorkersForm
                  handleClose={() => {
                    setShowModal(false);
                    setEditWorkersData(initialState);
                  }}
                  editWorkersData={editWorkersData}
                  handleSubmit={handleSubmit}
                  handleCancel={handleCancel}
                  zones={zones}
                  modalTitle={modalTitle} // Ensure modalTitle is passed as a prop
                  isHeadercss={themeCssHeader}
                />
              </CosmeticaModal>
            )} */}
          </Box>
        );
      })}
    </Box>
  );
};

// const WorkersForm = ({
//   handleSubmit,
//   handleCancel,
//   editWorkersData,
//   zones,
//   modalTitle,
//   isHeadercss,
// }) => {
//   const [formData, setFormData] = useState(editWorkersData);
// const[nameValid, setNameValid]=useState(false)
// const[lastnameValid, setLastNameValid]=useState(false)
// const[skillValid, setSkillNameValid]=useState(false)
// const[workercodeValid, setWorkerCodeValid]=useState(false)

// // const [colorPickerVisible, setColorPickerVisible] = useState(false);
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };
//   // const handleChangeset = (e) => {
    
//   //   const { name, value } = e.target;
//   //   setFormData((prevData) => ({
//   //     ...prevData,
//   //     [name]: value,
//   //   }));
//   //   // setColorPickerVisible(true);
//   // };

//   const handleColorChange = (color) => {
//     setFormData((prevData) => ({
//         ...prevData,
//         color_code: color 
//     }));
//     // setColorPickerVisible(false); 
// };
//   const handleChangenumber = (e) => {
//     const { name, value } = e.target;
//     const numericPattern = /^\d*$/;
//     if (value === "" || numericPattern.test(value)) {
//       setWorkerCodeValid(false)
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   }else{
//     setWorkerCodeValid(true)
//   }
//   };
//   const handleChangename = (e) => {
//     const { name, value } = e.target;
//     const alphabeticPattern = /^[a-zA-Z]*$/;
//     if (value === "" || alphabeticPattern.test(value)) {
//       setNameValid(false)
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   }else{
//     setNameValid(true)
//   }
//   };
//   const handleChangelastname = (e) => {
//     const { name, value } = e.target;
//     const alphabeticPattern = /^[a-zA-Z]*$/;
//     if (value === "" || alphabeticPattern.test(value)) {
//       setLastNameValid(false)
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   }else{
//     setLastNameValid(true)
//   }
//   };
//   const handleChangelSkill = (e) => {
//     const { name, value } = e.target;
//     const alphabeticPattern = /^[a-zA-Z]*$/;
//     if (value === "" || alphabeticPattern.test(value)) {
//       setSkillNameValid(false)
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   }else{
//     setSkillNameValid(true)
//   }
//   };
//   const handleChange1 = (event) => {
//     // setIsChecked(event.target.checked);
//     console.log("changelSkill",event.target.checked)
//   };
// const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
//   return (
//     <form
//       style={{ marginTop: "20px" }}
//       onSubmit={(e) => handleSubmit(e, formData)}
//     >
//       <FormGroup>
//         <FormControl sx={{ mt: 2 }}>
//           <TextField
//           error={nameValid}
//             label="First Name"
//             name="first_name"
//             value={formData.first_name}
//             onChange={handleChangename}
//             helperText={nameValid ? "Incorrect entry": ""}
//           />
//         </FormControl>

//         <FormControl sx={{ mt: 2 }}>
//           <TextField
//             error={lastnameValid}
//             label="Last Name"
//             name="last_name"
//             value={formData.last_name}
//             onChange={handleChangelastname}
//             helperText={lastnameValid ? "Incorrect entry": ""}
//           />
//         </FormControl>

//         <FormControl sx={{ mt: 2 }}>
//           <TextField
//            error={workercodeValid}
//             label="Worker Code"
//             name="code"
//             value={formData.code}
//             onChange={handleChangenumber}
//             helperText={workercodeValid ? "Incorrect entry": ""}
//           />
//         </FormControl>

//         <FormControl sx={{ mt: 2 }}>
//           {/* <TextField
//             label="Color Code"
//             name="color_code"
//             value={formData.color_code}
//             onChange={handleChange}
//           /> */}
//           <MuiColorInput
//             isAlphaHidden
//             label="Color Code"
//             name="color_code"
//             format="hex"
//             value={formData.color_code}
//             onChange={handleColorChange}
//             size="large"
//             variant="outlined"
//           />
//         </FormControl>        
//         <FormControl sx={{ mt: 2 }}>
//           <TextField
//             error={skillValid}
//             label="Skill"
//             name="skill"
//             value={formData.skill}
//             onChange={handleChangelSkill}
//             helperText={skillValid ? "Incorrect entry": ""}
//           />
//         </FormControl>
//         {modalTitle === "Add Workers" && (
//           <FormControl sx={{ mt: 2 }}>
//             <InputLabel id="zone-select-label">Zone</InputLabel>
//             <Select
//               labelId="zone-select-label"
//               id="zone-select"
//               value={formData.zone_id}
//               name="zone_id"
//               onChange={handleChange}
//             >
//               {zones.map((zone) => (
//                 <MenuItem key={zone.id} value={zone.id}>
//                   {zone.name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         )}
//          <FormControl sx={{ mt: 2 }}>
       
//           <Box sx={{ display: "flex", flexDirection: "row", mt: 0 }}>
//             <Box sx={{ width: '50px', height: '50px', mr: 1 }}>
//               <Checkbox
//                 //  sx={{ width: '50px', height: '50px' }}
//                 icon={<img style={{ width: '50px', height: '50px' }} src={Chair} alt="Unchecked" />}
//                 checkedIcon={<img style={{ width: '50px', height: '50px' }} src={Chair} alt="checked" />} />;
//             </Box>
//             <Box sx={{ width: '50px', height: '50px', mr: 1 }}>
//             <Checkbox
//               // sx={{ width: '50px', height: '50px' }}
//               icon={<img style={{ width: '50px', height: '50px' }} src={Allergy} alt="Unchecked" />}
//               checkedIcon={<img style={{ width: '50px', height: '50px' }} src={Allergy} alt="checked" />} />
//                </Box>
//                <Box sx={{ width: '50px', height: '50px', mr: 1 }}>
//             <Checkbox
//               // sx={{ width: '50px', height: '50px' }}
//               onChange={handleChange1}
//               icon={<img style={{ width: '50px', height: '50px' }} src={NoCapping} alt="Unchecked" />}
//               checkedIcon={<img style={{ width: '50px', height: '50px' }} src={NoCapping} alt="checked" />} />
//                </Box>
//           </Box>
//          </FormControl>
//       </FormGroup>
//       <Stack
//         direction="row"
//         justifyContent="flex-end"
//         alignItems="center"
//         spacing={2}
//         mt={2}
//       >
//         <Button variant="text" size="large" onClick={handleCancel}>
//           Cancel
//         </Button>
//         <Button
//           type="submit"
//           variant="contained"
//           size="large"
//           sx={isHeadercss || muiStyles.primaryButton}
//           endIcon={<East />}
//         >
//           Submit
//         </Button>
//       </Stack>
//     </form>
//   );
// };

export default Workers;
