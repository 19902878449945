import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "productionResourceSlice",
  initialState: {
    resource: {
        name: '',
        desc: ''
    }
  },
  reducers: {
    setResource: (state, action) => {
      state.items = JSON.parse(JSON.stringify(action.payload))
    }
  },
});

export const { setResource } = slice.actions;

export const getResource = (state) => {
  return state.productionResourceSlice.resource;
};

export default slice.reducer;
