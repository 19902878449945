import React from 'react'
import { Typography, Box, Stack, Button, createTheme, ThemeProvider } from '@mui/material'
import { East } from '@mui/icons-material'
import muiStyles from '../../styles/MuiComponentStyles'
import {useSelector} from 'react-redux'
import useApi from "../../hooks/useAPI";
import { formatDate } from "../../utils/helperFunctions";
import {DeleteModal} from '../UIComponents'

const theme = createTheme({
    typography: {
        body1: {
            fontSize: '16px', 
            fontWeight: 500, 
            lineHeight: '20px'
        },
        h5: {
            fontSize: '18px', 
            fontWeight: 400, 
            lineHeight: '40px'
        }
    },
    components: {
        MuiTypography: {
            color: '#2E3646'
        }
    }
})

const ScheduleJobDetails = ({handleEditJob, job, weekNumber, handleDelete}) => {
    const {getData} = useApi()
    const products = useSelector(state => state.products.allItems)
    const lines = useSelector(state => state.line.items)
    const priorities = useSelector(state => state.scheduleJobs.priorities)
    const [lineVariation, setLineVariation] = React.useState([])
    const [productionResource, setProductionResource] = React.useState([])
    const lineURL = `${process.env.REACT_APP_BASE_URL}/web/v2/line`
    const productionResourceUrl = `${process.env.REACT_APP_BASE_URL}/web/v2/production/resource`
    const [product, setProduct] = React.useState(null)
    const [line, setLine] = React.useState(null)
    const [priority, setPriority] = React.useState(null)
    const [showModal, setShowModal] = React.useState(false);

    React.useEffect(() => {
        const _product = products?.find(product => product.id === job.product_id)
        const _priority = priorities?.find(priority => priority.id === job.priority_id)
        setProduct(_product)
        setPriority(_priority)
        fetchResources(job.product_id)
        // eslint-disable-next-line
    }, [job])
    
    const fetchResources = async (productId) => {
        try {
            await getData(`${productionResourceUrl}/all/${productId}`).then(data => {
                const resource = data?.find(resource => resource.id === job.production_resource_id)
                const _line = lines?.find(line => line.id === resource.line_id)
                fetchLineVariation(resource.line_id, resource.line_variation_id)
                setLine(_line)
                setProductionResource(resource)
            })
        } catch (error) {
            console.log(error);
        }
    }

    async function fetchLineVariation(id, line_variation_id) {
        try {
            await getData(`${lineURL}/variation/all/${id}/page/${1}/count/${999}`).then((data) => {
                const variation = data.variations?.find(variation => variation.id === line_variation_id)
                setLineVariation(variation)
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => {
        setShowModal(false);
    };

    return <ThemeProvider theme={theme}>
    <Box px={4}>
        <Typography variant='h5' ><strong>Week - {weekNumber}</strong> ({formatDate(job?.start_date, 'ddMMM')} - {formatDate(job?.end_date, 'ddMMM')})</Typography>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1.5}>
            <Typography variant='body1'>Product Code</Typography>
            <Typography variant='body1'>{product?.product_code}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1.5}>
            <Typography variant='body1'>Description</Typography>
            <Typography variant='body1'>{product?.desc}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1.5}>
            <Typography variant='body1'>MFPS Number</Typography>
            <Typography variant='body1'>{product?.specification}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1.5}>
            <Typography variant='body1'>Revision</Typography>
            <Typography variant='body1'>{product?.specification_revision}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1.5}>
            <Typography variant='body1'>Production Resource</Typography>
            <Typography variant='body1'>{productionResource?.name}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1.5}>
            <Typography variant='body1'>Customer Number</Typography>
            <Typography variant='body1'>{job.customer_reference}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1.5}>
            <Typography variant='body1'>Work Order Number (W/O)</Typography>
            <Typography variant='body1'>{job.work_order}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1.5}>
            <Typography variant='body1'>Line Name</Typography>
            <Typography variant='body1'>{line?.name}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1.5}>
            <Typography variant='body1'>Schamatic Type</Typography>
            <Typography variant='body1'>{lineVariation?.name}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1.5}>
            <Typography variant='body1'>Quantity</Typography>
            <Typography variant='body1'>{job.quantity}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1.5}>
            <Typography variant='body1'>Priority</Typography>
            <Typography variant='body1'>{priority?.name}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1.5}>
            <Typography variant='body1'>Note</Typography>
            <Typography variant='body1'>{job.note}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='flex-end' alignItems='center' mt={3} spacing={1}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={muiStyles.primaryButton}
              onClick={() => setShowModal(true)}
            >
              Delete
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={muiStyles.primaryButton}
              endIcon={<East />}
              onClick={handleEditJob}
            >
              Edit
            </Button>
        </Stack>
    </Box>
    <DeleteModal
        showModal={showModal}
        handleClose={handleClose}
        handleDelete={() => {
          handleDelete();
          handleClose();
        }}
    />
    </ThemeProvider>
}

export default ScheduleJobDetails