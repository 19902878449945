import React, { useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import {
    DndContext,
    closestCenter,
    MouseSensor,
    TouchSensor,
    DragOverlay,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { DeleteModal } from "../../../UIComponents";
import { Grid, ThemeProvider, Button, Typography } from '@mui/material';
import { MultiStepTheme } from "../../Theme";
import SortableItem from './SortableItem';
import Item from './Item';
import { colors } from '../../../../styles/colors';

const App = ({handleAddStep, handleDeleteStep, handleStepPositions}) => {
    const steps = useSelector(state => state.steps.items)
    const enableNextStep = useSelector(state => state.steps.enableNextStep)
    const [activeId, setActiveId] = useState(null);
    const [activeStep, setActiveStep] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    React.useEffect(() => {
        // setItems(steps)
    }, [steps])

    const handleDragStart = useCallback((event) => {
        setActiveId(event.active.id);
        setActiveStep(event.active.data.current.step);
        setActiveIndex(event.active.data.current.index);
    }, []);

    const handleDragEnd = useCallback((event) => {
        const { active, over } = event;
        const oldIndex = steps.findIndex(item => item.id === active.id);
        const newIndex = steps.findIndex(item => item.id === over?.id);
        let updatedItems = arrayMove(steps, oldIndex, newIndex)
        // setItems(updatedItems)
        handleStepPositions(JSON.parse(JSON.stringify(updatedItems)))
        setActiveId(null);
        setActiveStep(null);
        setActiveIndex(null);
        // eslint-disable-next-line
    }, []);

    const handleDragCancel = useCallback(() => {
        setActiveId(null);
        setActiveStep(null);
        setActiveIndex(null);
    }, []);

    const handleDelete = () => {
        handleDeleteStep()
        setShowDeleteModal(false);
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <ThemeProvider theme={MultiStepTheme}>
                <SortableContext items={steps} strategy={rectSortingStrategy}>
                        <Grid container spacing={2}>
                            {steps.map((step, index) => (
                                <Grid item key={step?.id} xs={6} sm={4} md={3} lg={2}>
                                    <SortableItem id={step?.id} step={step} index={index} setShowDeleteModal={setShowDeleteModal} />
                                </Grid>
                            ))}
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                            <Button
                                item
                                sx={{
                                width: "164px",
                                color: "#fff",
                                backgroundColor: colors.primary,
                                "&:hover": { color: "#fff", backgroundColor: colors.primary },
                                "&.Mui-disabled": {
                                    color: "#fff !important",
                                    backgroundColor: colors.primary_disabled,
                                },
                                }}
                                disableRipple
                                onClick={handleAddStep}
                                disabled={!enableNextStep}
                            >
                                <Typography>+ Add New Step</Typography>
                            </Button>
                            </Grid>
                        </Grid>
                </SortableContext>
                <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
                    {activeId ? <Item id={activeId} index={activeIndex} step={activeStep} isDragging /> : null}
                </DragOverlay>
                <DeleteModal
                    showModal={showDeleteModal}
                    handleClose={() => setShowDeleteModal(false)}
                    handleDelete={handleDelete}
                    title="step"
                />
            </ThemeProvider>
        </DndContext>
    );
};

export default App;
